/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox } from "primereact/checkbox";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RegisterArrow, SampleTestClose, TaskArrow } from "../../svg/AllSvg";
import { labSampleActions } from "../../../shared/store/labSample-Slice";
import { useDispatch } from "react-redux";
import useAxios from "../../../shared/hooks/use-axios";
import Parameter from "../../../skeleton/components/customer/Parameter";
import { useTranslation } from "react-i18next";

const ParameterPopup = () => {
  const [list, setList] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const parameterPopup: any[] = useSelector((state: any) => state.labSample.parameterPopup);
  const sampleDetail = useSelector((state: any) => state.labSample.sampleDetail);
  const allParameter: any[] = useSelector((state: any) => state.labSample.allParameter);
  const selectDept: any = useSelector((state: any) => state.labSample.selectDept);
  const [allBtnShow, setAllBtnShow] = useState<number>(0);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const getParameterList = async () => {
    try {
      setIsLoading(true)
      const res: any = await useAxios.get(`/samples/reports-parameters-list/?sample=${sampleDetail?.id}&department=${selectDept?.department_id}`);
      if (res && res?.status === 200) {
        setIsLoading(false)
        setList(res?.data?.results)
      }

    } catch (error) {
      console.error("error while feching parameter list", error);
    }
  };

  useEffect(() => {
    (!!sampleDetail?.id && !!selectDept?.department_id) && getParameterList()
  }, [sampleDetail?.id, selectDept])

  document.onkeydown = function (evt: any) {
    if (evt.keyCode === 27) {
      dispatch(labSampleActions.setParameterPopup(false));
      dispatch(labSampleActions.setDeptPopup(true));
    }
  };

  const handleCheck = (type: any, id: any) => {
    if (type === "checkAll") {
      if (allParameter?.length === list?.length) {
        dispatch(labSampleActions.setAllParameter([]))
      } else {
        let arr: any = [];
        for (let i = 0; i < list.length; i++) {
          const el = list[i];
          arr.push(el?.parameter)
        }
        dispatch(labSampleActions.setAllParameter(arr))
      }
    } else {
      if (allParameter?.includes(id)) {
        let arr: any[] = allParameter?.filter((item: any) => item !== id)
        dispatch(labSampleActions.setAllParameter(arr))
      } else {
        if (sampleDetail?.partial_generated && ((Math.ceil(list.length / 2)) === allParameter?.length)) {
          return;
        }
        if (sampleDetail?.partial_generated && ((Math.ceil(list.length / 2)) !== allParameter?.length)) {
          dispatch(labSampleActions.setAllParameter([...allParameter, id]))
        }

        if (!sampleDetail?.partial_generated) {
          dispatch(labSampleActions.setAllParameter([...allParameter, id]))
        }
      }
    }
  };

  return (
    <>
      {parameterPopup && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
        <div className="flex justify-center items-center">
          <div className="relative my-[24px] md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] sm:w-[600px] md:w-[745px] lg:w-[930px] xl:w-[1230px] xxl:w-[1327px]">
            <div className="w-full flex items-center justify-between p-[24px] !pb-0">
              <div className="flex items-center gap-[4px] cursor-pointer" onClick={() => { dispatch(labSampleActions.setParameterPopup(false)); dispatch(labSampleActions.setDeptPopup(true)); }}>
                <TaskArrow />
                <h3 className="text-primaryLight text-lg font-medium pt-[1px]">{t("lab_sample.test_approval.review_process.parameters")}</h3>
              </div>
              <div className="cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full" onClick={() => { dispatch(labSampleActions.setParameterPopup(false)); dispatch(labSampleActions.setDeptPopup(false)); }}>
                <SampleTestClose />
              </div>
            </div>

            <div className="w-full lg:col-span-2 p-[24px] h-full flex flex-col gap-[25px] justify-between">
              {isLoading ? <Parameter /> :
                <div className="overflow-x-auto max-h-[350px] lg:max-h-max">
                  <div className="inline-block min-w-full">
                    <div className="overflow-hidden">
                      <table className="min-w-full">
                        <thead className="bg-white !rounded-[10px]">
                          <tr className="h-[50px] !rounded-[10px] border-b border-[#E8EBFF]">
                            <th className="px-[8px] 2xl:px-[16px] h-full">
                              <div className='flex items-center justify-center w-full'>
                                <Checkbox className="tests table-check" name="checkAll" disabled={sampleDetail?.partial_generated} checked={(list?.length === allParameter.length) ? true : false} onChange={() => handleCheck("checkAll", "")}></Checkbox>
                              </div>
                            </th>
                            <th className="px-[8px] 2xl:px-[16px] h-full">
                              <div className='flex items-center justify-start w-full gap-[8px]'>
                                <p className='text-lGray font-normal text-xs !text-left'>Parameter Name</p>
                              </div>
                            </th>
                            <th className="px-[8px] 2xl:px-[16px] h-full">
                              <div className='flex items-center justify-start w-full gap-[8px]'>
                                <p className='text-lGray font-normal text-xs !text-left'>Method of Test</p>
                              </div>
                            </th>
                            <th className="px-[8px] 2xl:px-[16px] h-full">
                              <div className='flex items-center justify-start w-full gap-[8px]'>
                                <p className='text-lGray font-normal text-xs !text-left'>Accreditation</p>
                              </div>
                            </th>
                            {list[0]?.pricing_methodology === "parameter" && <th className="px-[8px] 2xl:px-[16px] h-full">
                              <div className='flex items-center justify-center w-full gap-[8px]'>
                                <p className='text-lGray font-normal text-xs !text-center'>Qty</p>
                              </div>
                            </th>}
                            <th className="px-[8px] 2xl:px-[16px] h-full">
                              <div className='flex items-center justify-center w-full gap-[8px]'>
                                <p className='text-lGray font-normal text-xs !text-center'>Department</p>
                              </div>
                            </th>
                            {list[0]?.pricing_methodology === "parameter" && <th className="px-[8px] 2xl:px-[16px] h-full">
                              <div className='flex items-center justify-center w-full gap-[8px]'>
                                <p className='text-lGray font-normal text-xs !text-center'>Price</p>
                              </div>
                            </th>}
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(list) && list.map((item: any, index: number) => (
                            <tr className="border-b border-[#E8EBFF] !rounded-[10px] bg-white" key={index}>
                              <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                <div className="flex items-center justify-center">
                                  <Checkbox className="tests table-check" disabled={(((Math.ceil(list.length / 2)) === allParameter?.length)) ? (allParameter?.includes(item?.parameter) ? false : true) : false} checked={allParameter?.includes(item?.parameter) ? true : false} onChange={() => handleCheck("single", item?.parameter)} name={item?.parameter}></Checkbox>
                                </div>
                              </td>
                              <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                <h3 className="text-[#767676] font-normal !text-sm text-left">{(!!item?.parameters_detail?.clause_no || !!item?.parameters_detail?.parameter) ? `${item?.parameters_detail?.parameter} (${item?.parameters_detail?.clause_no})` : "-"}</h3>
                              </td>
                              <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                {item?.parameters_detail?.test_method?.length > 0 ? <>
                                  {(Array.isArray(item?.parameters_detail?.test_method) && item?.parameters_detail?.test_method.map((i: any, idx: number) =>
                                    <h3 className="text-[#767676] font-normal !text-sm text-left" key={idx}>
                                      {(i?.name) + `${item?.parameters_detail?.test_method?.length !== idx + 1 ? ', ' : ' '}`}
                                    </h3>
                                  ))}
                                </> : <h3 className="text-[#767676] font-normal !text-sm text-left">-</h3>}
                              </td>

                              <td className="px-[8px] 2xl:px-[16px] py-[16px]" onMouseEnter={() => setAllBtnShow(item?.id)} onMouseLeave={() => setAllBtnShow(0)}>
                                <div>
                                  {item?.certificate_detail?.length > 0 ?
                                    <>
                                      {(allBtnShow !== item?.id || allBtnShow === 0) ? <div className="flex items-center gap-[8px]">
                                        <button className={`rounded-[5px] bg-[#D7E8FF] text-primaryLight font-normal text-xs px-[13px] h-[25px] flex items-center justify-center whitespace-nowrap`}>{item?.certificate_detail[0]?.name}</button>
                                        {item?.certificate_detail?.length > 1 && <button className={`rounded-[5px] bg-[#D7E8FF] text-primaryLight font-normal text-xs px-[13px] h-[25px] flex items-center justify-center whitespace-nowrap`}>{item?.certificate_detail?.length - 1}+</button>}
                                      </div> :
                                        <div className="flex items-center gap-[8px]">
                                          {Array.isArray(item?.certificate_detail) && item?.certificate_detail.map((i: any, idx: number) => (
                                            <button key={idx} className={`rounded-[5px] bg-[#D7E8FF] text-primaryLight font-normal text-xs px-[13px] h-[25px] flex items-center justify-center whitespace-nowrap`}>{i?.name}</button>
                                          ))}
                                        </div>
                                      }
                                    </>
                                    :
                                    <h3 className="text-[#767676] font-normal !text-sm text-left">-</h3>
                                  }
                                </div>
                              </td>
                              {
                                list[0]?.pricing_methodology === "parameter" && <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                  <h3 className="text-[#767676] font-normal !text-sm text-center">{item?.quantity}</h3>
                                </td>
                              }
                              < td className="px-[8px] 2xl:px-[16px] py-[16px]" >
                                <div className="flex items-center justify-center">
                                  {item?.parameters_detail?.test_type_details[0] ? <div className="rounded-[20px] bg-[#F6ECFF] text-[#7840A7] font-normal text-xs px-[13px] h-[25px] flex items-center justify-center">{item?.parameters_detail?.test_type_details[0]?.name}</div> : <h3 className="text-[#767676] font-normal !text-sm text-center">-</h3>}
                                </div>
                              </td>
                              {list[0]?.pricing_methodology === "parameter" && <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                <h3 className="text-[#767676] font-normal !text-sm text-center">₹{item?.parameters_detail?.charges}</h3>
                              </td>}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              }
              <div className="w-full flex items-center justify-end">
                {/* <div className="w-full flex items-center gap-[8px] cursor-pointer" onClick={() => handleCheck("checkAll", "")}>
                  <Checkbox className='tests table-check' checked={(!isLoading && (list?.length === allParameter.length)) ? true : false} ></Checkbox>
                  <p className="text-primaryLight text-sm font-medium">{t("lab_sample.report.all_parameter")}</p>
                </div> */}

                <button className="bg-primaryLight px-[20px] py-[8px] ss:px-[40px] gap-[8px] ss:py-[10px] shadow-[0px_-1px_1px_1px_rgba(67,94,171,0.25)_inset,0px_1px_1px_1px_rgba(255,255,255,0.25)_inset,0px_0px_0px_1px_#435EAB_inset,0px_1px_2px_0px_rgba(20,14,62,0.25)] rounded-[10px] focus:!shadow-[0_0_0_0.20rem_#b9ccf7] hover:!shadow-[0_0_0_0.20rem_#b9ccf7] text-white text-sm font-medium whitespace-nowrap flex items-center disabled:opacity-[0.3] disabled:cursor-not-allowed" onClick={() => { dispatch(labSampleActions.setParameterPopup(false)); dispatch(labSampleActions.setDeptPopup(false)); dispatch(labSampleActions.setReportPopup(true)); }} disabled={sampleDetail?.partial_generated ? (allParameter?.length > 0 ? false : true) : ((list?.length === allParameter?.length) ? false : true)}>{t("common_btn.next")} <RegisterArrow className="fill-white" /></button>
              </div>
            </div>
          </div>
        </div >
      </div >}
    </>
  );
};

export default ParameterPopup;
