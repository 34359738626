/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { SettingRightArrow, TestDetailDown } from "../svg/AllSvg";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { settingActions } from "../../shared/store/setting-Slice";
import { useNavigate } from "react-router-dom";
import useAxios from "../../shared/hooks/use-axios";
import { useModule } from "../../shared/hooks/use-module";
import { settingMenu } from "../../shared/utils/settingMenu";
import { useScreen } from "../../shared/hooks/use-Screen";

const SettingLayout: React.FC<any> = () => {
    const settingPermission: string = useSelector((state: any) => state.setting.settingPermission);
    const settingPath: string = useSelector((state: any) => state.ui.settingPath);
    const prev: boolean = useSelector((state: any) => state.setting.prev);
    const customSearch: any[] = useSelector((state: any) => state.setting.customSearch);
    const title: string = useSelector((state: any) => state.setting.title);
    const select: any = useSelector((state: any) => state.setting.select);
    const isActivityOpen: boolean = useSelector((state: any) => state.ui.isOpen);
    const [list, setList] = useState<any[]>([]);
    const [toggle, setToggle] = useState<string>("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useModule("setting");
    useScreen("");

    useEffect(() => {
        setList(settingMenu)
    }, [settingMenu])

    const getCustomFieldType = async () => {
        try {
            const res: any = await useAxios.get(`/utility/custom-fields-table-list/`);

            if (res && res?.status === 200) {
                let arr: any[] = [];
                for (let i = 0; i < res?.data.results.length; i++) {
                    const el = res?.data.results[i];
                    el.name = el?.label;
                    arr.push(el)
                }
                dispatch(settingActions.setCustom(arr))
                dispatch(settingActions.setCustomSearch(arr))
                dispatch(settingActions.setSelect(res?.data.results[0]));
            }
        } catch (error) {
            console.error("error while feching custom field type", error);
        }
    }

    useEffect(() => {
        if (title === "Custom Fields") {
            getCustomFieldType();
        }
        // eslint-disable-next-line
    }, [title])

    const handleTabChange = (item: any) => {
        setToggle(toggle === item?.name ? "" : item?.name)
        dispatch(settingActions.setTitle(item?.name))
        navigate(`${settingPath}/setting${item?.path}`)
    }

    const handleSubTab = (item: any) => {
        dispatch(settingActions.setSelect(item))
        dispatch(settingActions.setPrev(false));

        if (item?.name === "Customer Group") {
            navigate(`${settingPath}/setting/group/${item?.id}`)
        }
        if (item?.name === "Remarks Master") {
            navigate(`${settingPath}/setting/remaks-template/${item?.id}`)
        }
        if (item?.name === "Administrative") {
            navigate(`${settingPath}/setting/department/administrative`)
        }
        if (item?.name === "Technical") {
            navigate(`${settingPath}/setting/department/technical`)
        }
        if (item?.name === "Billing Configuration") {
            navigate(`${settingPath}/setting/billing-config`)
        }
        if (item?.name === "Sample Configuration") {
            navigate(`${settingPath}/setting/sample-config`)
        }
        if (item?.name === "TDS Management") {
            navigate(`${settingPath}/setting/tds`)
        }
    }

    return (
        <>
            <div className="md:col-span-2 xxl:w-[290px] border border-[#dee6e980] rounded-[10px] shadow-[0px_5px_15px_0px_#dfeaff66] hidden md:block bg-white h-full min-h-[calc(100vh-118px)]">
                <h3 className="text-primaryLight text-[1.063rem] xl:text-lg font-medium py-[15px] px-[24px] border-b border-[#dee6e980] min-h-[60px]">Settings</h3>

                <div className="flex flex-col py-[12px]">
                    {Array.isArray(list) && list.map((item: any, index: number) => (
                        <React.Fragment key={index}>
                            {settingPermission?.hasOwnProperty(item?.name) && <div className="w-full">
                                <div className="w-full group">
                                    <div className={`${(item?.name === title) && "bg-[#F4F7FF] border-l-2 !border-primary"} flex justify-between items-center py-[16px] px-[24px] cursor-pointer group-hover:bg-[#F4F7FF]`} onClick={() => handleTabChange(item)}>
                                        <div className={`flex gap-[12px] items-center ${item?.name === title ? `${(item?.name === "Account Settings" || item?.name === "Sample Management") ? "stroke-primaryLight fill-primaryLight" : "fill-primaryLight"}` : `${(item?.name === "Account Settings" || item?.name === "Sample Management") ? "stroke-lGray fill-lGray group-hover:stroke-primaryLight group-hover:fill-primaryLight" : "fill-lGray group-hover:fill-primaryLight"}`}`}>
                                            <div className="min-w-[25px] h-fit flex items-center justify-center">
                                                {<item.icon />}
                                            </div>
                                            <h3 className={`${(item?.name === title) ? "text-primaryLight" : "text-lGray"} text-[0.938rem] 2xl:text-base line-clamp-1 group-hover:text-primaryLight font-normal`}>{item?.name}</h3>
                                        </div>
                                        {(item?.name === title) && <div className={`${isActivityOpen ? "hidden lg:block xlx:hidden" : "hidden lg:block"} cursor-pointer`}>
                                            <SettingRightArrow className="stroke-primaryLight" />
                                        </div>}

                                        {(item?.name === title) &&
                                            <div className={`${isActivityOpen ? "block lg:hidden xlx:block" : "block lg:hidden"} cursor-pointer`}>
                                                {toggle === item?.name ? <TestDetailDown color="#20328F" /> : <SettingRightArrow className="stroke-primaryLight" />}
                                            </div>}
                                    </div>
                                </div>
                                {(toggle === item?.name) && <div className={`${isActivityOpen ? "flex flex-col lg:hidden xlx:flex" : "flex lg:hidden flex-col"} w-full`}>
                                    {Array.isArray(customSearch) && customSearch.map((i: any, idx: number) => (
                                        <React.Fragment key={idx}>
                                            <>
                                                {settingPermission[item?.name]?.length > 0 ?
                                                    <>
                                                        {settingPermission[item?.name]?.includes(i?.name) && <div className="group w-full">
                                                            <div className={`${select?.name === i?.name ? "bg-[#F4F7FF]" : "group-hover:bg-[#F4F7FF]"} flex justify-between items-center py-[16px] px-[24px] cursor-pointer`} onClick={() => handleSubTab(i)}>
                                                                <h3 className={`${select?.name === i?.name ? "text-primaryLight" : "text-lGray"} font-normal text-[0.938rem] 2xl:text-base group-hover:text-primaryLight`}>{i?.name}</h3>
                                                            </div>
                                                        </div>}
                                                    </>
                                                    :
                                                    <div className="group w-full">
                                                        <div className={`${select?.name === i?.name ? "bg-[#F4F7FF]" : "group-hover:bg-[#F4F7FF]"} flex justify-between items-center py-[16px] px-[24px] cursor-pointer`} onClick={() => handleSubTab(i)}>
                                                            <h3 className={`${select?.name === i?.name ? "text-primaryLight" : "text-lGray"} font-normal text-[0.938rem] 2xl:text-base group-hover:text-primaryLight`}>{i?.name}</h3>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        </React.Fragment>
                                    ))}
                                </div>}
                            </div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>

            {prev && <div className="md:col-span-2 xxl:w-[290px] border border-[#dee6e980] rounded-[10px] shadow-[0px_5px_15px_0px_#dfeaff66] md:hidden block bg-white">
                <h3 className="text-primaryLight text-[1.063rem] xl:text-lg font-medium py-[16px] px-[24px] border-b border-[#dee6e980]">Settings</h3>

                <div className="flex flex-col py-[12px]">
                    {Array.isArray(list) && list.map((item: any, index: number) => (
                        <React.Fragment key={index}>
                            {settingPermission?.hasOwnProperty(item?.name) && <div className="w-full">
                                <div className="w-full group">
                                    <div className={`${(item?.name === title) && "bg-[#F4F7FF] border-l-2 !border-primary"} flex justify-between items-center py-[16px] px-[24px] cursor-pointer group-hover:bg-[#F4F7FF]`} onClick={() => handleTabChange(item)}>
                                        <div className={`flex gap-[12px] items-center ${item?.name === title ? `${(item?.name === "Account Settings" || item?.name === "Sample Management") ? "stroke-primaryLight fill-primaryLight" : "fill-primaryLight"}` : `${(item?.name === "Account Settings" || item?.name === "Sample Management") ? "stroke-lGray fill-lGray group-hover:stroke-primaryLight group-hover:fill-primaryLight" : "fill-lGray group-hover:fill-primaryLight"}`}`}>
                                            <div className="min-w-[25px] h-fit flex items-center justify-center">
                                                {<item.icon />}
                                            </div>
                                            <h3 className={`${(item?.name === title) ? "text-primaryLight" : "text-lGray"} text-[0.938rem] 2xl:text-base whitespace-nowrap overflow-hidden text-ellipsis font-normal group-hover:text-primaryLight`}>{item?.name}</h3>
                                        </div>
                                        {(item?.name === title) && <div className={`${isActivityOpen ? "hidden lg:block xlx:hidden" : "hidden lg:block"} cursor-pointer`}>
                                            <SettingRightArrow className="stroke-primaryLight" />
                                        </div>}

                                        {(item?.name === title) &&
                                            <div className={`${isActivityOpen ? "block lg:hidden xlx:block" : "block lg:hidden"} cursor-pointer`}>
                                                {toggle === item?.name ? <TestDetailDown color="#20328F" /> : <SettingRightArrow className="stroke-primaryLight" />}
                                            </div>}
                                    </div>
                                </div>
                                {(toggle === item?.name) && <div className={`${isActivityOpen ? "flex flex-col lg:hidden xlx:flex" : "flex lg:hidden flex-col"} w-full`}>
                                    {Array.isArray(customSearch) && customSearch.map((i: any, idx: number) => (
                                        <React.Fragment key={idx}>
                                            <>
                                                {settingPermission[item?.name]?.length > 0 ?
                                                    <>
                                                        {settingPermission[item?.name]?.includes(i?.name) && <div className="group w-full">
                                                            <div className={`${select?.name === i?.name ? "bg-[#F4F7FF]" : "group-hover:bg-[#F4F7FF] "} flex justify-between items-center py-[16px] px-[24px] cursor-pointer`} onClick={() => { handleSubTab(i); dispatch(settingActions.setPrev(false)) }}>
                                                                <h3 className={`${select?.name === i?.name ? "text-primaryLight" : "text-lGray"} text-[0.938rem] 2xl:text-base whitespace-nowrap overflow-hidden text-ellipsis font-normal group-hover:text-primaryLight`}>{i?.name}</h3>
                                                            </div>
                                                        </div>}
                                                    </>
                                                    :
                                                    <div className="group w-full">
                                                        <div className={`${select?.name === i?.name ? "bg-[#F4F7FF]" : "group-hover:bg-[#F4F7FF] "} flex justify-between items-center py-[16px] px-[24px] cursor-pointer`} onClick={() => { handleSubTab(i); dispatch(settingActions.setPrev(false)) }}>
                                                            <h3 className={`${select?.name === i?.name ? "text-primaryLight" : "text-lGray"} text-[0.938rem] 2xl:text-base whitespace-nowrap overflow-hidden text-ellipsis font-normal group-hover:text-primaryLight`}>{i?.name}</h3>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        </React.Fragment>
                                    ))}
                                </div>}
                            </div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>}
        </>
    );
};

export default SettingLayout;