/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import useAxios from "../../shared/hooks/use-axios";
import PrimaryInput from "./PrimaryInput";
import { settingActions } from "../../shared/store/setting-Slice";
import { useDispatch } from "react-redux";
import { LabelLine } from "../svg/AllSvg";
import SingleSelect from "./SingleSelect";

const CustomForm = ({ modal, fillModal, setCustomObj, id, grid, sectionStyle, page }: any) => {
    const [data, setData] = useState<any[]>([]);
    const [inputObj, setInputObj] = useState<any>({});
    const [saveValueObj, setSaveValueObj] = useState<any>({});
    const [inputSchema, setInputSchema] = useState<any>({});
    const dispatch = useDispatch();

    useEffect(() => {
        let obj: any = {};
        for (let i = 0; i < data.length; i++) {
            const el = data[i];
            for (let k = 0; k < el?.fields?.length; k++) {
                const e = el?.fields[k];
                if (e?.datatype === "enum") {
                    obj[e?.slug] = Object.keys(saveValueObj).includes(e?.slug) ? saveValueObj[e?.slug]?.value : ""
                } else {
                    obj[e?.slug] = saveValueObj[e?.slug] ? saveValueObj[e?.slug] : ""
                }
            }
        }

        let payload: any = {
            ...obj,
            model: modal,
            id: !!id ? id : ""

        }
        setCustomObj(payload);
    }, [saveValueObj])

    const getData = async () => {
        try {
            const res: any = await useAxios.get(`/utility/sections-wise-fields/?model=${modal}`);

            if (res && res.status === 200) {
                let arr: any[] = res?.data?.sections;
                let arr1: any[] = [];
                let obj1: any = {};
                if (res?.data?.defaults?.length > 0) {
                    obj1.id = res?.data?.sections?.length + 1
                    obj1.name = "Default"
                    obj1.fields = res?.data?.defaults

                    arr.push(obj1)
                }

                for (let i = 0; i < arr?.length; i++) {
                    const el = arr[i];
                    el.flag = false;
                    arr1.push(el)
                }

                setData(arr1)

                if (arr1?.length > 0) {
                    dispatch(settingActions.setCustomField(true))
                } else {
                    dispatch(settingActions.setCustomField(false))
                }

                let obj: any = {};
                let validationObj: any = {};
                for (let j = 0; j < arr1?.length; j++) {
                    const e = arr1[j];
                    for (let k = 0; k < e?.fields?.length; k++) {
                        const f = e?.fields[k];
                        obj[f?.slug] = "";
                        validationObj[f?.slug] = (f?.datatype === "int" || f?.datatype === "float" ? yup.number() : f?.datatype === "date" ? yup.date() : f?.datatype === "enum" ? yup.object() : yup.string())
                    }
                }

                setInputObj(obj);
                const schema = yup.object().shape(validationObj);
                setInputSchema(schema);
            }
        } catch (error) {
            console.error("error while getting custom field data", error);
        }
    };

    const fillData = async () => {
        try {
            const res: any = await useAxios.get(`/samples/api/get-custom-field-values/?model=${fillModal}&id=${id}`);

            if (res && res.status === 200) {
                if (res?.data.length > 0) {
                    let obj: any = {};
                    for (let i = 0; i < res?.data.length; i++) {
                        const el = res?.data[i];
                        if (el?.datatype === "int") {
                            obj[el?.slug] = !!el?.value_int ? el?.value_int : "";
                        }
                        if (el?.datatype === "text") {
                            obj[el?.slug] = !!el?.value_text ? el?.value_text : "";
                        }
                        if (el?.datatype === "enum") {
                            obj[el?.slug] = !!el?.choice_value ? { label: el?.choice_value, value: el?.choice_value } : "";
                        }
                        if (el?.datatype === "date") {
                            obj[el?.slug] = !!el?.choice_value ? el?.value_date : "";
                        }
                    }
                    setInputObj(obj);
                }
            }
        } catch (error) {
            console.error("error while fill data", error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        (data?.length > 0 && !!id) && fillData();
        // eslint-disable-next-line
    }, [data, id]);

    const handleSubmit = async (values: any, { resetForm }: any) => { }

    return (
        <div className='flex flex-col w-full' >
            <Formik
                enableReinitialize={true}
                initialValues={inputObj}
                validationSchema={inputSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
                    <Form>
                        <>
                            {setSaveValueObj(values)}
                        </>
                        <div className="w-full flex flex-col gap-[25px]">
                            {Array.isArray(data) && data?.map((item: any, index: number) => (
                                <div key={index} className="flex flex-col gap-[25px]">
                                    {
                                        !!page && (page === "lims" || page === "report") ?
                                            <div className="flex items-center gap-[10px]">
                                                <div className="w-[4px] h-[16px]">
                                                    <LabelLine className={`${page === "report" ? "fill-mGray" : "fill-dBlue"}`} />
                                                </div>
                                                <h2 className={`h-full ${page === "report" ? "text-mGray font-semibold" : "text-dBlue font-normal"} text-base leading-[20px]`}>
                                                    {item?.name}
                                                </h2>
                                            </div>
                                            :
                                            <h3 className={sectionStyle}>{item?.name}</h3>
                                    }

                                    <div className={`grid grid-cols-1 sm:grid-cols-2 ${grid === 1 ? "lg:grid-cols-1" : grid === 2 ? "lg:grid-cols-2" : grid === 3 ? "lg:grid-cols-3" : "lg:grid-cols-4"} w-full gap-x-[15px] gap-y-[25px]`}>
                                        {Array.isArray(item?.fields) && item?.fields?.map((i: any, idx: number) => (
                                            <React.Fragment key={idx}>
                                                {(i?.datatype === "text" || i?.datatype === "int" || i?.datatype === "float" || i?.datatype === "date") &&
                                                    < div className={`flex flex-col w-full ${i?.width === 1 ? "lg:col-span-3" : i?.width === 2 ? "lg:col-span-2" : "lg:col-span-1"}`} >
                                                        <PrimaryInput type={(i?.datatype === "int" || i?.datatype === "float") ? "number" : i?.datatype} title={i?.name} name={i?.slug} onChange={handleChange} onBlur={handleBlur} value={values[i?.slug]} />
                                                        <ErrorMessage
                                                            name={i?.slug}
                                                            component="div"
                                                            className="text-red-500 text-xs"
                                                        />
                                                    </div>
                                                }

                                                {i?.datatype === "enum" && <div className={`flex flex-col w-full ${i?.width === 1 ? "lg:col-span-3" : i?.width === 2 ? "lg:col-span-2" : "lg:col-span-1"}`}>
                                                    <SingleSelect options={Array.isArray(i?.enum_values) && i?.enum_values.map((i: any) => ({ label: i, value: i }))} placeholder={i?.name} name={i?.slug} value={values[i?.slug]} onChange={(e: any) => setFieldValue(i?.slug, e)} onBlur={(e: any) => {
                                                        setFieldTouched(i?.slug, e);
                                                    }} title={i?.name} />
                                                    <ErrorMessage
                                                        name={i?.slug}
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            ))}

                        </div>
                    </Form>
                )}
            </Formik>
        </div >
    );
};

export default CustomForm;