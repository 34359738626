/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { ParaValidated, ParametersIcon, TestSampleIcon } from "../../../components/svg/AllSvg";
import { uiActions } from "../../../shared/store/ui-Slice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "../../../components/labSample/digitalSign/Header";
import Sections from "../../../components/labSample/digitalSign/Sections";
import Test from "../../../components/labSample/common/Test";
import SampleInfo from "../../../components/labSample/common/SampleInfo";
import Moment from "moment";
import useAxios from "../../../shared/hooks/use-axios";
import PendingSigned from "../../../components/labSample/digitalSign/PendingSigned";
import DocSection from "../../../skeleton/components/common/DocSection";
import Signed from "../../../components/labSample/digitalSign/Signed";
import { getAWSFileUrl } from "../../../shared/utils/getAWSFileUrl";
import { labSampleActions } from "../../../shared/store/labSample-Slice";
import { getSampleDetail } from "../../../shared/utils/apis";
import { useScreen } from "../../../shared/hooks/use-Screen";
import { useModule } from "../../../shared/hooks/use-module";
import { messages } from "../../../shared/utils/message";

const DigitalSign = ({ role, mode }: any) => {
    const component: string = useSelector((state: any) => state.labSample.component);
    const user: any = useSelector((state: any) => state.auth.user);
    const sampleDetail = useSelector((state: any) => state.labSample.sampleDetail);
    const isOpen: boolean = useSelector((state: any) => state.ui.isOpen);
    const screenShotList: any[] = useSelector((state: any) => state.ui.screenShotList);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isSignedLoading, setIsSignedLoading] = useState<boolean>(true)
    const [signedData, setSignedData] = useState<any[]>([]);
    let [unSignedData, setUnSignedData] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [trDetails, setTrDetails] = useState<any>(null);
    const [trLoading, setTrLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    useScreen("doc");
    useModule(role === "customer" ? "tests" : "sample");

    const menu = role === "lab" ? ((component === messages?.sample_tab?.pending_sign) ?
        [
            { id: 1, icon: TestSampleIcon, name: "Sample Summary", eId: "sample_summary", submenu: [] },
            { id: 2, icon: ParametersIcon, name: "Pending for reviewer sign", eId: "pending_for_sign", submenu: [], test: true },
            { id: 3, icon: ParaValidated, name: "Pending for approver sign", eId: "signed", submenu: [], test: true }
        ]
        :
        [
            { id: 1, icon: TestSampleIcon, name: "Sample Summary", eId: "sample_summary", submenu: [] },
            { id: 2, icon: ParaValidated, name: "Signed", eId: "signed", submenu: [], test: true }
        ]
    )
        : [
            { id: 1, icon: TestSampleIcon, name: "Sample Summary", eId: "sample_summary", submenu: [] },
            { id: 2, icon: ParametersIcon, name: "UnSigned Report", eId: "pending_for_sign", submenu: [], test: true },
            { id: 3, icon: ParaValidated, name: "Signed Report", eId: "signed", submenu: [], test: true }
        ];

    const handleScreenshot = async () => {
        let arr: any[] = screenShotList?.filter((item: any) => item?.url !== location?.pathname);
        dispatch(uiActions.assignScreenShotList(arr))
        dispatch(uiActions.updateScreenShotList({ title: "Sample Code", detail: `#${sampleDetail?.sample_code}`, link: window.location.href, url: location.pathname, date: Moment(new Date()).format("DD-MM-YYYY HH:mm"), role: user?.role, fullScreen: false, moduleName: "lims" }));
        localStorage.setItem('screenshot', JSON.stringify([...screenShotList, { title: "Sample Code", detail: `#${sampleDetail?.sample_code}`, link: window.location.href, url: location.pathname, date: Moment(new Date()).format("DD-MM-YYYY HH:mm"), role: user?.role, fullScreen: false, moduleName: "lims" }]) as any);
        navigate(-1);
        setLoader(false)
        localStorage.setItem('full-view', false as any);
        dispatch(uiActions.setFullView(false));
    };

    useEffect(() => {
        loader && handleScreenshot();
    }, [loader]);

    const getSignedReport = async () => {
        try {
            setIsSignedLoading(true)
            let url: any = "";
            (component === messages?.sample_tab?.pending_sign) ? url = await useAxios.get(`/reports/generated-parameter-reports/?sample_dept_id=${role === "customer" ? params?.dept : sampleDetail?.forwarded_sample?.[0]?.id}&signed=True&approver_signed=False`) : url = await useAxios.get(`/reports/generated-parameter-reports/?sample_dept_id=${role === "customer" ? params?.dept : sampleDetail?.forwarded_sample?.[0]?.id}&signed=True`)

            if (!!url) {
                const res: any = url;
                if (res && res?.status === 200) {
                    if (res?.data?.length > 0) {
                        let arr: any = [];
                        let arr1: any = [];
                        for (let i = 0; i < res?.data?.length; i++) {
                            const el = res?.data[i];
                            const fileUrl = await getAWSFileUrl(`signed-report/${el?.report_no}_signed.pdf`, user?.tenant_name)
                            el.fileUrl = fileUrl
                            el.file = `signed-report/${el?.report_no}_signed.pdf`
                            el.trFile = `test-report/${el?.report_no}.pdf`
                            arr.push(el)

                            if (role === "customer") {
                                let obj: any = { ...el }
                                const fileUrl1 = await getAWSFileUrl(`test-report/${obj?.report_no}.pdf`, user?.tenant_name)
                                obj.fileUrl = fileUrl1
                                obj.file = `test-report/${obj?.report_no}.pdf`
                                arr1.push(obj)
                            }
                        }
                        setSignedData(arr);
                        if (role === "customer") {
                            unSignedData = unSignedData.concat(arr1)
                            setUnSignedData(unSignedData)
                        }
                    }
                    setIsSignedLoading(false)
                }
            }
        } catch (error) {
            setIsSignedLoading(false)
            console.error("error while get signed report detail", error);
        }
    };

    const getUnSignedReport = async () => {
        try {
            setIsLoading(true)
            const res: any = await useAxios.get(`/reports/generated-parameter-reports/?sample_dept_id=${role === "customer" ? params?.dept : sampleDetail?.forwarded_sample?.[0]?.id}&signed=False`);

            if (res && res?.status === 200) {
                let arr: any[] = [];
                if (res?.data?.length > 0) {
                    for (let i = 0; i < res?.data?.length; i++) {
                        const el = res?.data[i];
                        const fileUrl = await getAWSFileUrl(`test-report/${el?.report_no}.pdf`, user?.tenant_name)
                        el.fileUrl = fileUrl
                        el.file = `test-report/${el?.report_no}.pdf`
                        arr.push(el)
                    }
                }
                unSignedData = unSignedData.concat(arr)
                setUnSignedData(unSignedData)
                setIsLoading(false)
            }

        } catch (error) {
            setIsLoading(false)
            console.error("error while get unsigned report detail", error);
        }
    };

    useEffect(() => {
        if (role === "customer") {
            !!params?.dept && getSignedReport();
            !!params?.dept && getUnSignedReport();
        }
    }, [role, params?.dept])

    useEffect(() => {
        if (role === "lab" && !!component) {
            if (component === messages?.sample_tab?.pending_sign) {
                !!sampleDetail?.forwarded_sample?.[0]?.id && getUnSignedReport()
                !!sampleDetail?.forwarded_sample?.[0]?.id && getSignedReport()
            } else {
                !!sampleDetail?.forwarded_sample?.[0]?.id && getSignedReport()
            }
        }
    }, [role, sampleDetail?.forwarded_sample?.[0]?.id, component])

    const getTRDetail = async (test_request: any) => {
        try {
            const res: any = await useAxios.get(`/samples/api/test-requests/${test_request}/`);

            if (res && res.status === 200) {
                setTrDetails(res?.data)
                setTrLoading(false)
            }
        } catch (error) {
            console.error("error while getting tr details", error);
        }
    }

    const getSample = async () => {
        try {
            const { data, componentList, status }: any = await getSampleDetail(params?.id);
            if (status === 200) {
                dispatch(labSampleActions.setSampleDetail(data));
                getTRDetail(data?.test_request);
            }

        } catch (error) {
            console.error("error while getting sample details", error);
        }
    }

    useEffect(() => {
        (role === "customer" && !!params?.id) && getSample();
    }, [role, params?.id])

    return (
        <div className={`w-full h-full flex justify-center px-[24px] pb-[24px] ${isOpen ? "gap-[15px] 3xl:gap-[20px]" : "gap-[15px] 1xl:gap-[20px]"}`}>
            {/* sections */}
            <Sections menu={menu} />

            <div className={`flex flex-col gap-y-[15px] h-full ${isOpen ? "min-w-[780px] lg:w-full 2xl:max-w-[990px]" : "min-w-[780px] lg:w-full xxl:max-w-[990px]"}`}>
                {/* header */}
                <Header setLoader={setLoader} loader={loader} role={role} trLoading={trLoading} trDetails={trDetails} />

                <div className="flex flex-col w-full">
                    {/* test */}
                    <Test />

                    <div className="w-full px-[4px] gap-y-[15px] flex flex-col">
                        {/* sample info */}
                        <SampleInfo />

                        <>
                            {(role === "customer" || component === messages?.sample_tab?.pending_sign) &&
                                <>
                                    {/* pending for signed */}
                                    {isLoading ?
                                        <DocSection /> :
                                        <>
                                            <PendingSigned unSignedData={unSignedData} mode={mode} role={role} />
                                        </>}
                                </>
                            }
                        </>

                        {/* signed */}
                        {isSignedLoading ?
                            <DocSection /> :
                            <>
                                <Signed signedData={signedData} mode={mode} role={role} />
                            </>}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default DigitalSign;