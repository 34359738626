import SettingLayout from "../../components/setting/SettingLayout";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const SettingsLayout = () => {
    const prev: string = useSelector((state: any) => state.ui.prev);

    return (
        <div className="w-full xxl:flex grid md:grid-cols-6 lg:grid-cols-8 p-[24px] gap-[16px] min-h-[calc(100vh-70px)]">
            <SettingLayout />

            <div className={`w-full md:col-span-4 lg:col-span-6 xxl:w-[calc(100%-306px)] border border-[#dee6e980] rounded-[10px] shadow-[0px_5px_15px_0px_#dfeaff66] flex flex-col bg-white ${!prev ? "" : "hidden md:block"}`}>
                <Outlet />
            </div>
        </div>
    );
};

export default SettingsLayout;