import Select from "react-select";
import { useSelect } from "../../shared/hooks/use-select";

const SingleSelect = ({ options, placeholder, name, title, value, onChange, onBlur, isDisabled, minMenuHeight, menuPlacement, maxMenuHeight, isSearchable, bg, cursor, color, components, filterOption, required, text, id }: any) => {
    let styles = useSelect(true, true, "38px", !!cursor ? cursor : "text", !!color ? color : "#6B7280");

    return (
        <div className={`relative w-full ${isDisabled && "opacity-[0.6] bg-[#fafafa]"} ${text && "border border-[#CBCBCB] rounded-[6px]"}`} style={{ paddingRight: text ? `${!!document.getElementById(id)?.offsetWidth ? `${(+((document.getElementById(id) as any)?.offsetWidth) + 21)}px` : "0"}` : "0" }}>
            <Select
                options={options}
                name={name}
                className={`${text ? "w-full border border-white rounded-[6px] rounded-r-0 mr-[2px]" : "w-full border border-[#CBCBCB] rounded-[6px]"} relative bg-transparent block appearance-none peer`}
                placeholder={placeholder}
                styles={styles}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isDisabled={isDisabled}
                minMenuHeight={minMenuHeight}
                menuPlacement={menuPlacement}
                maxMenuHeight={maxMenuHeight}
                isSearchable={isSearchable}
                components={components}
                filterOption={filterOption}
            />
            {required && <div className="absolute top-0 right-0 border-r-[4px] rounded-r-[6px] border-[#ff0000] h-full"></div>}
            {text && <div className="absolute right-0 top-0 border-l border-[#CBCBCB] px-[10px] flex items-center justify-center h-full">
                <h4 className="text-primaryLight text-sm font-normal" id={id}>{text}</h4>
            </div>}
            <label
                htmlFor={name}
                className={`${!!bg ? bg : "bg-white"}  absolute left-[8px] top-[4px] font-normal text-xs text-mGray duration-300 transform -translate-y-4 scale-[0.85] origin-[0] px-[8px] peer-focus:px-[8px] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[4px] peer-focus:scale-[0.85] peer-focus:-translate-y-[16px] pointer-events-none`}
            >
                {title}
            </label>
        </div>
    );
};

export default SingleSelect;
