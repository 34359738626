/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ScreenRightArrow } from "../../components/svg/AllSvg";
import { useEffect, useState } from "react";
import Task from "../../components/layout/Common/Task";
import Note from "../../components/layout/Common/Note";
import Chat from "../../components/layout/Common/Chat";
import ActivitySidebar from "../../components/layout/Common/ActivitySidebar";
import Header from "../../components/layout/Common/Header";
import { useSortcut } from "../../shared/hooks/use-sortcut";
import { invoiceActions } from "../../shared/store/invoice-Slice";
import AddCustomer from "../../components/invoice/customer/dashboard/AddCustomer";
import AssignGroup from "../../components/invoice/customer/dashboard/AssignGroup";
import SuccessPopup from "../../components/invoice/customer/dashboard/SuccessPopup";
import RecordPaymentPopup from "../../components/invoice/invoice/dashboard/RecordPaymentPopup";
import Product from "../../components/invoice/invoice/dashboard/Product";
import Billing from "../../components/invoice/invoice/dashboard/Billing";
import Shipping from "../../components/invoice/invoice/dashboard/Shipping";
import SelectCustomer from "../../components/invoice/invoice/dashboard/SelectCustomer";
import MultiRecordPayment from "../../components/invoice/invoice/dashboard/MultiRecordPayment";
import SuccessRecordPayment from "../../components/invoice/invoice/dashboard/SuccessRecordPayment";
import AdjustPayment from "../../components/invoice/invoice/dashboard/AdjustPayment";
import AdjustPaymentInvoice from "../../components/invoice/invoice/dashboard/AdjustPaymentInvoice";
import CreateCreditNote from "../../components/invoice/creditNote/dashboard/CreateCreditNote";
import { uiActions } from "../../shared/store/ui-Slice";
import Search from "../../components/layout/Common/Search";
import { groupByApi, listApi, tabApi } from "../../shared/utils/apis";
import { tableActions } from "../../shared/store/table-Slice";
import CustomerGridView from "../../components/invoice/customer/dashboard/GroupView";
import CreditNoteGridView from "../../components/invoice/creditNote/dashboard/GroupView";
import CreditPolicyGridView from "../../components/invoice/creditPolicy/dashboard/GridView";
import InvoiceGridView from "../../components/invoice/invoice/dashboard/GridView";
import CreditPolicyTableView from "../../components/invoice/creditPolicy/dashboard/TableView";
import CreditNoteTableView from "../../components/invoice/creditNote/dashboard/TableView";
import CustomerTableView from "../../components/invoice/customer/dashboard/TableView";
import InvoiceTableView from "../../components/invoice/invoice/dashboard/TableView";
import ViewAllList from "../../components/form/ViewAllList";
import PaymentGridView from "../../components/invoice/payment/dashboard/GridView";
import PaymentTableView from "../../components/invoice/payment/dashboard/TableView";
import OrderGridView from "../../components/invoice/order/dashboard/GridView";
import OrderTableView from "../../components/invoice/order/dashboard/TableView";
import MiniSidebar from "../../components/layout/Common/MiniSidebar";
import OrderRecordPaymentPopup from "../../components/invoice/order/dashboard/OrderRecordPaymentPopup";

let addQtyOptionList: any[] = [
    { id: 1, text: "All New Dues", count: "250" }
]

let addQty: any[] = [
    { id: 1, org: { name: "Pratik Agrawal", org_name: "South Indian Railway" }, req_date: "10 June 2023", tr_number: "#3214ER", sample_name: "Quos reiciendis ad quos reiciendis ad...", amount: 50000 },
    { id: 2, org: { name: "Pratik Agrawal", org_name: "South Indian Railway" }, req_date: "10 June 2023", tr_number: "#3214ER", sample_name: "Quos reiciendis ad quos reiciendis ad...", amount: 50000 },
]

const InvoiceLayout: React.FC<any> = ({ role, path }) => {
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const isOpen: boolean = useSelector((state: any) => state.ui.isOpen);
    const permissionRole: any = useSelector((state: any) => state.auth.permissionRole);
    const successCreateGroup: boolean = useSelector((state: any) => state.invoice.successCreateGroup);
    const adjustPayment: boolean = useSelector((state: any) => state.invoice.adjustPayment);
    const adjustPaymentInvoice: boolean = useSelector((state: any) => state.invoice.adjustPaymentInvoice);
    const recordPaymentOpen: boolean = useSelector((state: any) => state.invoice.recordPaymentOpen);
    const selectCustOpen: boolean = useSelector((state: any) => state.invoice.selectCustOpen);
    const paymentSuccess: boolean = useSelector((state: any) => state.invoice.paymentSuccess);
    const creditNote: boolean = useSelector((state: any) => state.invoice.creditNote);
    const apiCall: any = useSelector((state: any) => state.invoice.apiCall);
    const sideBarOption: string = useSelector((state: any) => state.ui.sideBarOption);
    const chatUsersList: any[] = useSelector((state: any) => state.ui.chatUsersList);
    const isCustomerCreate: boolean = useSelector((state: any) => state.invoice.isCustomerCreate);
    const createGroup: boolean = useSelector((state: any) => state.invoice.createGroup);
    const recordPayment: boolean = useSelector((state: any) => state.invoice.recordPayment);
    const orderRecordPayment: boolean = useSelector((state: any) => state.invoice.orderRecordPayment);
    const productOpen: boolean = useSelector((state: any) => state.invoice.productOpen);
    const billOpen: boolean = useSelector((state: any) => state.invoice.billOpen);
    const shipOpen: boolean = useSelector((state: any) => state.invoice.shipOpen);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const creditPolicyOption: string = useSelector((state: any) => state.invoice.creditPolicyOption);
    const addQtyOption: string = useSelector((state: any) => state.invoice.addQtyOption);
    const tagApiCall: boolean = useSelector((state: any) => state.invoice.tagApiCall);
    const option: any = useSelector((state: any) => state.table.option);
    const sortBy: string = useSelector((state: any) => state.table.sortBy);
    const filterResult: string = useSelector((state: any) => state.table.filterResult);
    const page: number = useSelector((state: any) => state.table.page);
    const size: number = useSelector((state: any) => state.table.size);
    const groupByOptionValue: string = useSelector((state: any) => state.table.groupByOptionValue);
    const viewAll: boolean = useSelector((state: any) => state.table.viewAll);
    const childSize: number = useSelector((state: any) => state.table.childSize);
    const childPage: number = useSelector((state: any) => state.table.childPage);
    const groupByExpand: any = useSelector((state: any) => state.table.groupByExpand);
    const success: boolean = useSelector((state: any) => state.table.success);
    const module: string = useSelector((state: any) => state.ui.module);
    const subModule: string = useSelector((state: any) => state.ui.subModule);
    const selectOption: boolean = useSelector((state: any) => state.table.selectOption);
    const scrollY: boolean = useSelector((state: any) => state.ui.scrollY);
    const dispatch = useDispatch();
    const location = useLocation();
    const [roleName, setRoleName] = useState<string>("");

    const handleOpen = (): void => {
        setIsSearch(true);
    };

    useSortcut(["Control", "s"], handleOpen);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000)
        }
    }, [isOpen, sideBarOption]);

    useEffect(() => {
        dispatch(uiActions.setSettingPath(path))
        dispatch(uiActions.setModuleName("invoice"))
    }, []);

    useEffect(() => {
        if (permissionRole.length > 0) {
            let getRole: any = role?.filter((item: any) => permissionRole?.toString()?.includes(item))
            setRoleName(getRole[0])
        }
    }, [permissionRole])

    useEffect(() => {
        if (location.pathname !== "/invoice") {
            dispatch(invoiceActions.setChatUser(chatUsersList[0]));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        !!module && getTagList();
    }, [module, subModule])

    const getTagList = async () => {
        try {
            let url: any;
            if (module === "customer") {
                option?.module !== "customer" && dispatch(tableActions.setClear());
                url = "/customers/api/customers-ui-tab-config/";
            }
            if (module === "credit-note") {
                option?.module !== "credit-note" && dispatch(tableActions.setClear());
                url = "/payment/credit-note-tab-configuration-list";
            }
            if (module === "credit-policy") {
                option?.module !== "credit-policy" && dispatch(tableActions.setClear());
                url = "/customers/api/credit-policy-tab-config-list/";
            }
            if (module === "invoice-payment") {
                option?.module !== "invoice-payment" && dispatch(tableActions.setClear());
                url = "/invoices/paid-invoice-tab-list/";
            }
            if (module === "order") {
                option?.module !== "order" && dispatch(tableActions.setClear());
                url = "/payment/order-tab-api/";
            }
            if (module === "invoice" && subModule === "proforma-invoice") {
                (option?.module !== "invoice" && subModule !== "proforma-invoice") && dispatch(tableActions.setClear());
                url = "/invoices/proforma-invoice-tab-list/";
            }
            if (module === "invoice" && subModule === "tax-invoice") {
                (option?.module !== "invoice" && subModule !== "tax-invoice") && dispatch(tableActions.setClear());
                url = "/invoices/tax-invoice-tab-list/";
            }
            if (module === "invoice" && subModule === "bill-of-supply") {
                (option?.module !== "invoice" && subModule !== "bill-of-supply") && dispatch(tableActions.setClear());
                url = "/invoices/bill-of-supply-tab-list/";
            }
            if (!!url) {
                const { tabArr, sortBy, groupBby, filterArr, status, fieldType }: any = await tabApi(url);

                if (status === 200) {
                    dispatch(tableActions.setTagList(tabArr));
                    dispatch(tableActions.setSortByList(sortBy));
                    dispatch(tableActions.setGroupByList(groupBby));
                    dispatch(tableActions.setFilterList(filterArr));
                    dispatch(tableActions.setFieldType(fieldType));
                    if (!!module && module === "customer") {
                        !!!selectOption ? dispatch(tableActions.changeOption({ name: tabArr[0]?.name, module: 'customer' })) : dispatch(tableActions.changeOption({ name: selectOption, module: 'customer' }))
                    }
                    if (!!module && module === "credit-note") {
                        !!!selectOption ? dispatch(tableActions.changeOption({ name: tabArr[0]?.name, module: 'credit-note' })) : dispatch(tableActions.changeOption({ name: selectOption, module: 'credit-note' }))
                    }
                    if (!!module && module === "credit-policy") {
                        !!!selectOption ? dispatch(tableActions.changeOption({ name: tabArr[0]?.name, module: 'credit-policy' })) : dispatch(tableActions.changeOption({ name: selectOption, module: 'credit-policy' }))
                    }
                    if (!!module && module === "invoice-payment") {
                        !!!selectOption ? dispatch(tableActions.changeOption({ name: "invoice-payment", module: 'invoice-payment' })) : dispatch(tableActions.changeOption({ name: selectOption, module: 'invoice-payment' }))
                    }
                    if (!!module && module === "order") {
                        !!!selectOption ? dispatch(tableActions.changeOption({ name: tabArr[0]?.name, module: 'order' })) : dispatch(tableActions.changeOption({ name: selectOption, module: 'order' }))
                    }
                    if (!!module && module === "invoice" && subModule === "proforma-invoice") {
                        !!!selectOption ? dispatch(tableActions.changeOption({ name: "ProformaInvoice", module: 'invoice' })) : dispatch(tableActions.changeOption({ name: selectOption, module: 'invoice' }))
                    }
                    if (!!module && module === "invoice" && subModule === "tax-invoice") {
                        !!!selectOption ? dispatch(tableActions.changeOption({ name: tabArr[0]?.name, module: 'invoice' })) : dispatch(tableActions.changeOption({ name: selectOption, module: 'invoice' }))
                    }
                    if (!!module && module === "invoice" && subModule === "bill-of-supply") {
                        !!!selectOption ? dispatch(tableActions.changeOption({ name: tabArr[0]?.name, module: 'invoice' })) : dispatch(tableActions.changeOption({ name: selectOption, module: 'invoice' }))
                    }
                }
            }
            if (module === "additional-qty") {
                // const res: any = await useAxios.get("/payment/credit-note-tab-list/");
                // if (res && res?.status === 200) {
                dispatch(invoiceActions.setAddQtyTagList(addQtyOptionList));
                !!!creditPolicyOption && dispatch(invoiceActions.setAddQtyOption(addQtyOptionList[0]?.text))
                // }
            }
            dispatch(invoiceActions.setTagApiCall(false))
        } catch (error: any) {
            console.error("error while feching tags", error);
        }
    }

    const getList = async () => {
        try {
            dispatch(tableActions.setLoading(true));
            let apiUrl: any;
            if (module === "customer" && option?.module === "customer") {
                viewAll ? apiUrl = "/customers/api/customers-list-group-by" : apiUrl = "/customers/api/customers-custom-list";
            }
            if (module === "invoice-payment" && option?.module === "invoice-payment") {
                viewAll ? apiUrl = "/invoices/paid-invoice-group-by-api" : apiUrl = "/invoices/api/paid-tax-invoices";
            }
            if (module === "credit-note" && option?.module === "credit-note") {
                viewAll ? apiUrl = "/payment/credit-note-group-by" : apiUrl = "/payment/api/custom-credit-note-list";
            }
            if (module === "credit-policy" && option?.module === "credit-policy") {
                viewAll ? apiUrl = "/customers/api/credit-policy-group-by" : apiUrl = "/customers/api/custom-credit-policy";
            }
            if (module === "order" && option?.module === "order") {
                viewAll ? apiUrl = "/payment/order-group-by" : apiUrl = "/payment/api/order-list";
            }
            if (module === "invoice" && option?.module === "invoice" && subModule === "proforma-invoice") {
                viewAll ? apiUrl = "/invoices/proforma-invoice-group-by-api" : apiUrl = "/invoices/api/custom-proforma-invoices";
            }
            if (module === "invoice" && option?.module === "invoice" && subModule === "tax-invoice") {
                viewAll ? apiUrl = "/invoices/tax-invoice-group-by-api" : apiUrl = "/invoices/api/custom-tax-invoices";
            }
            if (module === "invoice" && option?.module === "invoice" && subModule === "bill-of-supply") {
                viewAll ? apiUrl = "/invoices/bill-of-supply-group-by-api" : apiUrl = "/invoices/api/custom-bill-of-supplies";
            }
            if (!!apiUrl) {
                const {
                    data,
                    count,
                    defaultFields,
                    detailFieldArr,
                    frontFieldList,
                    status
                }: any = await listApi(apiUrl, (option?.name === "invoice-payment" || option?.name === "proforma-invoice") ? "" : option?.name, sortBy, page, size, filterResult, module === "invoice" ? `${subModule}_${option?.name}` : module, groupByOptionValue, childPage, childSize, groupByExpand, viewAll);

                if (status === 200) {
                    dispatch(tableActions.setLoading(false));
                    dispatch(tableActions.setData(data));
                    viewAll ? dispatch(tableActions.setChildTotal(count)) : dispatch(tableActions.setTotal(count))
                    dispatch(tableActions.setDefaultField(defaultFields));
                    dispatch(tableActions.setDetailField(detailFieldArr));
                    dispatch(tableActions.setFrontField(frontFieldList));
                    dispatch(tableActions.setSuccess(false));
                    dispatch(invoiceActions.setApiCall(""));
                }
            }
        } catch (error) {
            console.error("error while feching data", error);
        }
    }

    const getListGroupBy = async () => {
        try {
            dispatch(tableActions.setLoading(true));
            let apiUrl: any;
            if (module === "customer" && option?.module === "customer") {
                apiUrl = "/customers/api/customers-list-group-by"
            }
            if (module === "invoice-payment" && option?.module === "invoice-payment") {
                apiUrl = "/invoices/paid-invoice-group-by-api"
            }
            if (module === "credit-note" && option?.module === "credit-note") {
                apiUrl = "/payment/credit-note-group-by"
            }
            if (module === "credit-policy" && option?.module === "credit-policy") {
                apiUrl = "/customers/api/credit-policy-group-by"
            }
            if (module === "order" && option?.module === "order") {
                apiUrl = "/payment/order-group-by"
            }
            if (module === "invoice" && option?.module === "invoice" && subModule === "proforma-invoice") {
                apiUrl = "/invoices/proforma-invoice-group-by-api"
            }
            if (module === "invoice" && option?.module === "invoice" && subModule === "tax-invoice") {
                apiUrl = "/invoices/tax-invoice-group-by-api"
            }
            if (module === "invoice" && option?.module === "invoice" && subModule === "bill-of-supply") {
                apiUrl = "/invoices/bill-of-supply-group-by-api"
            }

            if (!!apiUrl) {
                const {
                    data,
                    count,
                    defaultFields,
                    detailFieldArr,
                    frontFieldList,
                    status
                }: any = await groupByApi(apiUrl, (option?.name === "invoice-payment" || option?.name === "proforma-invoice") ? "" : option?.name, sortBy, page, size, filterResult, groupByOptionValue, module === "invoice" ? `${subModule}_${option?.name}` : module);

                if (status === 200) {
                    dispatch(tableActions.setLoading(false));
                    dispatch(tableActions.setGroupByData(data));
                    dispatch(tableActions.setTotal(count));
                    dispatch(tableActions.setDefaultField(defaultFields));
                    dispatch(tableActions.setDetailField(detailFieldArr));
                    dispatch(tableActions.setFrontField(frontFieldList));
                    dispatch(tableActions.setSuccess(false));
                    dispatch(invoiceActions.setApiCall(""));
                }
            }
        } catch (error: any) {
            console.error("error while feching data", error);
        }
    }

    const getAddQtylist = async () => {
        try {
            dispatch(invoiceActions.setCRMLoading(true));
            dispatch(invoiceActions.setCRMList(addQty));
            dispatch(invoiceActions.setApiCall(""));
            dispatch(tableActions.setSuccess(false));
            dispatch(invoiceActions.setCRMLoading(false));
        } catch (error) {
            console.error("error while feching credit list", error);
        }
    }

    useEffect(() => {
        ((!!!groupByOptionValue || viewAll) && !!option?.name) && getList();
        // eslint-disable-next-line
    }, [option, sortBy, page, filterResult, size, viewAll, childSize, childPage, module, subModule])

    useEffect(() => {
        (!!groupByOptionValue && !!option?.name) && getListGroupBy();
        // eslint-disable-next-line
    }, [groupByOptionValue, filterResult, sortBy, option, page, size, module, subModule])

    useEffect(() => {
        if (success) {
            ((!!!groupByOptionValue || viewAll) && !!option?.name) && getList();
            (!!groupByOptionValue && !!option?.name) && getListGroupBy();
        }
        // eslint-disable-next-line
    }, [success, option])


    useEffect(() => {
        !!addQtyOption && getAddQtylist();
        // eslint-disable-next-line
    }, [addQtyOption])

    useEffect(() => {
        if (apiCall === "customer" || apiCall === "invoice" || apiCall === "credit-note" || apiCall === "payment" || apiCall === "credit-policy" || apiCall === "order") {
            ((!!!groupByOptionValue || viewAll) && !!option?.name) && getList();
            (!!groupByOptionValue && !!option?.name) && getListGroupBy();
        }
        apiCall === "additional-qty" && getAddQtylist();
        // eslint-disable-next-line
    }, [apiCall])

    useEffect(() => {
        tagApiCall && getTagList();
        // eslint-disable-next-line
    }, [tagApiCall])

    useEffect(() => {
        if (isCustomerCreate || createGroup || successCreateGroup || recordPayment || productOpen || billOpen || shipOpen || recordPaymentOpen || paymentSuccess || adjustPayment || adjustPaymentInvoice || creditNote || selectCustOpen || isSearch || viewAll || orderRecordPayment) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isCustomerCreate, createGroup, successCreateGroup, recordPayment, productOpen, billOpen, shipOpen, recordPaymentOpen, paymentSuccess, adjustPayment, adjustPaymentInvoice, creditNote, selectCustOpen, isSearch, viewAll, orderRecordPayment]);

    useEffect(() => {
        if (window.innerWidth <= 1200 && isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [window.innerWidth, isOpen])

    return (
        <>
            {isCustomerCreate && <AddCustomer />}
            {createGroup && <AssignGroup />}
            {successCreateGroup && <SuccessPopup />}
            {recordPayment && <RecordPaymentPopup />}
            {orderRecordPayment && <OrderRecordPaymentPopup />}
            {(productOpen && window.location.pathname !== "/setting/manage-product") && <Product />}
            {billOpen && <Billing />}
            {shipOpen && <Shipping />}
            {selectCustOpen && <SelectCustomer />}
            {recordPaymentOpen && <MultiRecordPayment />}
            {paymentSuccess && <SuccessRecordPayment />}
            {adjustPayment && <AdjustPayment />}
            {adjustPaymentInvoice && <AdjustPaymentInvoice />}
            {creditNote && <CreateCreditNote />}
            {viewAll && <ViewAllList grid={module === "customer" ? <CustomerGridView type="group" /> : module === "credit-note" ? <CreditNoteGridView type="group" /> : module === "credit-policy" ? <CreditPolicyGridView type="group" /> : module === "order" ? <OrderGridView type="group" /> : module === "invoice-payment" ? <PaymentGridView type="group" /> : <InvoiceGridView type="group" />} table={module === "customer" ? <CustomerTableView type="group" /> : module === "credit-note" ? <CreditNoteTableView type="group" /> : module === "credit-policy" ? <CreditPolicyTableView type="group" /> : module === "order" ? <OrderTableView type="group" /> : module === "invoice-payment" ? <PaymentTableView type="group" /> : <InvoiceTableView type="group" />} />}

            <div className="w-full h-full relative overflow-x-hidden">
                {/* Backdrop */}
                <div>
                    <span
                        id="menu-remove"
                        className={`fixed top-0 left-0 z-30 ${isOpen ? "block" : "hidden"
                            } h-screen w-full bg-[#00000080] xlx:hidden`}
                        onClick={() => dispatch(uiActions.toggle())}
                    ></span>
                </div>

                {/* Close Section Icon */}
                {(permissionRole?.includes(roleName)) && <div className={`bg-white w-[24px] h-[24px] shadow-md flex items-center justify-center rounded-full fixed top-[96px] cursor-pointer z-[5] right-[38px]`} onClick={() => { !isOpen && setIsLoading(true); dispatch(uiActions.toggle()); }}>
                    <ScreenRightArrow color="#20328F" />
                </div>}

                {/* Header */}
                <Header setIsSearch={setIsSearch} />

                <div className="w-full min-h-screen bg-[#F8FAFB] flex pt-[70px]">
                    {/* Sidebar */}
                    {(permissionRole?.includes(roleName)) && <MiniSidebar />}

                    <div className={`!h-full ${isOpen ? "w-full xlx:w-[calc(100%-300px)]" : "w-full"} transition-all flex overflow-x-hidden px-[50px]`}>
                        <Outlet />

                        <Search isSearch={isSearch} setIsSearch={setIsSearch} />

                        <div className={`${isOpen ? "w-full sm:w-[300px] fixed right-0 bottom-0 z-30 xlx:z-[2] xlx:right-[50px] bg-white" : "translate-x-full w-0"} overflow-y-auto overflow-x-hidden transition duration-150 ${scrollY ? "top-0 xlx:top-[75px]" : "top-0 xlx:top-[70px]"}`}>
                            {/* Activity */}
                            {isLoading ? <div className="w-full h-full flex items-center justify-center">
                                <div className="circle circle-1"></div>
                                <div className="circle circle-2"></div>
                                <div className="circle circle-3"></div>
                                <div className="circle circle-4"></div>
                                <div className="circle circle-5"></div>
                            </div> :
                                <>
                                    {(!isLoading && sideBarOption === "Todo") && <Task />}
                                    {(!isLoading && sideBarOption === "Note") && <Note />}
                                    {(!isLoading && sideBarOption === "Chat") && <Chat />}
                                </>
                            }
                        </div>
                    </div>

                    {/* Activity right Navbar */}
                    {(permissionRole?.includes(roleName)) && <ActivitySidebar setIsLoading={setIsLoading} />}
                </div>
            </div>
        </>
    );
};

export default InvoiceLayout;