import { useEffect, useState } from 'react';
import { TaskArrow } from '../../../components/svg/AllSvg';
import { settingActions } from '../../../shared/store/setting-Slice';
import { useDispatch } from 'react-redux';
import TemplateSettingList from '../../../components/setting/TemplateSettingList';
import CreateNewTemplate from '../../../components/setting/CreateNewTemplate';
import EditTemplate from '../../../components/setting/EditTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrimaryDarkLightBtn from '../../../components/form/PrimaryDarkLightBtn';

const TemplateSetting = () => {
  const settingPath: string = useSelector((state: any) => state.ui.settingPath);
  const [list, setList] = useState<any[]>([]);
  const [searchList, setSearchList] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [apiCall, setApiCall] = useState<boolean>(false);
  const [detail, setDetail] = useState<any>(null);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params?.mode === "edit") {
      setIsEdit(true)
    }
    if (params?.mode === "create") {
      setIsOpen(true)
    }
  }, [params?.mode])

  useEffect(() => {
    dispatch(settingActions.setTitle("Template Management"))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {isOpen && <CreateNewTemplate setIsOpen={setIsOpen} setApiCall={setApiCall} />}
      {isEdit && <EditTemplate setIsEdit={setIsEdit} setApiCall={setApiCall} detail={detail} />}
      {(!isOpen && !isEdit) &&
        <>
          <div className="border-b border-[#dee6e980] flex items-center flex-wrap sm:flex-nowrap justify-between w-full gap-[8px] p-[9px] md:px-[24px] min-h-[60px]">
            <div className="flex items-center gap-[4px] xs:p-0 whitespace-nowrap">
              <div className="cursor-pointer p-[4px] hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full block md:hidden" onClick={() => dispatch(settingActions.setPrev(true))}>
                <TaskArrow />
              </div>
              <h3 className="text-primaryLight text-[1.063rem] xl:text-lg font-medium">Template Management</h3>
            </div>
            {searchList?.length !== 0 && <div className="w-full flex items-center justify-end">
              <PrimaryDarkLightBtn title="Create New Template" onClick={() => { setIsOpen(true); navigate(`${settingPath}/setting/template-management/create`) }} />
            </div>}
          </div>
          <div className="lg:grid lg:grid-cols-8 w-full h-[calc(100%-60px)] xxl:flex items-start">
            <TemplateSettingList apiCall={apiCall} setApiCall={setApiCall} detail={detail} setDetail={setDetail} setIsEdit={setIsEdit} list={list} setList={setList} setIsOpen={setIsOpen} setSearchList={setSearchList} searchList={searchList} />
          </div>
        </>
      }
    </>
  );
};

export default TemplateSetting;