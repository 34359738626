import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DigitalSign from "../../pages/labSample/digitalSign/DigitalSign";

const Dashboard = React.lazy(() => import("../../pages/customer/dashboard/Dashboard"));
const BookTest = React.lazy(() => import("../../pages/customer/bookTest/BookTest"));
// const AddSample = React.lazy(() => import("../../pages/customer/addSample/AddSample"));
const NewLabSampleDetail = React.lazy(() => import("../../pages/labSample/labSampleDetail/NewLabSampleDetail"));
const ServiceBilling = React.lazy(() => import("../../pages/customer/serviceBilling/ServiceBilling"));
const ServiceBillingInfo = React.lazy(() => import("../../pages/customer/serviceBillingInfo/ServiceBillingInfo"));
// const TestRequestDetail = React.lazy(() => import("../../pages/customer/testRequestDetail/TestRequestDetail"));
const TestRequest = React.lazy(() => import("../../pages/customer/testRequest/TestRequest"));
const PaymentSuccess = React.lazy(() => import("../../pages/customer/paymentSuccess/PaymentSuccess"));
const PaymentFail = React.lazy(() => import("../../pages/customer/paymentFail/PaymentFail"));
const PaymentProgress = React.lazy(() => import("../../pages/customer/paymentProgress/PaymentProgress"));
const DownloadInvoice = React.lazy(() => import("../../pages/customer/downloadInvoice/DownloadInvoice"));
const Payment = React.lazy(() => import("../../pages/customer/payment/Payment"));

const CustomerRoutes: React.FC<any> = () => {
    return (
        <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/:tab" element={<Dashboard />} />
            <Route path="/service" element={<BookTest role="customer" />} />
            <Route path="/book-test/:id" element={<BookTest role="customer" />} />
            {/* <Route path="/add-sample/:id" element={<AddSample />} /> */}
            <Route path="/add-sample/:id" element={<NewLabSampleDetail role="customer" theme="customer" mode="edit" />} />
            <Route path="/service-billing/:id" element={<ServiceBilling />} />
            <Route path="/service-billing-info/:id" element={<ServiceBillingInfo />} />
            {/* <Route path="/test-request/:id" element={<TestRequestDetail />} /> */}
            <Route path="/test-request/:id" element={<NewLabSampleDetail role="customer" theme="customer" mode="view" />} />
            <Route path="/report/:id/:dept" element={<DigitalSign role="customer" mode="view" />} />
            <Route path="/tests" element={<TestRequest />} />
            <Route path="/tests/:tab" element={<TestRequest />} />
            <Route path="/download-invoices" element={<DownloadInvoice />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/payment/:tab" element={<Payment />} />
            <Route path="/payment-success/:id" element={<PaymentSuccess />} />
            <Route path="/payment-fail/:id" element={<PaymentFail />} />
            <Route path="/payment-process/:id" element={<PaymentProgress />} />
            <Route path="*" element={<Navigate to="/customer/dashboard" />} />
        </Routes>
    );
};

export default CustomerRoutes;