import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Inventory = React.lazy(() => import("../../pages/inventory/dashboard/Inventory"));
const InventoryDetail = React.lazy(() => import("../../pages/inventory/inventoryDetail/InventoryDetail"));
const AddProduct = React.lazy(() => import("../../pages/inventory/dashboard/AddProduct"));
const IndentRequest = React.lazy(() => import("../../pages/inventory/indentRequest/IndentRequest"));
const IndentRequestDetail = React.lazy(() => import("../../pages/inventory/indentRequestDetail/IndentRequestDetail"));
const CreateIndentRequest = React.lazy(() => import("../../pages/inventory/indentRequest/CreateIndentRequest"));
const ApproveIndentRequest = React.lazy(() => import("../../pages/inventory/indentRequest/ApproveIndentRequest"));

const InventoryRoutes: React.FC<any> = () => {
    return (
        <Routes>
            <Route path="/" element={<Inventory />} />
            <Route path="/:tab" element={<Inventory />} />
            <Route path="/:tab/:id" element={<InventoryDetail />} />
            <Route path="/:tab/:id/:mode" element={<AddProduct />} />
            <Route path="/in-out/details/:tab/:id" element={<InventoryDetail />} />
            <Route path="/add-product" element={<AddProduct />} />
            <Route path="/indent-request" element={<IndentRequest />} />
            <Route path="/indent-request/:tab" element={<IndentRequest />} />
            <Route path="/indent-request/:tab/:id" element={<IndentRequestDetail />} />
            <Route path="/indent-request/create" element={<CreateIndentRequest type="Item Requisition" />} />
            <Route path="/indent-request/stock-transfer/create" element={<CreateIndentRequest type="Stock Transfer" />} />
            <Route path="/indent-request/stock-return/create" element={<CreateIndentRequest type="Return" />} />
            <Route path="/indent-request/:tab/:id/approve" element={<ApproveIndentRequest type="approve" />} />
            <Route path="/indent-request/:tab/:id/receive" element={<ApproveIndentRequest type="receive" />} />
            <Route path="*" element={<Navigate to="/inventory" />} />
        </Routes>
    );
};

export default InventoryRoutes;