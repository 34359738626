/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AddNew, ClosePopup, DownloadIcon, FileUploadIcon, GroupClose, LoadingBtn, NameOrgIcon, RemoveMore, SearchCustomerArrow, SearchCustomerIcon } from "../../../svg/AllSvg";
import { invoiceActions } from "../../../../shared/store/invoice-Slice";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate } from "react-router-dom";
import useAxios from "../../../../shared/hooks/use-axios";
import { randomColor } from "../../../../shared/utils/randomColor";
import Select, { components } from "react-select";
import { success } from "../../../../shared/hooks/use-toastify";
import PrimaryInput from "../../../form/PrimaryInput";
import PrimaryTextarea from "../../../form/PrimaryTextarea";
import SecondaryBtn from "../../../form/SecondaryBtn";
import { useSelect } from "../../../../shared/hooks/use-select";
import SingleSelect from "../../../form/SingleSelect";

let refund_list: any[] = [
    { label: "Credit", value: "credit" },
    { label: "Refund", value: "refund" },
]

const CreateCreditNote = () => {
    const [submit, setSubmit] = useState<boolean>(false);
    const creditNote: boolean = useSelector((state: any) => state.invoice.creditNote);
    const [list, setList] = useState<any[]>([]);
    const [reasonList, setReasonList] = useState<any[]>([]);
    const [refundType, setRefundType] = useState<any>(null);
    const [reason, setReason] = useState<any>(null);
    const [search, setSearch] = useState<string>("");
    let styles = useSelect(false, false, "39px", "text", "#6B7280");
    const [cust, setCust] = useState<any>(null);
    const [amount, setAmount] = useState<any>("")
    const [note, setNote] = useState<any>("")
    const [files, setFiles] = useState<any>([]);
    const [file, setFile] = useState<any>([]);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [disable, setDisable] = useState<boolean>(false);
    const [bank, setBank] = useState<boolean>(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const SelectMenuButton: any = (props: any) => {
        return (
            <components.MenuList  {...props}>
                {props.children}
                <button className="w-full flex items-center gap-[4px] justify-center py-[8px] bg-[#F2F2F2] rounded-b-[8px]" onClick={() => { dispatch(invoiceActions.setIsCustomerCreate(true)); }}>
                    <AddNew />
                    <h1 className="text-primaryLight text-xs font-normal">Add New Customer</h1>
                </button>
            </components.MenuList >
        )
    }

    const Option: any = (props: any) => {
        return (
            <components.Option {...props}>{props.data.option}</components.Option>
        );
    };

    const getReasonList = async () => {
        try {
            const res: any = await useAxios.get("/payment/reason-list/");

            if (res && res?.status === 200) {
                setReasonList(res?.data?.results?.map((item: any) => ({
                    label: item?.text,
                    value: item?.id
                })))
            }
        } catch (error) {
            console.error("error while feching reason list", error);
        }
    }

    const getCustomer = async () => {
        try {
            const res: any = await useAxios.get("/customers/api/customers-accounting-list/");

            if (res && res?.status === 200) {
                setList(res?.data?.results?.map((item: any) => ({
                    ...item,
                    label: item?.first_name + " " + item?.last_name,
                    value: item?.id,
                    option:
                        <div className="rounded-[10px] py-[12px] px-[19px] flex flex-col gap-[10px]">
                            <div className="flex items-center justify-between">
                                <div className='flex items-center gap-[12px]'>
                                    {!!item?.org_name ? <div style={{ backgroundColor: `${randomColor[(item?.org_name)?.charAt(0)?.toLowerCase()]}` }} className='w-[27px] h-[27px] rounded-full uppercase text-white font-semibold text-xs flex items-center justify-center'>{item?.org_name?.charAt(0)}</div> :
                                        <div style={{ backgroundColor: `${randomColor[(item?.first_name)?.charAt(0)?.toLowerCase()]}` }} className='w-[27px] h-[27px] rounded-full uppercase text-white font-semibold text-xs flex items-center justify-center'>{item?.first_name?.charAt(0)}{item?.last_name?.charAt(0)}</div>
                                    }
                                    <div className='flex flex-col'>
                                        <h1 className="text-mGray text-sm font-normal hover:text-primaryLight">{item?.first_name + " " + item?.last_name}</h1>
                                        {!!item?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#6B7280" /> <span className="text-dGray font-normal !text-xs text-left">{item?.org_name}</span></p>}
                                    </div>
                                </div>
                                <p className="text-dGray text-sm font-normal">{item?.shipping_details?.billing_gst_no}</p>
                            </div>
                            {(!!item?.address || !!item?.city || !!item?.state || !!item?.country || !!item?.zipcode) && <p className="text-mGray text-sm font-normal">{!!item?.address && item?.address + `${!!item?.city ? ', ' : ""}`} {!!item?.city && item?.city + `${!!item?.state ? ', ' : ""}`} {!!item?.state && item?.state + `${!!item?.country ? ', ' : ""}`} {!!item?.country && item?.country + `${!!item?.zipcode ? ', ' : ""}`} {!!item?.zipcode && '- ' + item?.zipcode}</p>}
                        </div>
                })))
            }
        } catch (error) {
            console.error("error while feching customer", error);
        }
    }

    useEffect(() => {
        getCustomer();
        getReasonList();
        // eslint-disable-next-line
    }, []);

    const simulateChunkUpload = (chunk: any) => {
        console.log(chunk, "1MB")
        return new Promise((resolve: any, reject: any) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            if (files?.length === 1 || acceptedFiles?.length > 1) {
                return;
            } else {
                setUploadProgress(0);
                setDisable(true)
                setFiles([...files, { file: acceptedFiles, url: URL.createObjectURL(acceptedFiles[0]) }]);
                setFile([...file, acceptedFiles[0]])

                let uploadFile = acceptedFiles[0];
                if (uploadFile) {
                    const chunkSize = 1024 * 1024; // 1MB chunk size
                    const totalChunks = Math.ceil(uploadFile.size / chunkSize);
                    let currentChunk = 0;

                    const uploadNextChunk = () => {
                        const start = currentChunk * chunkSize;
                        const end = Math.min(start + chunkSize, uploadFile.size);
                        const chunk = uploadFile.slice(start, end);

                        simulateChunkUpload(chunk)
                            .then(() => {
                                currentChunk++;

                                if (currentChunk < totalChunks) {
                                    const progress = Math.floor((currentChunk / totalChunks) * 100);
                                    setUploadProgress(progress);

                                    uploadNextChunk();
                                } else {
                                    setUploadProgress(100);
                                    setDisable(false)
                                }
                            })
                            .catch((error) => {
                                console.error('Error uploading chunk', error);
                            });
                    };

                    uploadNextChunk();
                }
            }
        }
    });

    const removeFile = (idx: number) => {
        let arr: any[] = [];
        let arr1: any[] = [];
        for (let i = 0; i < files.length; i++) {
            const el = files[i];

            if (idx !== i) {
                arr.push(el)
                arr1.push(el?.file[0])
            }
        }
        setFiles(arr);
        setFile(arr1)
    };

    const thumbs = files.map((file: any, idx: number) => (
        <div key={idx} className="border border-[#D0D0D0] rounded-[10px] h-[40px] flex flex-col items-center w-full relative bg-white shadow-[0px_8px_8px_-4px_rgba(24,39,75,0.08),0px_4px_6px_-4px_rgba(24,39,75,0.12)]">
            <div className='w-full flex items-center justify-between h-full px-[19px]'>
                <h3 className="text-dGray text-sm font-normal truncate">{file?.file[0]?.name}</h3>

                {(uploadProgress !== 100 && (idx + 1 === files?.length)) ?
                    <div className="flex items-center gap-[10px]">
                        <h1 className="text-primaryLight text-sm font-normal">Uploading {uploadProgress}%</h1>
                        <div className="cursor-pointer" onClick={() => removeFile(idx)}>
                            <GroupClose />
                        </div>
                    </div> :
                    <div className="flex items-center gap-[10px]">
                        <Link to={file?.url} download={file?.url} target="_blank" className="cursor-pointer">
                            <DownloadIcon />
                        </Link>
                        <div className="cursor-pointer" onClick={() => removeFile(idx)}>
                            <RemoveMore />
                        </div>
                    </div>
                }
            </div>
        </div>
    ));

    const handleSubmit = async () => {
        setSubmit(true)
        try {
            let formdata = new FormData();
            formdata.append("customer", cust?.id);
            formdata.append("refund_type", refundType?.value);
            formdata.append("credit_type", bank ? "credit_in_bank" : "credit_in_wallet");
            formdata.append("amount", amount);
            file?.length > 0 && formdata.append("file", file[0]);
            formdata.append("remarks", note);
            formdata.append("reason", reason?.value);
            formdata.append("note_type", "credit_note");

            const res: any = await useAxios.post("/payment/api/credit-note/", formdata);

            if (res && res.status === 201) {
                success("Create Credit Note Successfully.")
                dispatch(invoiceActions.setCreditNote(false))
                dispatch(invoiceActions.setTagApiCall(true))
                dispatch(invoiceActions.setApiCall("credit-note"))
                navigate("/invoice/credit-note")
                setSubmit(false)
            }
        } catch (error: any) {
            setSubmit(false)
            console.error("error while create credit note", error);
        }
    }

    return (
        <>
            {creditNote && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px] md:mx-auto bg-white rounded-[20px] flex flex-col w-[320px] sm:w-[600px] md:w-[645px] z-[10]">
                        <div className="relative">
                            <div className="cursor-pointer absolute top-[22px] right-[19px] z-[10]" onClick={() => dispatch(invoiceActions.setCreditNote(false))}>
                                <ClosePopup />
                            </div>
                        </div>
                        <div className="flex flex-col px-[28px] py-[23px] rounded-[20px] bg-white">
                            <div className="flex items-center gap-[15px]">
                                <div className="flex flex-col items-start">
                                    <h3 className="text-primaryLight font-semibold text-lg">Create Credit Note</h3>
                                    <p className="text-mGray font-normal text-sm">Create a  credit note for a customer.</p>
                                </div>
                            </div>

                            <div className='border border-[#CBCBCB] bg-white h-[40px] rounded-[6px] flex items-center justify-between mt-[25px]'>
                                <Select options={list} components={{ MenuList: SelectMenuButton, Option }} placeholder="Select or add a customer" name="section-name" value={cust} styles={styles} onChange={(e: any) => setCust(e)} menuPlacement="auto" minMenuHeight={300} menuIsOpen={!!search && true} onMenuClose={() => !!!search && false} onMenuOpen={() => !!search && true} onInputChange={(e: any) => setSearch(e)} className='!w-full' />
                                <div className="flex items-center gap-[4px]">
                                    <SearchCustomerArrow />
                                    <div className="mt-[9px] mr-[-2px]">
                                        <SearchCustomerIcon className="fill-[#22B378]" />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-[20px]">
                                <h1 className="text-primaryLight text-sm font-medium">Send Money to</h1>

                                <div className="flex items-center gap-[8px] mt-[10px]">
                                    <button className={`${bank ? "bg-[#F6ECFF] text-primaryLight" : "bg-[#EBEBEB] text-mGray"}  rounded-[20px] px-[13px] text-sm font-normal h-[25px] flex items-center justify-center cursor-pointer`} onClick={() => setBank(true)}>Bank Account</button>
                                    <button className={`${!bank ? "bg-[#F6ECFF] text-primaryLight" : "bg-[#EBEBEB] text-mGray"} rounded-[20px] px-[13px] text-sm font-normal h-[25px] flex items-center justify-center cursor-pointer`} onClick={() => setBank(false)}>Wallet</button>
                                </div>
                            </div>

                            <div className="mt-[18px] flex flex-col gap-[16px]">
                                <h1 className="text-primaryLight text-sm font-medium">Credit Note Amount (₹)</h1>

                                <PrimaryInput type="number" min={1} title="Amounts" name="amounts" required={true} value={amount} onChange={(e: any) => setAmount(e.target.value)} exceptThisSymbols={["e", "E", "+", "-"]} />
                            </div>

                            <div className="flex flex-col gap-[10px] mt-[25px]">
                                <SingleSelect
                                    options={refund_list}
                                    onChange={(e: any) => setRefundType(e)}
                                    value={refundType}
                                    name="refund_list"
                                    placeholder="Refund Type"
                                    menuPlacement="auto"
                                    minMenuHeight={300}
                                    title="Refund Type*"
                                />
                            </div>

                            <div className="flex flex-col gap-[10px] mt-[25px]">
                                <SingleSelect
                                    options={reasonList}
                                    title="Add Reason & Note*"
                                    onChange={(e: any) => setReason(e)}
                                    value={reason}
                                    name="reason"
                                    placeholder="Reason"
                                    menuPlacement="auto"
                                    minMenuHeight={300}
                                />
                            </div>

                            <div className="mt-[25px]">
                                <PrimaryTextarea title="Add Note" name="note" rows={3} value={note} onChange={(e: any) => setNote(e.target.value)} />
                            </div>

                            <div {...getRootProps()} onClick={(e) => e.stopPropagation} className="rounded-[4px] border-2 border-dashed border-[rgba(8,133,134,0.30)] bg-[rgba(208,252,253,0.05)] min-h-[107px] mt-[29px] flex flex-col items-center justify-center">
                                {(uploadProgress === 0 || uploadProgress === 100) && <>
                                    <input {...getInputProps()} />
                                    <div className="cursor-pointer" onClick={open}>
                                        <FileUploadIcon />
                                    </div>
                                </>}
                                <div className="text-dGray text-sm font-medium pt-[5px]">Drag & drop files or <span className="text-primaryLight text-sm font-medium">Browse</span></div>
                                <p className="text-lGray text-xs font-normal pt-[2px] text-center">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</p>
                            </div>

                            <aside className='w-full mt-[15px] flex flex-col gap-[12px]'>{thumbs}</aside>

                            <div className="w-full flex items-end justify-end mt-[26px]">
                                <SecondaryBtn onClick={handleSubmit} disabled={(!!amount && !disable && !!refundType && !!reason && !submit && +amount > 0) ? false : true} title={!submit ? "Update Payment" :
                                    <>
                                        <LoadingBtn className="w-4 h-4 text-white animate-spin" />
                                        Update Payment...
                                    </>} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default CreateCreditNote;