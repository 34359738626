/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from 'react-scroll';

const Sections = ({ menu }: any) => {
    const isOpen: boolean = useSelector((state: any) => state.ui.isOpen);
    const scrollY: boolean = useSelector((state: any) => state.ui.scrollY);
    const [activeMenu, setActiveMenu] = useState<string>(menu[0]?.eId);
    let [select, setSelect] = useState<any>(false)
    const { t } = useTranslation('common');

    useEffect(() => {
        if (!scrollY) {
            select = false;
            setSelect(select)
        }
    }, [scrollY])

    return (
        <div className={`${isOpen ? "!w-[60px] 2xl:!min-w-[227px]" : "!w-[60px] xlx:!min-w-[227px]"} flex flex-col gap-[20px] sticky ${scrollY ? "top-[90px]" : "top-[270px]"} h-fit transition-all`}>
            <div className="w-full bg-white shadow-[0px_4px_4px_rgba(24,39,75,0.08)] rounded-[10px]">
                <div className={`px-[17px] py-[12px] w-full border-b border-[rgba(0,134,176,0.06)] ${isOpen ? "hidden 2xl:block" : "hidden xlx:block"}`}>
                    <h2 className="text-mGray font-semibold text-xs">{t("common_sections.title")}</h2>
                    <p className="text-lGray text-xs font-normal">{t("common_sections.desc")}</p>
                </div>

                {Array.isArray(menu) && menu.map((item: any, index: number) => (
                    <Link to={item?.eId} spy={true} smooth={true} duration={300} offset={(!select && !scrollY) ? -374 : -180} onSetActive={() => setActiveMenu(item?.eId)} className={`px-[17px] h-full flex items-center w-full ${activeMenu === item?.eId && "bg-[#51d5ff1a]"} ${menu?.length === (index + 1) && "rounded-b-[10px]"} cursor-pointer border-b border-[rgba(0,134,176,0.06)] ${isOpen ? `justify-center 2xl:justify-between ${index === 0 && "rounded-t-[10px] 2xl:rounded-t-none"}` : `justify-center xlx:justify-between ${index === 0 && "rounded-t-[10px] xlx:rounded-t-none"}`}`} key={index} onClick={() => { if (!scrollY) { select = true; setSelect(select); } }}>
                        <div className={`flex items-center gap-[10px] h-full py-[15px] w-full ${isOpen ? "justify-center 2xl:justify-start" : "justify-center xlx:justify-start"}`}>
                            <div className="min-w-[20px] flex items-center justify-center h-fit">
                                <item.icon className={`${activeMenu === item?.eId ? "fill-lBlue" : "fill-mGray"}`} />
                            </div>
                            <h2 className={`${activeMenu === item?.eId ? "text-lBlue" : "text-mGray"} text-sm font-normal  ${isOpen ? "hidden 2xl:block" : "hidden xlx:block"}`}>{item?.name}</h2>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Sections;