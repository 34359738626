import { GrpDownArrow, RegisterArrow, SortArrow, SortDownArrow, SortUpArrow } from "../svg/AllSvg";
import React, { useState } from "react";
import { Paginator } from "primereact/paginator";
import { Checkbox } from "primereact/checkbox";
import { useTranslation } from "react-i18next";
import Moment from 'moment';
import PrimaryDarkLightBtn from "../form/PrimaryDarkLightBtn";
import GroupViewLoader from "../../skeleton/components/common/GroupViewLoader";

const GroupView: React.FC<any> = ({ sortBy, frontField, sortByList, groupByData, groupLoading, groupByExpand, defaultField, fieldType, total, size, groupByOptionValue, first, setGroupByExpand, setChildTotal, setSortBy, setPage, setFirst, setSize, setGroupByData, setViewAll, getActions, notFound, extraRow, handleNavigate, ignore, highLight, checkBox, height, sampleDetailPopup, active, center }) => {
  const [oneCheck, setOneCheck] = useState<string>("");
  const [allCheckBox, setAllCheckBox] = useState<boolean>(false);
  const { t } = useTranslation('common');

  const handleChange = (e: any, name: any) => {
    let itemName = e.target.name;
    let checked = e.target.checked;

    let arr: any[] = [];
    for (let i = 0; i < groupByData?.length; i++) {
      const el = groupByData[i];

      if (el?.id === name) {
        if (itemName === "checkAll") {
          el.allFlag = checked;
          let result = el?.data.map((item: any) => ({ ...item, flag: checked }));
          el.data = result;
          arr.push(el)
        } else {
          let result = el?.data.map((item: any) =>
            item?.id === itemName ? { ...item, flag: checked } : item
          );
          let check = result.every((item: any) => item.flag);
          el.allFlag = check;
          el.data = result;
          arr.push(el)
        }
      }
    }

    setGroupByData(arr);
  };

  const handleSelect = (item: any) => {
    let arr: any[] = [];
    for (let i = 0; i < groupByData?.length; i++) {
      const el = groupByData[i];

      if (item?.id === el?.id) {
        el.flag = !item?.flag;
        groupByExpand === el?.id ? setGroupByExpand("") : setGroupByExpand(el?.id)
        setChildTotal(item?.count);
        arr.push(el);
      } else {
        let obj: any = { ...groupByExpand };
        if (obj?.hasOwnProperty(el?.value)) {
          obj[el?.value] = false
          setGroupByExpand(obj)
        }
        el.flag = false;
        arr.push(el);
      }
    }
    setGroupByData(arr);
  };

  const getSortIcon = (asc: any, desc: any) => {
    if (sortByList.includes(asc)) {
      if (sortBy === "-created_at") {
        return <SortArrow />
      } else if (asc === sortBy) {
        return <SortDownArrow />
      } else if (desc === sortBy) {
        return <SortUpArrow />
      } else {
        return <SortArrow />
      }
    }
  }

  const handleSort = (asc: any, desc: any) => {
    if (sortByList.includes(asc)) {
      if (sortBy === "-created_at") {
        setSortBy(asc)
      } else if (asc === sortBy) {
        setSortBy(desc)
      } else {
        setSortBy(asc)
      }
    }
  }

  const getHeader = () => {
    let arr: any = [];
    defaultField.forEach((el: any) => {
      for (const key in frontField) {
        if (key === el) {
          arr.push(<th key={key} className="px-[8px] 2xl:px-[16px] cursor-pointer bg-[#F9F9F9] shadow-[0px_1px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]" onClick={() => handleSort(key, `-${key}`)}>
            <div className={`flex items-center w-full gap-[8px] ${center?.includes(key) ? "justify-center" : "justify-start"}`}>
              <p className='text-mGray font-normal text-xs'>{frontField[key]}</p>
              {sortByList.includes(key) && <div className="w-[13px] h-[13px]">
                {getSortIcon(key, `-${key}`)}
              </div>}
            </div>
          </th>);
        }
      }
    });

    return arr;
  };

  const onPageChange = (event: any) => {
    setPage(event?.page + 1);
    setFirst(event.first);
    setSize(event.rows);
  };

  return (
    <>
      {groupLoading ? <GroupViewLoader height={!!height ? height : ""} /> :
        <>
          {(!groupLoading && groupByData?.length > 0) &&
            <div className={`flex flex-col justify-between ${!!height ? height : "min-h-[calc(100vh-265px)]"} rounded-[5px] border border-[#E4E4E4] p-[10px]`}>
              <div className="flex flex-col w-full">
                {Array.isArray(groupByData) && groupByData.map((item: any, index: number) => (
                  <div key={index}>
                    <div className={`rounded-[8px] flex items-center justify-between h-[50px] w-full mb-[14px] cursor-pointer  px-[20px] ${item?.flag ? "bg-[#BEF1DF]" : "bg-[#F8F8F8]"}`} onClick={() => handleSelect(item)}>
                      <div className="flex items-center gap-[10px]">
                        <GrpDownArrow className={`${item?.flag ? "fill-primaryLight" : "fill-lGray rotate-180"}`} />

                        <div className="flex items-center gap-[16px]">
                          <h3 className={`font-medium text-base leading-[20px] ${item?.flag ? "text-primaryLight" : "text-mGray"} break-all`}>{fieldType[groupByOptionValue] === "date" ? Moment(item?.value).format('DD MMM YYYY') : item?.value}</h3>

                          <div className={`font-semibold text-xs px-[5px] min-w-[30px] h-[20px] flex items-center justify-center bg-white rounded-[5px] ${item?.flag ? "text-primaryLight" : "text-mGray"}`}>{item?.count}</div>
                        </div>
                      </div>

                      {/* <button className="min-w-[27px] h-[27px] flex items-center justify-center rounded-full bg-white shadow-[0px_2px_4px_rgba(24,39,75,0.06),0px_0px_4px_rgba(24,39,75,0.12)]">
                        <AddMoreTag className={`w-[24px] h-[23px] ${item?.flag ? "stroke-primaryLight" : "stroke-mGray"}`} />
                      </button> */}
                    </div>

                    {item?.flag &&
                      <div className="w-full mb-[14px] flex flex-col rounded-[5px] border border-[#E4E4E4] px-[10px] pt-[5px]">
                        <div className={`${!!sampleDetailPopup ? "" : "overflow-x-scroll overflow-y-hidden"}`}>
                          <table className="table-auto w-full list-view">
                            <thead>
                              <tr className="h-[30px]">
                                <th className="w-[58px] min-w-[58px] h-full rounded-l-[5px] bg-[#F9F9F9] shadow-[0px_1px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset,1px_0px_0px_0px_#F1F1F1_inset]">
                                  {!!checkBox ?
                                    <div className='flex items-center justify-center' onMouseLeave={() => setAllCheckBox(false)} onMouseEnter={() => setAllCheckBox(true)}>
                                      <Checkbox className="tests table-check" name="checkAll" checked={item?.allFlag} onChange={(e: any) => handleChange(e, item?.id)}></Checkbox>
                                    </div>
                                    :
                                    <div className='flex items-center justify-center w-full'>
                                      <Checkbox className="tests table-check" name="checkAll" checked={item?.allFlag} onChange={(e: any) => handleChange(e, item?.id)}></Checkbox>
                                    </div>
                                  }
                                </th>
                                {getHeader()}
                                {!!getActions && <th className="px-[8px] 2xl:px-[16px] h-full bg-[#F9F9F9] rounded-r-[5px] shadow-[0px_1px_0px_0px_#F1F1F1_inset,-1px_0px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]"></th>}
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(item?.data) && item?.data.map((i: any, idx: number) => (
                                <tr className={`${i?.flag ? "bg-[#9d7aff1a]" : `${!!handleNavigate && "hover:bg-[#B5B5B51a]"} bg-[#F1F1F11a]`} ${!!handleNavigate && "cursor-pointer"} w-full h-[35px] min-h-[35px] shadow-[0px_-1px_0px_0px_#efefef_inset]`} key={idx}>
                                  <td className="w-[58px] min-w-[58px] py-[5px]">
                                    {!!checkBox ?
                                      <div className="flex items-center justify-center" onMouseEnter={() => setOneCheck(i?.id)} onMouseLeave={() => setOneCheck("")}>
                                        {(allCheckBox || item?.allFlag || oneCheck === i?.id || i?.flag) ?
                                          <div className="flex items-center justify-center min-w-[31px] min-h-[31px]">
                                            <Checkbox className="tests table-check" checked={i?.flag} onChange={(e: any) => handleChange(e, item?.id)} name={i?.id}></Checkbox>
                                          </div>
                                          :
                                          <>{checkBox()}</>
                                        }
                                      </div>
                                      :
                                      <div className="flex items-center justify-center">
                                        <Checkbox className="tests table-check" checked={i?.flag} onChange={(e: any) => handleChange(e, item?.id)} name={i?.id}></Checkbox>
                                      </div>
                                    }
                                  </td>
                                  {Array.isArray(defaultField) && defaultField.map((d: any, didx: number) => (
                                    <React.Fragment key={didx}>
                                      <td onClick={() => { setViewAll(false); handleNavigate(d, i); }} className={`px-[8px] 2xl:px-[16px] py-[5px] ${center?.includes(d) ? "text-center" : "text-left"}`}>
                                        {!!extraRow && extraRow(d, i, idx)}
                                        {
                                          ignore?.includes(d) ? "" :
                                            fieldType[d] === "date" ?
                                              <h3 className={`${(highLight?.includes(d) || (active?.includes(d) && item?.flag)) ? "text-primaryLight" : "text-mGray"} text-mGray font-normal text-sm whitespace-nowrap`}>{!!i[d] ? Moment(i[d]).format('DD MMM YYYY') : "-"}</h3>
                                              :
                                              <div>
                                                {!!i[d] ?
                                                  <h3 className={`${(highLight?.includes(d) || (active?.includes(d) && item?.flag)) ? "text-primaryLight" : "text-mGray"} font-normal text-sm`}>{i[d]}</h3>
                                                  :
                                                  <>
                                                    {i?.custom_fields_values?.length > 0 ? <>
                                                      {Array.isArray(i?.custom_fields_values) && i?.custom_fields_values.map((c: any, cidx: number) => (
                                                        <React.Fragment key={cidx}>
                                                          {c?.slug === d &&
                                                            <>
                                                              {fieldType[c?.slug] === "date" ?
                                                                <h3 className={`${(highLight?.includes(d) || (active?.includes(d) && item?.flag)) ? "text-primaryLight" : "text-mGray"} text-mGray font-normal text-sm whitespace-nowrap`}>{!!c?.value ? Moment(c?.value).format('DD MMM YYYY') : "-"}</h3>
                                                                :
                                                                <h3 className={`${(highLight?.includes(d) || (active?.includes(d) && item?.flag)) ? "text-primaryLight" : "text-mGray"} font-normal text-sm`}>{!!c?.value ? c?.value : "-"}</h3>
                                                              }
                                                            </>
                                                          }
                                                        </React.Fragment>
                                                      ))}
                                                    </> : <h3 className="text-mGray font-normal text-sm">-</h3>}
                                                  </>
                                                }
                                              </div>
                                        }
                                      </td>
                                    </React.Fragment>
                                  ))}
                                  {!!getActions && getActions(i)}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        {!!item?.next && <div className="flex items-center justify-end w-full p-[15px] !pr-[5px]">
                          <PrimaryDarkLightBtn onClick={() => setViewAll(true)} rightIcon={<RegisterArrow className="fill-primaryLight dark:fill-white" />} padding="px-[20px]" height="h-[30px]" title={t("customer.dashboard.title.view") as any} />
                        </div>}
                      </div>
                    }
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-end">
                <Paginator className="pagination" pageLinkSize={3} first={first} rows={size} totalRecords={total} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
              </div>
            </div>
          }
        </>}

      {(groupByData?.length === 0 && !groupLoading) && <div className={`flex flex-col items-center justify-center ${!!height ? height : "min-h-[calc(100vh-265px)]"} rounded-[5px] border border-[#E4E4E4] p-[10px]`}>
        {notFound()}
      </div>}
    </>
  );
};

export default GroupView;