/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { invoiceActions } from '../../../../shared/store/invoice-Slice'
import { useDispatch } from 'react-redux'
import { ClosePopup, HeaderSearch, NameOrgIcon, SearchRightFilter } from '../../../svg/AllSvg';
import { useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import useAxios from '../../../../shared/hooks/use-axios';
import CustomerList from '../../../../skeleton/components/invoice/invoice/CustomerList';

const SelectCustomer = () => {
    const selectCustOpen: boolean = useSelector((state: any) => state.invoice.selectCustOpen);
    const dispatch = useDispatch();
    const [search, setSearch] = useState<string>("")
    const [list, setList] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const getCustomer = async () => {
        try {
            setIsLoading(true)
            const res: any = await useAxios.get(`/payment/customer-list-record-payment/?name=${search}`);

            if (res && res?.status === 200) {
                setIsLoading(false)
                setList(res?.data?.results);
            }
        } catch (error) {
            console.error("error while feching customer", error);
        }
    }

    useEffect(() => {
        if (!!search) {
            getCustomer();
        } 
        if(search?.length === 0) {
            setList([]);
        }
    }, [search]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(invoiceActions.setSelectCustOpen(false))
        }
    };

    return (
        <>
            {selectCustOpen && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px] md:mx-auto bg-white rounded-[20px] flex flex-col w-[320px] sm:w-[600px] md:w-[762px] z-[10] min-h-[600px]">
                        <div className="relative">
                            <div className="cursor-pointer absolute top-[29px] right-[19px] z-[10]" onClick={() => dispatch(invoiceActions.setSelectCustOpen(false))}>
                                <ClosePopup />
                            </div>
                        </div>

                        <div className="flex flex-col px-[28px] py-[23px] rounded-[20px] bg-white h-full">
                            <div className="flex items-center gap-[15px]">
                                <div className="flex flex-col items-start">
                                    <h3 className="text-primaryLight font-semibold text-lg">Select Customer</h3>
                                    <p className="text-mGray font-normal text-sm">Select customer to record payment</p>
                                </div>
                            </div>

                            <div className='pt-[15px] flex items-center justify-between w-full gap-[10px]'>
                                <span className="p-input-icon-left w-full">
                                    <HeaderSearch className="fill-mGray" />
                                    <InputText className="!shadow-none placeholder:!text-lGray !w-full !py-[10px] !rounded-[10px] !border-[#CBD5E1] !text-xs placeholder:!text-sm focus:!shadow-none focus:!border-[#CBD5E1] !bg-[#F2F2F2] !shadow-[0px_1.74571px_4.36429px_0px_rgba(38,51,77,0.03)]" placeholder="Search" value={search} onChange={(e: any) => setSearch(e.target.value)} />
                                </span>

                                <button className="rounded-[10px] bg-white p-[6px] border border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] cursor-pointer">
                                    <SearchRightFilter color="#6B7280" />
                                </button>
                            </div>

                            {(list?.length === 0 && isLoading) ? <CustomerList /> :
                                <>
                                    {list?.length > 0 && <table className="table-auto overflow-x-scroll w-full table">
                                        <thead className="bg-white !rounded-[10px]">
                                            <tr className="h-[50px]">
                                                <th className="px-[8px] 2xl:px-[16px] !rounded-l-[10px]"></th>
                                                <th className="px-[8px] 2xl:px-[16px]">
                                                    <div className='flex items-center justify-start w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-xs !text-left'>Name</p>
                                                    </div>
                                                </th>
                                                <th className="px-[8px] 2xl:px-[16px]">
                                                    <div className='flex items-center justify-start w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-xs !text-left'>Company</p>
                                                    </div>
                                                </th>
                                                <th className="px-[8px] 2xl:px-[16px]">
                                                    <div className='flex items-center justify-start w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-xs !text-left'>GST No.</p>
                                                    </div>
                                                </th>
                                                <th className="px-[8px] 2xl:px-[16px]">
                                                    <div className='flex items-center justify-start w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-xs !text-left'>Invoice</p>
                                                    </div>
                                                </th>
                                                <th className="px-[8px] 2xl:px-[16px]">
                                                    <div className='flex items-center justify-center w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-xs !text-center'>Amount</p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(list) && list.map((item: any, index: number) => (
                                                <tr className={`!rounded-[10px] bg-white shadow-[0px_2px_10px_0px_rgba(229,229,229,0.40)] h-[50px] hover:!bg-[#FAFAFF] cursor-pointer ${item?.flag && "col-active bg-[#FAFAFF]"}`} key={index}>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full" onClick={() => { dispatch(invoiceActions.setCust(item)); dispatch(invoiceActions.setRecordPaymentOpen(true)); dispatch(invoiceActions.setSelectCustOpen(false)); }}>
                                                        <img src={require("../../../../assets/images/org_logo.png")} alt="" />
                                                    </td>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full" onClick={() => { dispatch(invoiceActions.setCust(item)); dispatch(invoiceActions.setRecordPaymentOpen(true)); dispatch(invoiceActions.setSelectCustOpen(false)); }}>
                                                        <h3 className="text-dGray font-normal !text-sm text-left">{item?.first_name + " " + item?.last_name}</h3>
                                                    </td>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full" onClick={() => { dispatch(invoiceActions.setCust(item)); dispatch(invoiceActions.setRecordPaymentOpen(true)); dispatch(invoiceActions.setSelectCustOpen(false)); }}>
                                                        {!!item?.org_name && <p className="flex items-stretch gap-[4px]"><NameOrgIcon color="#6B7280" /> <span className="text-dGray font-normal !text-xs text-left">{item?.org_name}</span></p>}
                                                    </td>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full" onClick={() => { dispatch(invoiceActions.setCust(item)); dispatch(invoiceActions.setRecordPaymentOpen(true)); dispatch(invoiceActions.setSelectCustOpen(false)); }}>
                                                        <h3 className="text-dGray font-normal !text-sm text-left">{!!item?.gst ? item?.gst : "-"}</h3>
                                                    </td>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full" onClick={() => { dispatch(invoiceActions.setCust(item)); dispatch(invoiceActions.setRecordPaymentOpen(true)); dispatch(invoiceActions.setSelectCustOpen(false)); }}>
                                                        <h3 className="text-dGray font-normal !text-sm text-left">{item?.invoice_count}</h3>
                                                    </td>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full" onClick={() => { dispatch(invoiceActions.setCust(item)); dispatch(invoiceActions.setRecordPaymentOpen(true)); dispatch(invoiceActions.setSelectCustOpen(false)); }}>
                                                        <h3 className="text-mGray font-normal !text-sm text-center">₹ {!!item?.total_amount ? item?.total_amount : 0}</h3>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>}
                                </>
                            }

                            {(list?.length === 0 && !isLoading) && <div className="flex flex-col items-center justify-center min-h-[400px]">
                                <img src={require("../../../../assets/images/step2.png")} alt="" />
                                <p className="text-sm font-medium text-primaryLight text-center max-w-[232px]">No Customer Found.</p>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default SelectCustomer