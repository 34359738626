import { useSelector } from "react-redux";
import { AllValidate, Editing, ParaValidated, StartValidate } from "../../svg/AllSvg";
import useAxios from "../../../shared/hooks/use-axios";
import { messages } from "../../../shared/utils/message";

const Signed = ({ signedData, mode, role }: any) => {
    const sampleDetail = useSelector((state: any) => state.labSample.sampleDetail);
    const component: string = useSelector((state: any) => state.labSample.component);

    const navigateSignReport = async (item: any) => {
        try {

            const res: any = await useAxios.post(`/reports/get-pdf/`, { file_key: item?.trFile, sample: sampleDetail?.id, reviewed_by: signedData?.[0]?.reviewed_by });

            if (res && res?.status === 200) {
                if (!!res?.data?.redirect_uri) {
                    window.open(res?.data?.redirect_uri, "_blank")
                }
            }

        } catch (error) {
            console.error("error while navigate sign report", error);
        }
    };

    return (
        <div className="bg-white shadow-[0px_4px_4px_rgba(24,39,75,0.08),0px_2px_4px_rgba(24,39,75,0.12)] rounded-[10px] w-full" id="signed">
            <div className="py-[11px] min-h-[55px] flex items-center justify-between px-[27px] gap-[9px]  border-b border-[rgba(147,147,147,0.29)] w-full">
                <div className="flex items-center gap-[9px]">
                    <ParaValidated className="fill-lBlue" />
                    <h1 className="text-lBlue text-base font-semibold">{role === "lab" ? `${component === messages?.sample_tab?.pending_sign ? "Pending for approver sign" : "Signed"}` : "Signed Report"}</h1>
                </div>
            </div>

            <div className="p-[17px] w-full flex flex-col gap-y-[15px]">
                <>
                    {signedData?.length > 0 ?
                        <>
                            {Array.isArray(signedData) && signedData?.map((item: any, index: number) => (
                                <div className="border border-[rgba(147,147,147,0.29)] w-full rounded-[10px]" key={index}>
                                    <div className="flex items-center justify-between w-full py-[6px] min-h-[45px] px-[20px] gap-[9px] border-b border-[rgba(147,147,147,0.29)]">
                                        <div className="flex items-center gap-[9px]">
                                            <div className="min-w-[28px] min-h-[28px] rounded-full flex items-center justify-center text-primaryLight text-base font-semibold bg-[#EEFBFF]">
                                                {index + 1}
                                            </div>
                                            <h4 className="text-primaryLight text-base font-semibold">{item?.report_no}_signed.pdf</h4>
                                        </div>

                                        {(mode === "edit" && component === messages?.sample_tab?.pending_sign) && <button className="rounded-[10px] bg-[rgba(204,212,255,0.10)] border border-[rgba(135,154,255,0.30)] hover:bg-[#F1F4FF] hover:border-[rgba(67,94,171,0.25)] hover:shadow-[0px_1px_2px_0px_rgba(20,14,62,0.25),0px_0px_0px_1px_#435EAB_inset,0px_1px_1px_1px_rgba(255,255,255,0.25)_inset,0px_-1px_1px_1px_rgba(67,94,171,0.25)_inset] min-w-[32px] min-h-[32px] items-center justify-center disabled:opacity-[0.5] disabled:cursor-not-allowed flex" onClick={() => navigateSignReport(item)}><Editing className="fill-primaryLight" /></button>}
                                    </div>

                                    <div className="p-[17px] w-full">
                                        <iframe id="test-report-iframe" title="test-report" src={item?.fileUrl} className="w-full h-[950px] overflow-hidden overflow-y-scroll border-0"></iframe>
                                    </div>
                                </div>
                            ))}
                        </>
                        :
                        <div className="px-[40px] py-[29px] flex flex-col items-center justify-center">
                            {component === messages?.sample_tab?.pending_sign ?
                                <>
                                    <AllValidate />
                                    <p className="text-lGray text-base font-normal">All Report signed</p>
                                </> :
                                <>
                                    <StartValidate />
                                    <p className="text-lGray text-base font-normal">{role === "lab" ? "Start Signing Report" : "No Signed Report found"}</p>
                                </>
                            }
                        </div>
                    }
                </>
            </div>
        </div>
    );
};

export default Signed;