const PrimaryInput: React.FC<any> = ({ type, name, title, onChange, value, onBlur, required, tabIndex, disabled, min, bg, defaultValue, autoFocus, exceptThisSymbols, text, id }: any) => {
    return (
        <div className={`relative w-full rounded-[6px] ${disabled && "opacity-[0.6] bg-[#fafafa]"}`}>
            <input
                type={type}
                id={name}
                className={`relative bg-transparent block py-[9px] ${text ? "pl-[12px]" : "px-[12px]"} w-full !text-sm !text-mGray font-normal appearance-none peer border border-[#CBCBCB] rounded-[6px] focus:shadow-[0_0_0_0.2rem_#EAF9FF] hover:border-[#8D9FFF] focus:border-[#8D9FFF] disabled:border-[#CBCBCB]`}
                placeholder=" "
                name={name}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                tabIndex={tabIndex}
                disabled={disabled}
                min={min && min}
                defaultValue={defaultValue}
                autoFocus={autoFocus}
                onFocus={(e: any) => e.target.addEventListener("wheel", function (e: any) { e.preventDefault() }, { passive: false })}
                onKeyDown={(e: any) => !!exceptThisSymbols && exceptThisSymbols.includes(e.key) && e.preventDefault()}
                style={{ paddingRight: text ? `${!!document.getElementById(id)?.offsetWidth ? `${(+((document.getElementById(id) as any)?.offsetWidth) + 20)}px` : "0"}` : "12px" }}
            />
            {required && <div className="absolute top-0 right-0 border-r-[4px] rounded-r-[6px] border-[#ff0000] h-full"></div>}
            {text && <div className="absolute right-0 top-0 px-[10px] flex items-center justify-center h-full">
                <h4 className="text-primaryLight text-sm font-normal" id={id}>{text}</h4>
            </div>}
            <label
                htmlFor={name}
                className={`${!!bg ? bg : "bg-white"} absolute whitespace-nowrap font-normal !text-xs text-mGray duration-300 transform -translate-y-4 scale-[0.85] top-[4px] origin-[0] px-[8px] peer-focus:px-[8px] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[4px] peer-focus:scale-[0.85] peer-focus:-translate-y-[16px] pointer-events-none left-[8px] !cursor-text`}
            >
                {title}
            </label>
        </div>
    );
};

export default PrimaryInput;