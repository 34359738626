import { useSelector } from "react-redux";
import { labSampleActions } from "../../../shared/store/labSample-Slice";
import { AlertNotiIcon, CustomerClose, LoadingBtn } from "../../svg/AllSvg";
import { useDispatch } from "react-redux";
import useAxios from "../../../shared/hooks/use-axios";
import { success } from "../../../shared/hooks/use-toastify";
import PrimaryTextarea from "../../form/PrimaryTextarea";
import SecondaryLightBtn from "../../form/SecondaryLightBtn";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const AddAlertRemark = () => {
    const alertRemark = useSelector((state: any) => state.labSample.alertRemark);
    const note = useSelector((state: any) => state.labSample.note);
    const selectReviewParameter = useSelector((state: any) => state.labSample.selectReviewParameter);
    const [submit, setSubmit] = useState<any>(false);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    const handleSave = async (flag: any) => {
        try {
            // {
            //     "auto_approve": false,
            //     "approved": false,
            //     "remarks": "",
            //     "parameter": null,
            //     "approved_by": null,
            //     "tag": []
            // }
            setSubmit(true)
            let userDetail: any = JSON.parse(localStorage.getItem('userdetail') as any);
            let payload = {
                "approved": flag?.toString(),
                "parameter": selectReviewParameter?.parameter,
                "approved_by": userDetail?.manpower_id,
                "remarks": note
            }

            const res: any = await useAxios.post(`/workflow/parameter-result-approval-api/`, payload);

            if (res && res.status === 200) {
                setSubmit(false)
                if (flag) {
                    success("Parameter validate successfully.");
                } else {
                    success("Parameter revert back successfully.");
                }
                dispatch(labSampleActions.setSampleDetailApi(true))
                // dispatch(labSampleActions.setSelectRow(null))
                // dispatch(labSampleActions.setSelectParameter(null));
                // dispatch(labSampleActions.setSelectReviewParameter(null));
                // dispatch(labSampleActions.setSelectedParameterReviewRow(null))
                dispatch(labSampleActions.setReviewApiCall(true))
                dispatch(labSampleActions.setAlertRemark(false))
                dispatch(labSampleActions.setMainAlert(false));
                dispatch(labSampleActions.setNote(""))
                // dispatch(labSampleActions.setTodoDetail(false));
            }
        } catch (error) {
            setSubmit(false)
            console.error("error while feching validate parameter", error);
        }
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(labSampleActions.setAlertRemark(false));
            dispatch(labSampleActions.setMainAlert(false));
        }
    };

    return (
        <>
            {alertRemark &&
                <div className="absolute inset-0 z-[8] overflow-y-hidden top-0 rounded-[10px]">
                    <div
                        className="absolute inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] h-auto sm:w-[395px] z-[2]">
                            <div className="flex items-start justify-between px-[20px] pt-[20px] rounded-t-[21px] bg-white z-[2]">
                                <h3 className="text-primaryLight font-normal text-base">{t("lab_sample.add_result.add_remarks")}</h3>
                                <div className="cursor-pointer z-[2]" onClick={() => { dispatch(labSampleActions.setAlertRemark(false)); dispatch(labSampleActions.setMainAlert(false)); }}>
                                    <CustomerClose />
                                </div>
                            </div>

                            <div className="flex flex-col gap-[12px] pt-[25px] pb-[16px] px-[20px] z-[2]">
                                <PrimaryTextarea title={t("common_form.field.remark")} name="remark" onChange={(e: any) => dispatch(labSampleActions.setNote(e.target.value))} value={note} />

                                <div className="bg-[#FFF2DD] rounded-[10px] min-h-[75px] flex items-center justify-center px-[10px]">
                                    <div className="flex items-start w-full gap-[5px]">
                                        <div className="w-[25px] h-[25px]">
                                            <AlertNotiIcon className="w-[25px] h-[25px]" />
                                        </div>
                                        <p className="text-dGray text-xs font-normal">{t("lab_sample.add_result.alert.conduct")} <span className="text-dGray text-xs font-semibold">{selectReviewParameter?.actual_quantity < 9 ? `0${selectReviewParameter?.actual_quantity}` : selectReviewParameter?.actual_quantity} {t("lab_sample.add_result.alert.time")}</span> {t("lab_sample.add_result.alert.more_than")} <span className="text-dGray text-xs font-semibold">{selectReviewParameter?.quantity < 9 ? `0${selectReviewParameter?.quantity}` : selectReviewParameter?.quantity} {t("lab_sample.add_result.alert.times")}</span> {t("lab_sample.add_result.alert.credit_note_desc")}</p>
                                    </div>
                                </div>

                                <div className="w-full flex items-end justify-end">
                                    <SecondaryLightBtn disabled={(!!note && !submit) ? false : true} onClick={() => handleSave(true)} title={!submit ? t("common_btn.submit") :
                                        <>
                                            <LoadingBtn className="w-5 h-5 text-primaryLight animate-spin" />
                                            {`${t("common_btn.save")}...`}
                                        </>} />
                                </div>
                            </div>

                            <img src={require("../../../assets/images/add-qty-remark.png")} alt="" className="absolute right-0 bottom-0 rounded-b-[10px] rounded-t-none" />
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default AddAlertRemark;
