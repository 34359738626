import { useSelector } from "react-redux";
import useAxios from "../../../shared/hooks/use-axios";
import { success } from "../../../shared/hooks/use-toastify";
import { DownloadInvoice, LoadingBtn, QRCodeIcon } from "../../svg/AllSvg";
import { testActions } from "../../../shared/store/test-Slice";
import { useDispatch } from "react-redux";
import PrimaryBtn from "../../form/PrimaryBtn";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Skeleton } from "primereact/skeleton";
import CustomFieldInfo from "../../form/CustomFieldInfo";

const SampleInfo = ({ setSampleApiCall, page, getDraft, loader }: any) => {
    const customField = useSelector((state: any) => state.setting.customField);
    const [submit, setSubmit] = useState<boolean>(false);
    const sampleDetail: any = useSelector((state: any) => state.test.sampleDetail);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    const handleAction = async () => {
        try {
            setSubmit(true)
            let payload = {
                "sample_id": sampleDetail?.id,
                "status": "approved"
            }

            const res: any = await useAxios.post(`/workflow/approve-test-request/`, payload);

            if (res && res?.status === 200) {
                setSubmit(false)
                success(t("customer.billing_info.test_req_detail.sample.validate_success"));
                getDraft();
                setSampleApiCall(true);
            }

        } catch (error) {
            setSubmit(false)
            console.error("error while validate sample", error);
        }
    };

    const handleDownload = async () => {
        try {
            setSubmit(true)
            let formdata = new FormData();
            formdata.append("sample_part", sampleDetail?.id);

            const res: any = await useAxios.post(`/reports/customer-report`, formdata);

            if (res && res?.status === 200) {
                dispatch(testActions.setReportData(res?.data))
                if (res?.data?.length > 0) {
                    dispatch(testActions.setDeptPopup(true));
                }
            }

        } catch (error) {
            setSubmit(false)
            console.error("error while download report detail", error);
        }
    };

    return (
        <div className="flex flex-col justify-between items-center h-[calc(100%-50px)]">
            <div className="flex flex-col w-full">
                <div className="w-full border-b border-[#EEE]">
                    <div className="py-[20px] px-[24px]">
                        <div className="flex items-center justify-between w-full">
                            <div className="flex flex-col">
                                <h3 className="text-primaryLight text-sm font-normal">
                                    {t("customer.billing_info.test_req_detail.sample.sample_detail")}
                                </h3>
                                <p className="text-mGray text-xs font-normal">{t("customer.billing_info.test_req_detail.sample.sample_detail_desc")}</p>
                            </div>

                            <div className="flex items-center gap-[8px]">
                                {!!page ? "" : <>
                                    {!!sampleDetail?.barcode_details && <button className="rounded-[5px] border border-[rgba(32,50,143,0.30)] bg-[rgba(183,194,255,0.10)] min-h-[30px] min-w-[30px] flex items-center justify-center" onClick={() => dispatch(testActions.setSingleQRCode(true))}>
                                        <QRCodeIcon />
                                    </button>}
                                </>}
                                {!!page ? "" : <>
                                    {sampleDetail?.component_permission_list?.length > 0 &&
                                        <>
                                            {Array.isArray(sampleDetail?.component_permission_list) && sampleDetail?.component_permission_list?.map((item: any, index: number) => (
                                                <React.Fragment key={index} >
                                                    {item?.components?.includes("Download Report") &&
                                                        < button className="rounded-[5px] border border-[rgba(32,50,143,0.30)] bg-[rgba(183,194,255,0.10)] min-h-[30px] min-w-[30px] flex items-center justify-center" onClick={handleDownload}>
                                                            <DownloadInvoice />
                                                        </button>
                                                    }
                                                </React.Fragment>
                                            ))}
                                        </>
                                    }
                                </>}
                            </div>
                        </div>

                        <div className="grid grid-cols-4 gap-[20px] pt-[20px]">
                            <div className="flex flex-col gap-[4px]">
                                {loader ?
                                    <>
                                        <Skeleton height="10px" className="!w-[60px] !rounded-full"></Skeleton>
                                        <Skeleton height="10px" className="!w-[81px] !rounded-full"></Skeleton>
                                    </> :
                                    <>
                                        <h3 className="text-lGray font-normal text-xs">{t("customer.billing_info.test_req_detail.sample.field.name")}</h3>
                                        <p className="text-dGray font-normal text-sm">{!!sampleDetail?.test_name ? sampleDetail?.test_name : "-"}</p>
                                    </>
                                }
                            </div>

                            {/* <div className="flex flex-col gap-[4px]">
                            <h3 className="text-lGray font-normal text-xs">Test Name</h3>
                            <p className="text-dGray font-normal text-sm">System-1 Test IP-29037</p>
                        </div> */}

                            <div className="flex flex-col gap-[4px]">
                                {loader ?
                                    <>
                                        <Skeleton height="10px" className="!w-[60px] !rounded-full"></Skeleton>
                                        <Skeleton height="10px" className="!w-[81px] !rounded-full"></Skeleton>
                                    </> :
                                    <>
                                        <h3 className="text-lGray font-normal text-xs">{t("customer.billing_info.test_req_detail.sample.field.qty")}</h3>
                                        <p className="text-dGray font-normal text-sm">{!!sampleDetail?.quantity ? sampleDetail?.quantity : "-"}</p>
                                    </>
                                }
                            </div>

                            <div className="flex flex-col gap-[4px]">
                                {loader ?
                                    <>
                                        <Skeleton height="10px" className="!w-[60px] !rounded-full"></Skeleton>
                                        <Skeleton height="10px" className="!w-[81px] !rounded-full"></Skeleton>
                                    </> :
                                    <>
                                        <h3 className="text-lGray font-normal text-xs">{t("customer.billing_info.test_req_detail.sample.field.unit")}</h3>
                                        <p className="text-dGray font-normal text-sm">{!!sampleDetail?.sample_unit_name ? sampleDetail?.sample_unit_name : "-"}</p>
                                    </>
                                }
                            </div>

                            <div className="flex flex-col gap-[4px]">
                                {loader ?
                                    <>
                                        <Skeleton height="10px" className="!w-[60px] !rounded-full"></Skeleton>
                                        <Skeleton height="10px" className="!w-[81px] !rounded-full"></Skeleton>
                                    </> :
                                    <>
                                        <h3 className="text-lGray font-normal text-xs">{t("customer.billing_info.test_req_detail.sample.field.status")}</h3>
                                        <p className="text-dGray font-normal text-sm">{!!sampleDetail?.sealed_status_name ? sampleDetail?.sealed_status_name : "-"}</p>
                                    </>
                                }
                            </div>

                            {/* <div className="flex flex-col gap-[4px]">
                                <h3 className="text-lGray font-normal text-xs">{t("customer.billing_info.test_req_detail.sample.field.expire_date")}</h3>
                                <p className="text-dGray font-normal text-sm">-</p>
                            </div> */}

                            <div className="flex flex-col gap-[4px]">
                                {loader ?
                                    <>
                                        <Skeleton height="10px" className="!w-[60px] !rounded-full"></Skeleton>
                                        <Skeleton height="10px" className="!w-[81px] !rounded-full"></Skeleton>
                                    </> :
                                    <>
                                        <h3 className="text-lGray font-normal text-xs">{t("customer.billing_info.test_req_detail.sample.field.type")}</h3>
                                        <p className="text-danger font-normal text-sm">{!!sampleDetail?.sample_type_name ? sampleDetail?.sample_type_name : "-"}</p>
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>

                <div className="py-[20px] px-[24px] w-full">
                    <div className="flex flex-col gap-[14px]">
                        <h3 className="text-primaryLight text-sm font-normal">
                            {t("customer.billing_info.test_req_detail.sample.sample_desc")}
                        </h3>
                        <div className="grid grid-cols-3 gap-[20px]">
                            <div className="flex flex-col gap-[4px]">
                                {loader ?
                                    <>
                                        <Skeleton height="10px" className="!w-[60px] !rounded-full"></Skeleton>
                                        <Skeleton height="10px" className="!w-[81px] !rounded-full"></Skeleton>
                                    </> :
                                    <>
                                        <h3 className="text-lGray font-normal text-xs">{t("customer.billing_info.test_req_detail.sample.field.model_desc")}</h3>
                                        <p className="text-dGray font-normal text-sm">{!!sampleDetail?.sample_description ? sampleDetail?.sample_description : "-"}</p>
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>

                {!loader && <div className={`${!customField && "hidden"} border-t border-[#EEE] py-[20px] px-[24px]`}>
                    <div className="flex flex-col gap-[14px]">
                        <CustomFieldInfo modal="samplepart" fillModal="Sample" id={sampleDetail?.id} grid={3} page="sample" />
                    </div>
                </div>}
            </div>

            {
                !!page ? "" : <>
                    {sampleDetail?.tag_list?.includes("PendingValidation") && <div className="flex items-end justify-end w-full pr-[20px] pb-[20px]">
                        <PrimaryBtn disabled={submit} onClick={handleAction} title={!submit ? t("customer.billing_info.test_req_detail.sample.field.btn") :
                            <>
                                <LoadingBtn className="w-5 h-5 text-white animate-spin" />
                                {`${t("customer.billing_info.test_req_detail.sample.field.btn")}...`}
                            </>} />
                    </div>}
                </>
            }
        </div >
    );
};

export default SampleInfo;