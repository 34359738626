import { useSelector } from "react-redux";

const Test = () => {
    const sampleDetail = useSelector((state: any) => state.labSample.sampleDetail);;
    const scrollY: boolean = useSelector((state: any) => state.ui.scrollY);
    return (
        <div className={`${scrollY && `sticky top-[75.3px] bg-[#F8FAFB]`} z-[2] transition-all py-[15px] mt-[-15px]`}>
            <div className="rounded-[10px] py-[15px] px-[26px] flex flex-col w-full border border-[#E0E3FF] bg-[#ECF6F9]">
                <h3 className="text-primaryLight font-bold text-xl">{`${sampleDetail?.standard_details?.is_number} (${sampleDetail?.standard_details?.year})`}</h3>
                <p className="text-dGray text-xs font-normal">{sampleDetail?.standard_details?.name}</p>
            </div>
        </div>
    );
};

export default Test;