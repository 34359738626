/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet } from "react-router-dom";
import Notification from "../../components/layout/Common/Notification";
import { useEffect, useState } from "react";
import { useSortcut } from "../../shared/hooks/use-sortcut";
import { useSelector } from "react-redux";
import SuccessTestReq from "../../components/customer/testRequestDetail/SuccessTestReq";
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";
import CreditLimitPopup from "../../components/customer/dashboard/CreditLimitPopup";
import Search from "../../components/layout/Common/Search";
import { employeeActions } from "../../shared/store/employee-Slice";
import MiniSidebar from "../../components/layout/Common/MiniSidebar";
import AddTestPopup from "../../components/customer/bookTest/AddTestPopup";
import Header from "../../components/layout/Common/Header";
import { testActions } from "../../shared/store/test-Slice";

const CustomerLayout: React.FC<any> = ({ path }) => {
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [notification, setNotification] = useState<boolean>(false);
    const isSuccess: boolean = useSelector((state: any) => state.test.isSuccess);
    const creditLimitOpen: boolean = useSelector((state: any) => state.test.creditLimitOpen);
    const addTestOpen: boolean = useSelector((state: any) => state.test.addTestOpen);
    const scrollY: boolean = useSelector((state: any) => state.ui.scrollY);
    const dispatch = useDispatch();
    const handleOpen = (): void => {
        setIsSearch(true);
    };

    useSortcut(["Control", "s"], handleOpen);

    useEffect(() => {
        dispatch(uiActions.setSettingPath(path))
        dispatch(employeeActions.Close())
        dispatch(uiActions.setModuleName("customer"))
    }, []);

    useEffect(() => {
        if (window.location.pathname === "/customer/service" || window.location.pathname === "/lims/test/service" || window.location.pathname === "/crm/test/service") {
            dispatch(testActions.setAddTestOpen(true))
        } else {
            dispatch(testActions.setAddTestOpen(false))
        }

        if (window.location.pathname === "/customer/service" || window.location.pathname === "/lims/test/service" || window.location.pathname === "/crm/test/service") {
            dispatch(testActions.setSelectStandard([]));
            dispatch(testActions.setSelectLab(""));
            dispatch(testActions.setLabList([]));
        }
        // eslint-disable-next-line
    }, [window.location.pathname]);

    useEffect(() => {
        if (isSuccess || creditLimitOpen || notification || isSearch || addTestOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isSuccess, creditLimitOpen, notification, isSearch, addTestOpen]);

    return (
        <div className="w-full h-full">
            {/* Backdrop */}
            <div>
                <span
                    id="menu-remove"
                    className={`fixed top-0 left-0 z-20 ${notification ? "block" : "hidden"
                        } h-screen w-full bg-[#00000080]`}
                    onClick={() => setNotification(!notification)}
                ></span>
            </div>

            {isSuccess && <SuccessTestReq />}
            {creditLimitOpen && <CreditLimitPopup />}
            {!scrollY && <Header setIsSearch={setIsSearch} setNotification={setNotification} />}
            {addTestOpen && <AddTestPopup />}

            <div className={`${scrollY ? "pt-[75px]" : "pt-[70px]"} w-full min-h-screen flex bg-[#F8FAFB]`}>
                <MiniSidebar />

                <div className="w-full pl-[50px] h-full">

                    {/* Notification module */}
                    <Notification />

                    <Search isSearch={isSearch} setIsSearch={setIsSearch} />

                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default CustomerLayout;