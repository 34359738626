import { useSelector } from 'react-redux';
import { CustomerClose, LoadingBtn } from '../../svg/AllSvg';
import { useDispatch } from 'react-redux';
// import { useSelectPrimary}  from "../../../shared/hooks/use-selectPrimary";
// import Select from "react-select";
import { crmActions } from '../../../shared/store/crm-Slice';
import { success } from '../../../shared/hooks/use-toastify';
import { useState } from 'react';
import useAxios from '../../../shared/hooks/use-axios';
import Moment from 'moment';
import PrimaryInput from '../../form/PrimaryInput';
import PrimaryTextarea from '../../form/PrimaryTextarea';
import PrimaryBtn from '../../form/PrimaryBtn';
import { useTranslation } from 'react-i18next';

const CreditLimitPopup = () => {
    const [submit, setSubmit] = useState<boolean>(false);
    const creditLimitOpen: boolean = useSelector((state: any) => state.crm.creditLimitOpen);
    const creditGroup: any = useSelector((state: any) => state.crm.creditGroup);
    const customer: any = useSelector((state: any) => state.crm.customer);
    const dispatch = useDispatch();
    // let styles = useSelectPrimary();
    const [remarks, setRemarks] = useState<string>("");
    const [graceDays, setGraceDays] = useState<any>("");
    const { t } = useTranslation('common');

    const handleSubmit = async () => {
        setSubmit(true)
        let payload = {
            "customer": customer?.id,
            "remarks": remarks,
            "grace_days": graceDays,
            "credit_group": creditGroup?.id
        }

        try {
            await useAxios.post(`/customers/api/customer-credit-policy/`, payload);
            success(t("crm.customer.success.credit_limit"));
            dispatch(crmActions.setCreditLimitOpen(false))
            setSubmit(false)

        } catch (error) {
            setSubmit(false)
            console.error("error while feching test request", error);
        }
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(crmActions.setCreditLimitOpen(false))
        }
    };

    return (
        <>
            {creditLimitOpen && <div className="fixed inset-0 z-20 overflow-y-hidden top-0 ">
                <div className="fixed inset-0 z-[31] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[21px] flex flex-col w-[320px] sm:w-[508px] md:h-auto">
                            <div className="flex items-start justify-between pl-[24px] pr-[26px] pt-[21px] rounded-t-[21px] bg-white">
                                <div className='flex flex-col'>
                                    <h3 className="text-primaryLight font-semibold text-lg">{t("crm.credit_limit.title")}</h3>
                                    <p className='text-mGray text-sm font-normal'>{t("crm.credit_limit.desc")}</p>
                                </div>
                                <div className="cursor-pointer" onClick={() => dispatch(crmActions.setCreditLimitOpen(false))}>
                                    <CustomerClose />
                                </div>
                            </div>


                            <div className="pl-[24px] pr-[26px] flex flex-col">
                                <div className='mt-[20px] flex flex-col w-full rounded-[10px] bg-[#F2F2F2] py-[8px] gap-[4px] px-[15px] justify-start'>
                                    <div className='flex items-center gap-[15px]'>
                                        <p className='text-mGray text-sm font-normal'>{t("crm.credit_limit.group_name")} </p>
                                        <div className={`bg-[#D7E8FF] text-primaryLight flex items-center justify-center text-sm font-normal rounded-[5px] py-[4px] px-[20px] whitespace-nowrap`}>
                                            {creditGroup?.name}
                                        </div>
                                    </div>
                                    <p className='text-dGray font-medium text-sm'>
                                        {t("crm.credit_limit.prev")} {Moment(creditGroup?.expiry_date).format('DD MMM YYYY')}
                                    </p>
                                </div>

                                <h4 className='text-sm font-medium text-primaryLight pt-[14px] pb-[20px]'>{t("crm.credit_limit.select_days")}</h4>

                                <PrimaryInput type="number" min={1} title={`${t("crm.credit_limit.grace_days")}*`} name="grace_days" value={graceDays} onChange={(e: any) => setGraceDays(e.target.value)} exceptThisSymbols={["e", "E", "+", "-", "."]} />

                                <div className='w-full pt-[24px]'>
                                    <PrimaryTextarea title={`${t("common_form.field.remark")}*`} name="remark" value={remarks} onChange={(e: any) => setRemarks(e.target.value)} />
                                </div>
                            </div>

                            <div className="w-full flex items-end justify-end pr-[22px] pt-[25px] pb-[21px] gap-[15px]">
                                <PrimaryBtn title={!submit ? t("common_btn.submit") :
                                    <>
                                        <LoadingBtn className="w-5 h-5 text-white animate-spin" />
                                        {`${t("common_btn.submit")}...`}
                                    </>} onClick={handleSubmit} disabled={(!!remarks && !!graceDays && !submit) ? false : true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default CreditLimitPopup
