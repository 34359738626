import { useTranslation } from "react-i18next";
import PrimaryBtn from "./PrimaryBtn";

const Confirmation = ({ openConfirm, setOpenConfirm, onSubmit, text }: any) => {
    const { t } = useTranslation('common');
    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            setOpenConfirm(false)
        }
    };

    return (
        <>
            {openConfirm && <div className="fixed inset-0 z-20 overflow-y-hidden top-0 ">
                <div className="fixed inset-0 z-[31] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[21px] flex flex-col w-[320px] sm:w-[480px] md:h-auto z-[10]">
                            <img src={require("../../assets/images/Mask group.png")} alt="" className="absolute bottom-0 right-0 rounded-b-[20px] !rounded-l-none z-[5] opacity-[0.4]" />

                            <div className="p-[28px] flex flex-col gap-[30px] z-[10]">
                                {!!text ? <h3 className="text-dGray text-lg font-normal">{t("confirmation.delete.title")} <span className="font-semibold">{text}</span> ?</h3> : <h3 className="text-dGray text-lg font-normal">{t("confirmation.delete.subtitle")}</h3>}
                            </div>

                            <div className="w-full flex items-end justify-end px-[24px] pb-[33px] gap-[15px] z-[10]">
                                <button type="button" className="border border-[rgba(32,50,143,0.30)] h-[40px] bg-[rgba(183,194,255,0.10)] px-[24px] text-primaryLight text-sm font-medium rounded-[10px]" onClick={() => setOpenConfirm(false)}>{t("common_btn.cancel")}</button>
                                <PrimaryBtn onClick={() => { onSubmit(); setOpenConfirm(false); }} title={t("common_btn.delete")} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default Confirmation;