import Filters from "../form/Filters";
import ListView from "./ListView";
import TableView from "./TableView";

const ViewLayout = ({ option, sortBy, filterResult, frontField, view, groupBy, groupByOption, groupByList, defaultField, detailField, viewAll, groupByOptionValue, setView, setGroupByOptionValue, setGroupByOption, setDefaultField, filterList, setChildPage, setGroupBy, setPage, setFilterResult, filterObj, setFilterObj, setChildFirst, setFirst, data, setChildSize, sortByList, setData, listLoading, setSortBy, fieldType, setSize, childSize, childTotal, childFirst, total, size, first, check, setCheck, setViewAll, module, notFound, getActions, handleNavigate, extraRow, ignore, highLight, checkBox, height, sampleDetailPopup, active, center }: any) => {
    return (
        <>
            <Filters option={option} sortBy={sortBy} filterResult={filterResult} frontField={frontField} view={view} groupBy={groupBy} groupByOption={groupByOption} groupByList={groupByList} defaultField={defaultField} detailField={detailField} viewAll={viewAll} groupByOptionValue={groupByOptionValue} setView={setView} setGroupByOptionValue={setGroupByOptionValue} setGroupByOption={setGroupByOption} setDefaultField={setDefaultField} setChildPage={setChildPage} setPage={setPage} setGroupBy={setGroupBy} filterObj={filterObj} setFilterObj={setFilterObj} filterList={filterList} setChildFirst={setChildFirst} setFirst={setFirst} setFilterResult={setFilterResult} module={module} height={height} />

            {(view === "list" && (!groupBy || groupByOption === "" || viewAll)) && <ListView type={viewAll ? "group" : "list"} data={data} listLoading={listLoading} setData={setData} sortByList={sortByList} sortBy={sortBy} setSortBy={setSortBy} setChildSize={setChildSize} setChildFirst={setChildFirst} setChildPage={setChildPage} setSize={setSize} setFirst={setFirst} setPage={setPage} defaultField={defaultField} frontField={frontField} fieldType={fieldType} total={total} size={size} first={first} childTotal={childTotal} childSize={childSize} childFirst={childFirst} setCheck={setCheck} check={check} setViewAll={setViewAll} notFound={notFound} getActions={getActions} handleNavigate={handleNavigate} extraRow={extraRow} ignore={ignore} highLight={highLight} checkBox={checkBox} height={height} sampleDetailPopup={sampleDetailPopup} active={active} center={center} />}

            {(view === "table" && (!groupBy || groupByOption === "" || viewAll)) && <TableView type={viewAll ? "group" : "list"} data={data} listLoading={listLoading} setData={setData} sortByList={sortByList} sortBy={sortBy} setSortBy={setSortBy} setChildSize={setChildSize} setChildFirst={setChildFirst} setChildPage={setChildPage} setSize={setSize} setFirst={setFirst} setPage={setPage} defaultField={defaultField} frontField={frontField} fieldType={fieldType} total={total} size={size} first={first} childTotal={childTotal} childSize={childSize} childFirst={childFirst} setCheck={setCheck} check={check} setViewAll={setViewAll} notFound={notFound} getActions={getActions} handleNavigate={handleNavigate} extraRow={extraRow} ignore={ignore} highLight={highLight} checkBox={checkBox} height={height} sampleDetailPopup={sampleDetailPopup} active={active} center={center} />}
        </>
    );
};

export default ViewLayout;