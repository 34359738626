/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

const Dashboard = React.lazy(() => import("../../pages/admin/dashboard/Dashboard"));
const BIReport = React.lazy(() => import("../../pages/admin/biReport/BIReport"));
const LabSample = React.lazy(() => import("../../pages/labSample/dashboard/LabSample"));
const LabSampleDetailLayout = React.lazy(() => import("../../pages/labSample/labSampleDetail/LabSampleDetailLayout"));
// const LabSampleTask = React.lazy(() => import("../../pages/labSample/labSampleTask/LabSampleTask"));
// const LabSampleProfile = React.lazy(() => import("../../pages/labSample/labSampleProfile/LabSampleProfile"));
// const LabSampleChecklist = React.lazy(() => import("../../pages/labSample/labSampleChecklist/LabSampleChecklist"));
// const LabSampleAssignChecklist = React.lazy(() => import("../../pages/labSample/labSampleAssignChecklist/LabSampleAssignChecklist"));
// const LabSampleAddResult = React.lazy(() => import("../../pages/labSample/labSampleAddResult/LabSampleAddResult"));
const Premium = React.lazy(() => import("../../pages/premium/Premium"));
const Remnant = React.lazy(() => import("../../pages/labSample/ramnant/Ramnant"));
// const FullViewLabSampleTask = React.lazy(() => import("../../pages/labSample/labSampleTask/FullViewLabSampleTask"));
// const FullViewLabSampleChecklist = React.lazy(() => import("../../pages/labSample/labSampleChecklist/FullViewLabSampleChecklist"));
// const FullViewLabSampleAssignChecklist = React.lazy(() => import("../../pages/labSample/labSampleAssignChecklist/FullViewLabSampleAssignChecklist"));
// const FullViewLabSampleAddResult = React.lazy(() => import("../../pages/labSample/labSampleAddResult/FullViewLabSampleAddResult"));
// const FullViewLabSampleAssign = React.lazy(() => import("../../pages/labSample/labSampleAssign/FullViewLabSampleAssign"));
// const LabSampleAssign = React.lazy(() => import("../../pages/labSample/labSampleAssign/LabSampleAssign"));

const LabSampleRoutes: React.FC<any> = ({ role }) => {
    // const fullView: boolean = useSelector((state: any) => state.ui.fullView);
    const permissionRole: any = useSelector((state: any) => state.auth.permissionRole);
    const [roleName, setRoleName] = useState<string>("");
    const [existRole, setExistRole] = useState<any>(null);

    useEffect(() => {
        if (permissionRole?.length > 0) {
            let getRole: any = role?.filter((item: any) => permissionRole?.toString()?.includes(item))
            setRoleName(getRole[0])
            getRole?.length > 0 ? setExistRole(true) : setExistRole(false)
        }
    }, [permissionRole])

    return (
        <>
            {existRole != null && <>
                {(existRole) ? <>
                    {(permissionRole?.includes(roleName)) &&
                        <>
                            {roleName === "Remnant" ?
                                <Routes>
                                    <Route path="/dashboard" element={<Dashboard />} />
                                    <Route path="/bi-report" element={<BIReport />} />
                                    <Route path="/" element={<Remnant />} />
                                    <Route path="/:tab" element={<Remnant />} />
                                    <Route path="*" element={<Navigate to="/lims/samples/dashboard" />} />
                                </Routes> :
                                <Routes>
                                    <Route path="/dashboard" element={<Dashboard />} />
                                    <Route path="/bi-report" element={<BIReport />} />
                                    <Route path="/" element={<LabSample />} />
                                    <Route path="/:tab" element={<LabSample />} />
                                    <Route path="/:tab/:id" element={<LabSampleDetailLayout />} />
                                    <Route path="/:tab/:id/:mode" element={<LabSampleDetailLayout />} />
                                    <Route path="/parameter-result/:tab/:id/:parameter" element={<LabSampleDetailLayout />} />
                                    <Route path="/pending-for-validation/:tab/:id" element={<LabSampleDetailLayout />} />
                                    <Route path="/pending-for-validation/:tab/:id/:parameter" element={<LabSampleDetailLayout />} />
                                    <Route path="/validated/:tab/:id" element={<LabSampleDetailLayout />} />
                                    <Route path="/validated/:tab/:id/:parameter" element={<LabSampleDetailLayout />} />

                                    <Route path="/parameter-result-review/:tab/:id/:parameter" element={<LabSampleDetailLayout />} />
                                    <Route path="/pending-result-review/:tab/:id" element={<LabSampleDetailLayout />} />
                                    <Route path="/pending-result-review/:tab/:id/:parameter" element={<LabSampleDetailLayout />} />
                                    <Route path="/result-reviewed/:tab/:id" element={<LabSampleDetailLayout />} />
                                    <Route path="/result-reviewed/:tab/:id/:parameter" element={<LabSampleDetailLayout />} />
                                    {/* <Route path="/task/:tab/:id/:todo" element={fullView ? <FullViewLabSampleTask /> : <LabSampleTask />} />
                                    <Route path="/profile/:tab/:id" element={<LabSampleProfile />} />
                                    <Route path="/checklist/:tab/:id/:todo" element={fullView ? <FullViewLabSampleChecklist /> : <LabSampleChecklist />} />
                                    <Route path="/assign-qty/:tab/:id/:todo" element={fullView ? <FullViewLabSampleAssign /> : <LabSampleAssign />} />
                                    <Route path="/assign-parameter/:tab/:id/:todo" element={fullView ? <FullViewLabSampleAssignChecklist /> : <LabSampleAssignChecklist />} />
                                    <Route path="/add-result/:tab/:id/:todo" element={fullView ? <FullViewLabSampleAddResult /> : <LabSampleAddResult />} /> */}
                                    <Route path="*" element={<Navigate to="/lims/samples/dashboard" />} />
                                </Routes>
                            }
                        </>
                    }
                </> :
                    <Routes>
                        <Route path="/premium" element={<Premium />} />
                        <Route path="*" element={<Navigate to="/lims/samples/premium" />} />
                    </Routes>
                }
            </>}
        </>
    );
};

export default LabSampleRoutes;