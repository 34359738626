import { useDispatch } from "react-redux";
import { AllClose, CopyLink, SampleTestClose, ScreenShot, TestingDateIcon } from "../../svg/AllSvg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { uiActions } from "../../../shared/store/ui-Slice";
import { theme } from "../../../shared/utils/theme";

const AllTabs: React.FC<any> = ({ isOpen, setIsOpen }: any) => {
    const screenShotList: any[] = useSelector((state: any) => state.ui.screenShotList);
    const option: string = useSelector((state: any) => state.ui.option);
    const [copy, setCopy] = useState<any>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    const handleClose = (index: any) => {
        let arr: any[] = screenShotList?.filter((item, i) => index !== i);
        dispatch(uiActions.assignScreenShotList(arr));
        localStorage.setItem('screenshot', JSON.stringify(arr) as any);
        arr?.length === 0 && setIsOpen(false);
    };

    const handleDelete = () => {
        dispatch(uiActions.assignScreenShotList([]));
        localStorage.removeItem('screenshot');
    }

    const handleSwitch = (item: any) => {
        setIsOpen(false);
        let userDetail: any = JSON.parse(localStorage.getItem('userdetail') as any)
        if (userDetail?.role !== item?.role) {
            let obj: any = { ...userDetail, role: item?.role }
            localStorage.setItem('userdetail', JSON.stringify(obj));
            if (item?.fullScreen) {
                localStorage.setItem('full-view', true as any);
                dispatch(uiActions.setFullView(true));
            }
            navigate(item?.url, { state: option });
        } else {
            if (item?.fullScreen) {
                localStorage.setItem('full-view', true as any);
                dispatch(uiActions.setFullView(true));
            }
            navigate(item?.url, { state: option });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setCopy(null);
        }, 1500)
    }, [copy]);

    return (
        <>
            {isOpen && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px] md:mx-auto bg-[#f3f4f4] rounded-[10px] border border-[rgba(223,223,223,0.50] shadow-[0px_5px_15px_0px_rgba(223,234,255,0.40)] flex flex-col w-[320px] sm:w-[600px] md:w-[745px] lg:w-[915px] xl:w-[1100px] 1xl:w-[1400px] 2xl:w-[1500px] py-[24px] px-[34px] min-h-[calc(100vh-48px)] justify-between">
                        <div className="min-h-[calc(100vh-155px)]">
                            <div className="flex items-center justify-between rounded-t-[15px]">
                                <h3 className="text-lg text-primaryLight font-medium flex items-center">{t("minimize_window.title")}</h3>
                                <div className="cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full" onClick={() => setIsOpen(false)}>
                                    <SampleTestClose />
                                </div>
                            </div>

                            {screenShotList?.length > 0 ? <div className="flex items-center justify-center lg:justify-start w-full gap-[16px] flex-wrap py-[21px]">
                                {Array.isArray(screenShotList) && screenShotList.map((item: any, index: number) => (
                                    <div className="w-[271px] rounded-[8px] border border-[#FFEAEA] bg-white shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] py-[13px] px-[9px] flex flex-col gap-[9px]" key={index}>
                                        <div className="flex items-center justify-between w-full">
                                            <div className='flex items-center justify-start gap-[4px] w-full'>
                                                {/* Logo */}
                                                <h2 className="text-primary text-[11.05px] font-bold italic">Vista<span className={`${theme[item?.moduleName]?.text} not-italic`}>ERP</span></h2>
                                                <div className={`border-r ${theme[item?.moduleName]?.border} h-[10px]`}></div>
                                                <div className={`${theme[item?.moduleName]?.text} text-[11.05px] font-light leading-normal ${(item?.moduleName === "hrms" || item?.moduleName === "lims" || item?.moduleName === "crm") ? "uppercase" : "capitalize"}`}>{item?.moduleName}</div>
                                            </div>

                                            <div className="cursor-pointer" onClick={() => handleClose(index)}>
                                                <AllClose className="w-[14px] h-[14px]" />
                                            </div>
                                        </div>

                                        <div className="cursor-pointer w-full" onClick={() => handleSwitch(item)}>
                                            <ScreenShot color={theme[item?.moduleName]?.color} />
                                        </div>

                                        <p className="text-dGray text-sm font-medium">{item?.title}: &nbsp; <span className="text-primaryLight text-sm font-medium">{item?.detail}</span></p>

                                        <div className="flex items-center justify-between w-full">
                                            <div className="flex items-center gap-[4px] cursor-pointer relative" onClick={() => { navigator.clipboard.writeText(item?.link); setCopy(index) }}>
                                                <CopyLink className="fill-primaryLight" />
                                                <h4 className="text-primaryLight text-sm font-medium">{t("minimize_window.copy_link")}</h4>

                                                {(copy === index) && <span className="absolute right-[3px] bg-gray-800 border border-[rgba(19,63,151,0.1] rounded-full py-[6px] px-[11px] z-20 divide-gray-800 before:absolute before:content-[''] before:bottom-[-9px] before:right-[20px] before:w-[10px] before:h-[10px] before:bg-gray-800 before:-translate-y-1/2 before:-translate-x-1/2 before:rotate-45 top-[-35px] transition-all text-xs text-white scale-100">{t("minimize_window.copied")}</span>}
                                            </div>

                                            <div className="flex items-center gap-[4px]">
                                                <TestingDateIcon />
                                                <p className="text-mGray text-xs font-normal">{item?.date}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> :
                                <div className="flex items-center justify-center min-h-[calc(100vh-125px)] flex-col">
                                    <img src={require("../../../assets/images/no-tab.png")} alt="" />
                                    <h3 className="text-primaryLight text-[1.625rem] font-semibold pt-[38px]">{t("minimize_window.no_window.title")}</h3>
                                    <p className="text-dGray font-normal text-sm ">{t("minimize_window.no_window.desc")}</p>
                                </div>}
                        </div>

                        {screenShotList?.length > 0 && <div className='w-full flex items-end justify-end'>
                            <button className="bg-[#FFF6F6] border border-[rgba(255,0,0,0.30)] rounded-[10px] px-[24px] h-[40px] flex items-center justify-center cursor-pointer gap-[8px]" onClick={handleDelete}><AllClose className="w-[19px] h-[19px]" /> <span className="text-[#F00] text-sm font-medium">{t("minimize_window.btn")}</span></button>
                        </div>}
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default AllTabs;