import AWS from 'aws-sdk';

let awsKey = process.env.REACT_APP_S3_ID;
let awsSecret = process.env.REACT_APP_S3_SECRET;
let bucket = process.env.REACT_APP_S3_BUCKET_NAME;

AWS.config.update({
    accessKeyId: awsKey,
    secretAccessKey: awsSecret,
});

let s3 = new AWS.S3({ apiVersion: '2006-03-01', region: process.env.REACT_APP_S3_DEFAULT_REGION });

export const getAWSFileUrl = async (fileName: any, folder: any) => {
    // Bucket: folder ? folder : bucket,
    let params: any = {
        Bucket: bucket,
        Key: fileName,
        Expires: 3600
    };
    const file = await s3.getSignedUrl('getObject', params)
    return file;
};

export const postAWSFile = async (file: any, key: any, folder: any) => {
    let params: any = {
        Bucket: bucket,
        Key: !!key ? key : file?.name,
        Body: file,
    };

    if (!!file?.type) {
        params["ContentType"] = file?.type
    }
    return new Promise((resolve, reject) => {
        s3.putObject(params, (error: any, data: any) => {
            if (error) {
                return reject(false);
            }
            if (data) {
                return resolve(true);
            }
        });

    });
};