/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import ActivitySidebar from "../../components/layout/Common/ActivitySidebar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ScreenRightArrow } from "../../components/svg/AllSvg";
import { useEffect, useState } from "react";
import Task from "../../components/layout/Common/Task";
import Note from "../../components/layout/Common/Note";
import Chat from "../../components/layout/Common/Chat";
import AddEmployee from "../../components/laboratory/dashboard/AddEmployee";
import SuccessPopup from "../../components/laboratory/dashboard/SuccessPopup";
import EditEmployee from "../../components/laboratory/dashboard/EditEmployee";
// import { useSortcut } from "../../shared/hooks/use-sortcut";
import ModifyRole from "../../components/laboratory/dashboard/ModifyRole";
import { uiActions } from "../../shared/store/ui-Slice";
import Search from "../../components/layout/Common/Search";
import { tableActions } from "../../shared/store/table-Slice";
import { groupByApi, listApi, tabApi } from "../../shared/utils/apis";
import ViewAllList from "../../components/form/ViewAllList";
import GridView from "../../components/laboratory/dashboard/GridView";
import TableView from "../../components/laboratory/dashboard/TableView";
import { employeeActions } from "../../shared/store/employee-Slice";
import Header from "../../components/layout/Common/Header";
import MiniSidebar from "../../components/layout/Common/MiniSidebar";

const LaboratoryLayout: React.FC<any> = ({ role, path }) => {
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const isOpen: boolean = useSelector((state: any) => state.ui.isOpen);
    const permissionRole: any = useSelector((state: any) => state.auth.permissionRole);
    const sideBarOption: string = useSelector((state: any) => state.ui.sideBarOption);
    const chatUsersList: any[] = useSelector((state: any) => state.ui.chatUsersList);
    const isEmployeeCreate: boolean = useSelector((state: any) => state.employee.isEmployeeCreate);
    const isEmployeeEdit: boolean = useSelector((state: any) => state.employee.isEmployeeEdit);
    const modifyRole: boolean = useSelector((state: any) => state.employee.modifyRole);
    const isSuccess: boolean = useSelector((state: any) => state.employee.isSuccess);
    const option: any = useSelector((state: any) => state.table.option);
    const sortBy: string = useSelector((state: any) => state.table.sortBy);
    const filterResult: string = useSelector((state: any) => state.table.filterResult);
    const page: number = useSelector((state: any) => state.table.page);
    const size: number = useSelector((state: any) => state.table.size);
    const groupByOptionValue: string = useSelector((state: any) => state.table.groupByOptionValue);
    const viewAll: boolean = useSelector((state: any) => state.table.viewAll);
    const childSize: number = useSelector((state: any) => state.table.childSize);
    const childPage: number = useSelector((state: any) => state.table.childPage);
    const groupByExpand: any = useSelector((state: any) => state.table.groupByExpand);
    const success: boolean = useSelector((state: any) => state.table.success);
    const selectOption: boolean = useSelector((state: any) => state.table.selectOption);
    const module: string = useSelector((state: any) => state.ui.module);
    const scrollY: boolean = useSelector((state: any) => state.ui.scrollY);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const location = useLocation();

    // const handleOpen = (): void => {
    //     setIsSearch(true);
    // };

    // useSortcut(["Control", "s"], handleOpen);

    const getTagList = async () => {
        try {
            const { tabArr, sortBy, groupBby, filterArr, status, fieldType }: any = await tabApi("/labs/manpower-configuration-list/");

            if (status === 200) {
                dispatch(tableActions.setTagList(tabArr));
                dispatch(tableActions.setSortByList(sortBy));
                dispatch(tableActions.setGroupByList(groupBby));
                dispatch(tableActions.setFilterList(filterArr));
                dispatch(tableActions.setFieldType(fieldType));
                if (!!module && module === 'employee' && !!!selectOption) {
                    dispatch(tableActions.changeOption({ name: tabArr[0]?.name, module: 'employee' }))
                } else {
                    dispatch(tableActions.changeOption({ name: selectOption, module: 'employee' }))
                }
            }
        } catch (error: any) {
            console.error("error while feching tags", error);
        }
    }

    const getUserList = async () => {
        try {
            dispatch(tableActions.setLoading(true));
            let url: any;
            viewAll ? url = "/labs/manpower-list-custom-group-by" : url = "/labs/api/manpower-custom-list";
            const {
                data,
                count,
                defaultFields,
                detailFieldArr,
                frontFieldList,
                status
            }: any = await listApi(url, option?.name, sortBy, page, size, filterResult, "employee", groupByOptionValue, childPage, childSize, groupByExpand, viewAll);

            if (status === 200) {
                dispatch(tableActions.setLoading(false));
                dispatch(tableActions.setData(data));
                viewAll ? dispatch(tableActions.setChildTotal(count)) : dispatch(tableActions.setTotal(count))
                dispatch(tableActions.setDefaultField(defaultFields));
                dispatch(tableActions.setDetailField(detailFieldArr));
                dispatch(tableActions.setFrontField(frontFieldList));
                dispatch(tableActions.setSuccess(false));
            }
        } catch (error: any) {
            console.error("error while feching employee data", error);
        }
    }

    const getUserListGroupBy = async () => {
        try {
            dispatch(tableActions.setLoading(true));
            const {
                data,
                count,
                defaultFields,
                detailFieldArr,
                frontFieldList,
                status
            }: any = await groupByApi("/labs/manpower-list-custom-group-by", option?.name, sortBy, page, size, filterResult, groupByOptionValue, "employee");

            if (status === 200) {
                dispatch(tableActions.setLoading(false));
                dispatch(tableActions.setGroupByData(data));
                dispatch(tableActions.setTotal(count));
                dispatch(tableActions.setDefaultField(defaultFields));
                dispatch(tableActions.setDetailField(detailFieldArr));
                dispatch(tableActions.setFrontField(frontFieldList));
                dispatch(tableActions.setSuccess(false));
            }
        } catch (error: any) {
            console.error("error while feching employee data", error);
        }
    }

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000)
        }
    }, [isOpen, sideBarOption]);

    useEffect(() => {
        dispatch(uiActions.setSettingPath(path))
        dispatch(uiActions.setModuleName("hrms"))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        option?.module !== 'employee' && dispatch(tableActions.setClear());
        !!module && getTagList();
    }, [module])

    useEffect(() => {
        (!(!!groupByOptionValue || viewAll) && (option?.module === 'employee' && !!option?.name)) && getUserList();
        // eslint-disable-next-line
    }, [option, sortBy, page, filterResult, size, viewAll, childSize, childPage])

    useEffect(() => {
        (!!groupByOptionValue && (option?.module === 'employee' && !!option?.name)) && getUserListGroupBy();
        // eslint-disable-next-line
    }, [groupByOptionValue, filterResult, sortBy, option, page, size])

    useEffect(() => {
        if (success) {
            (!!!groupByOptionValue && (option?.module === 'employee' && !!option?.name)) && getUserList();
            (!!groupByOptionValue && (option?.module === 'employee' && !!option?.name)) && getUserListGroupBy();
        }
        // eslint-disable-next-line
    }, [success])

    useEffect(() => {
        if (location.pathname !== "/laboratory") {
            dispatch(employeeActions.setChatUser(chatUsersList[0]));
        }
        // eslint-disable-next-line
    }, [window.location.pathname]);

    useEffect(() => {
        if (isSuccess || isEmployeeCreate || isEmployeeEdit || modifyRole || isSearch || viewAll) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isEmployeeCreate, isSuccess, isEmployeeEdit, modifyRole, isSearch, viewAll]);

    useEffect(() => {
        if (window.innerWidth <= 1200 && isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [window.innerWidth, isOpen])

    return (
        <>
            {isEmployeeCreate && <AddEmployee getUserList={getUserList} getTagList={getTagList} />}
            {isEmployeeEdit && <EditEmployee getUserList={getUserList} getTagList={getTagList} />}
            {modifyRole && <ModifyRole getUserList={getUserList} getTagList={getTagList} />}
            {isSuccess && <SuccessPopup />}
            {viewAll && <ViewAllList grid={<GridView type="group" />} table={<TableView type="group" />} />}
            <div className="w-full h-full relative">
                {/* Backdrop */}
                <div>
                    <span
                        id="menu-remove"
                        className={`fixed top-0 left-0 z-30 ${isOpen ? "block" : "hidden"
                            } h-screen w-full bg-[#00000080] xlx:hidden`}
                        onClick={() => dispatch(uiActions.toggle())}
                    ></span>
                </div>

                {/* Close Section Icon */}
                {permissionRole?.includes(role) && <div className={`bg-white w-[24px] h-[24px] shadow-md flex items-center justify-center rounded-full fixed top-[96px] cursor-pointer z-[5] right-[38px]`} onClick={() => { !isOpen && setIsLoading(true); dispatch(uiActions.toggle()); }}>
                    <ScreenRightArrow color="#20328F" />
                </div>}

                {/* Header */}
                <Header setIsSearch={setIsSearch} />

                <div className="w-full min-h-screen bg-[#F8FAFB] flex pt-[70px]">
                    {/* Sidebar */}
                    {permissionRole?.includes(role) && <MiniSidebar />}

                    <div className={`!h-full flex px-[50px] ${isOpen ? "w-full xlx:w-[calc(100%-300px)]" : "w-full"} transition-all`}>
                        <Outlet />

                        <Search isSearch={isSearch} setIsSearch={setIsSearch} />

                        <div className={`${isOpen ? "w-full sm:w-[300px] fixed right-0 bottom-0 z-30 xlx:z-[2] xlx:right-[50px] bg-white" : "translate-x-full w-0"} overflow-y-auto overflow-x-hidden transition duration-150 ${scrollY ? "top-0 xlx:top-[75px]" : "top-0 xlx:top-[70px]"}`}>
                            {/* Activity */}
                            {isLoading ? <div className="w-full h-full flex items-center justify-center">
                                <div className="circle circle-1"></div>
                                <div className="circle circle-2"></div>
                                <div className="circle circle-3"></div>
                                <div className="circle circle-4"></div>
                                <div className="circle circle-5"></div>
                            </div> :
                                <>
                                    {(!isLoading && sideBarOption === "Todo") && <Task />}
                                    {(!isLoading && sideBarOption === "Note") && <Note />}
                                    {(!isLoading && sideBarOption === "Chat") && <Chat />}
                                </>
                            }
                        </div>
                    </div>

                    {/* Activity right Navbar */}
                    {permissionRole?.includes(role) && <ActivitySidebar setIsLoading={setIsLoading} />}
                </div>
            </div>
        </>
    );
};

export default LaboratoryLayout;