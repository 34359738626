import { createSlice } from '@reduxjs/toolkit';

interface InvoiceState {
    isOpen: boolean,
    view: string,
    groupBy: boolean,
    groupByOption: string,
    groupByOptionValue: string,
    screenShotList: any[],
    option: string,
    sortBy: string,
    sortByList: any[],
    groupByList: any[],
    crmData: any,
    activityFeed: any[],
    todoList: any[],
    sampleDetail: any[],
    userGroupByData: any,
    tagList: any[],
    crmLoading: boolean,
    sideBarOption: string,
    chatUser: any,
    chatUsersList: any[],
    select: string,
    isCustomerCreate: boolean,
    isSuccess: boolean,
    page: number,
    total: number,
    size: number,
    filterList: any[],
    filterResult: string,
    empName: string,
    defaultField: any[],
    detailField: any[],
    successType: string,
    isEmployeeEdit: boolean,
    selectedId: string,
    groupByExpand: any,
    qrcodePopup: boolean,
    sampleDetailPopup: any,
    tab: string,
    toggleTab: string,
    confirmation: boolean,
    detail: any,
    createGroup: boolean,
    successCreateGroup: boolean,
    customer: any,
    apiCall: string,
    isOrgOpen: boolean,
    fullName: string,
    paymentData: any[],
    paymentLoading: boolean,
    recordPayment: boolean,
    productOpen: boolean,
    billOpen: boolean,
    billingData: any,
    shippingData: any,
    shipOpen: boolean,
    billingDisable: boolean,
    shippingDisable: boolean,
    invoiceDetail: any,
    billingSame: boolean,
    selectCustOpen: boolean,
    recordPaymentOpen: boolean,
    orderRecordPayment: boolean,
    billingUpdate: boolean,
    shippingUpdate: boolean,
    paymentSuccess: boolean,
    cust: any,
    customerOption: any,
    customerTagList: any[],
    adjustPayment: boolean,
    adjustAmount: any,
    selectId: string,
    fullView: boolean,
    adjustPaymentInvoice: boolean,
    extraInvoice: boolean,
    creditNote: boolean,
    tagApiCall: boolean,
    addQtyOption: string,
    addQtyTagList: any[],
    frontField: any,
    invoiceSortByList: any[],
    invoiceGroupByList: any[],
    invoiceFilterList: any[],
    invoiceDetailField: any[],
    invoiceDefaultField: any[],
    invoiceFrontField: any,
    invoiceFilterResult: string,
    invoiceSortBy: string,
    invoicePage: number,
    invoiceTotal: number,
    invoiceSize: number,
    invoiceGroupByData: any[],
    invoiceGroupByExpand: any,
    invoiceOption: string,
    invoiceView: string,
    invoiceGroupBy: boolean,
    invoiceGroupByOption: string,
    invoiceGroupByOptionValue: string,
    invoiceTagList: any[],
    invoiceData: any[],
    invoiceLoading: boolean,
    creditPolicyOption: string,
    creditPolicyTagList: any[],
    creditPolicySortByList: any[],
    creditPolicyGroupByList: any[],
    creditPolicyFilterList: any[],
    creditPolicyDetailField: any[],
    creditPolicyDefaultField: any[],
    creditPolicyFrontField: any,
    creditPolicyFilterResult: string,
    creditPolicySortBy: string,
    creditPolicyPage: number,
    creditPolicyTotal: number,
    creditPolicySize: number,
    creditPolicyGroupByData: any[],
    creditPolicyGroupByExpand: any,
    creditPolicyView: string,
    creditPolicyGroupBy: boolean,
    creditPolicyGroupByOption: string,
    creditPolicyGroupByOptionValue: string,
    creditPolicyData: any[],
    creditNoteOption: string,
    creditNoteTagList: any[],
    creditNoteSortByList: any[],
    creditNoteGroupByList: any[],
    creditNoteFilterList: any[],
    creditNoteDetailField: any[],
    creditNoteDefaultField: any[],
    creditNoteFrontField: any,
    creditNoteFilterResult: string,
    creditNoteSortBy: string,
    creditNotePage: number,
    creditNoteTotal: number,
    creditNoteSize: number,
    creditNoteGroupByData: any[],
    creditNoteGroupByExpand: any,
    creditNoteView: string,
    creditNoteGroupBy: boolean,
    creditNoteGroupByOption: string,
    creditNoteGroupByOptionValue: string,
    creditNoteData: any[]
}

const initialState: InvoiceState = {
    isOpen: false,
    view: "card",
    groupBy: false,
    groupByOption: "",
    groupByOptionValue: "",
    screenShotList: JSON.parse(localStorage.getItem('screenshot') as any) || [],
    option: "",
    sortBy: "-created_at",
    sortByList: [],
    groupByList: [],
    crmData: null,
    activityFeed: [],
    todoList: [],
    sampleDetail: [],
    userGroupByData: null,
    tagList: [],
    crmLoading: true,
    isSuccess: false,
    sideBarOption: "All",
    chatUser: {},
    chatUsersList: [
        { id: 1, logo: "V", bg: "bg-[#0297a7]", name: "Vista Lims", email: "harshita.kachhadiya@arohatech.com" },
        { id: 2, logo: "P", bg: "bg-[#502aaf]", name: "Project Meeting", email: "harshita.kachhadiya@arohatech.com" },
        { id: 3, logo: "N", bg: "bg-[#689f38]", name: "New Project", email: "harshita.kachhadiya@arohatech.com" },
    ],
    select: "",
    isCustomerCreate: false,
    page: 1,
    total: 0,
    size: 10,
    filterList: [],
    filterResult: "",
    empName: "",
    defaultField: [],
    detailField: [],
    successType: "",
    isEmployeeEdit: false,
    selectedId: "",
    groupByExpand: {},
    qrcodePopup: false,
    sampleDetailPopup: 0,
    tab: "Todo List",
    toggleTab: "Sample Information",
    confirmation: false,
    detail: null,
    createGroup: false,
    successCreateGroup: false,
    customer: null,
    apiCall: "",
    isOrgOpen: false,
    fullName: "",
    paymentData: [],
    paymentLoading: true,
    recordPayment: false,
    productOpen: false,
    billOpen: false,
    billingData: {},
    shippingData: {},
    shipOpen: false,
    shippingDisable: true,
    billingDisable: true,
    invoiceDetail: null,
    billingSame: true,
    selectCustOpen: false,
    recordPaymentOpen: false,
    orderRecordPayment: false,
    billingUpdate: false,
    shippingUpdate: false,
    paymentSuccess: false,
    cust: null,
    customerOption: "",
    customerTagList: [],
    adjustPayment: false,
    adjustAmount: 0,
    selectId: "",
    fullView: false,
    adjustPaymentInvoice: false,
    extraInvoice: false,
    creditNote: false,
    tagApiCall: false,
    addQtyOption: "",
    addQtyTagList: [],
    frontField: null,
    invoiceSortByList: [],
    invoiceGroupByList: [],
    invoiceFilterList: [],
    invoiceDetailField: [],
    invoiceDefaultField: [],
    invoiceFrontField: null,
    invoiceFilterResult: "",
    invoiceSortBy: "-created_at",
    invoicePage: 1,
    invoiceTotal: 0,
    invoiceSize: 10,
    invoiceGroupByData: [],
    invoiceGroupByExpand: {},
    invoiceOption: "",
    invoiceView: "card",
    invoiceGroupBy: false,
    invoiceGroupByOption: "",
    invoiceGroupByOptionValue: "",
    invoiceTagList: [],
    invoiceData: [],
    invoiceLoading: true,
    creditPolicyOption: "",
    creditPolicyTagList: [],
    creditPolicySortByList: [],
    creditPolicyGroupByList: [],
    creditPolicyFilterList: [],
    creditPolicyDetailField: [],
    creditPolicyDefaultField: [],
    creditPolicyFrontField: null,
    creditPolicyFilterResult: "",
    creditPolicySortBy: "-created_at",
    creditPolicyPage: 1,
    creditPolicyTotal: 0,
    creditPolicySize: 10,
    creditPolicyGroupByData: [],
    creditPolicyGroupByExpand: {},
    creditPolicyView: "card",
    creditPolicyGroupBy: false,
    creditPolicyGroupByOption: "",
    creditPolicyGroupByOptionValue: "",
    creditPolicyData: [],
    creditNoteOption: "",
    creditNoteTagList: [],
    creditNoteSortByList: [],
    creditNoteGroupByList: [],
    creditNoteFilterList: [],
    creditNoteDetailField: [],
    creditNoteDefaultField: [],
    creditNoteFrontField: null,
    creditNoteFilterResult: "",
    creditNoteSortBy: "-created_at",
    creditNotePage: 1,
    creditNoteTotal: 0,
    creditNoteSize: 10,
    creditNoteGroupByData: [],
    creditNoteGroupByExpand: {},
    creditNoteView: "card",
    creditNoteGroupBy: false,
    creditNoteGroupByOption: "",
    creditNoteGroupByOptionValue: "",
    creditNoteData: []
}

const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        Open: (state) => {
            state.isOpen = true;
        },
        Close: (state) => {
            state.isOpen = false;
        },
        toggle: (state) => {
            state.isOpen = !state.isOpen;
        },
        groupByToggle: (state) => {
            state.groupBy = true;
        },
        groupToggle: (state) => {
            state.groupBy = false;
            state.groupByOption = "";
            state.groupByOptionValue = "";
            state.page = 1;
        },
        handleView: (state, action) => {
            state.view = action.payload;
        },
        handleGroupOption: (state, action) => {
            state.groupByOption = action.payload;
        },
        handleGroupOptionValue: (state, action) => {
            state.groupByOptionValue = action.payload;
        },
        updateScreenShotList: (state, action) => {
            state.screenShotList = [...state.screenShotList, action.payload];
        },
        assignScreenShotList: (state, action) => {
            state.screenShotList = action.payload;
        },
        changeOption: (state, action) => {
            state.option = action.payload;
        },
        switchOption: (state, action) => {
            state.sideBarOption = action.payload;
        },
        setChatUser: (state, action) => {
            state.chatUser = action.payload;
        },
        setSelect: (state, action) => {
            state.select = action.payload;
        },
        setIsCustomerCreate: (state, action) => {
            state.isCustomerCreate = action.payload;
        },
        setSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setSortByList: (state, action) => {
            state.sortByList = action.payload;
        },
        setCRMList: (state, action) => {
            state.crmData = action.payload;
        },
        setFeedList: (state, action) => {
            state.activityFeed = action.payload;
        },
        setTodoList: (state, action) => {
            state.todoList = action.payload;
        },
        setSampleDetail: (state, action) => {
            state.sampleDetail = action.payload;
        },
        setUserGroupByList: (state, action) => {
            state.userGroupByData = action.payload;
        },
        setTagList: (state, action) => {
            state.tagList = action.payload;
        },
        setCRMLoading: (state, action) => {
            state.crmLoading = action.payload;
        },
        setGroupByList: (state, action) => {
            state.groupByList = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setTotal: (state, action) => {
            state.total = action.payload
        },
        setSize: (state, action) => {
            state.size = action.payload
        },
        setIsSuccess: (state, action) => {
            state.isSuccess = action.payload
        },
        setFilterList: (state, action) => {
            state.filterList = action.payload
        },
        setFilterResult: (state, action) => {
            state.filterResult = action.payload
        },
        setEmpName: (state, action) => {
            state.empName = action.payload
        },
        setDefaultField: (state, action) => {
            state.defaultField = action.payload
        },
        setDetailField: (state, action) => {
            state.detailField = action.payload
        },
        setIsEmployeeEdit: (state, action) => {
            state.isEmployeeEdit = action.payload;
        },
        setSuccessType: (state, action) => {
            state.successType = action.payload
        },
        setSelectedId: (state, action) => {
            state.selectedId = action.payload
        },
        setGroupByExpand: (state, action) => {
            state.groupByExpand = action.payload
        },
        setQRCodePopup: (state, action) => {
            state.qrcodePopup = action.payload
        },
        setSampleDetailPopup: (state, action) => {
            state.sampleDetailPopup = action.payload
        },
        setTab: (state, action) => {
            state.tab = action.payload
        },
        setToggleTab: (state, action) => {
            state.toggleTab = action.payload
        },
        setConformation: (state, action) => {
            state.confirmation = action.payload
        },
        setDetail: (state, action) => {
            state.detail = action.payload
        },
        setCreateGroup: (state, action) => {
            state.createGroup = action.payload
        },
        setCustomer: (state, action) => {
            state.customer = action.payload
        },
        setApiCall: (state, action) => {
            state.apiCall = action.payload
        },
        setSuccessCreateGroup: (state, action) => {
            state.successCreateGroup = action.payload
        },
        setIsOrgOpen: (state, action) => {
            state.isOrgOpen = action.payload
        },
        setFullName: (state, action) => {
            state.fullName = action.payload
        },
        setPaymentData: (state, action) => {
            state.paymentData = action.payload
        },
        setPaymentLoading: (state, action) => {
            state.paymentLoading = action.payload
        },
        setRecordPayment: (state, action) => {
            state.recordPayment = action.payload
        },
        setOrderRecordPayment: (state, action) => {
            state.orderRecordPayment = action.payload
        },
        setProductOpen: (state, action) => {
            state.productOpen = action.payload
        },
        setBillOpen: (state, action) => {
            state.billOpen = action.payload
        },
        setShipOpen: (state, action) => {
            state.shipOpen = action.payload
        },
        setBillingData: (state, action) => {
            state.billingData = action.payload
        },
        setShippingData: (state, action) => {
            state.shippingData = action.payload
        },
        setBillingDisable: (state, action) => {
            state.billingDisable = action.payload
        },
        setShippingDisable: (state, action) => {
            state.shippingDisable = action.payload
        },
        setInvoiceDetail: (state, action) => {
            state.invoiceDetail = action.payload
        },
        setBillingSame: (state) => {
            state.billingSame = !state.billingSame
        },
        setBillingSameState: (state, action) => {
            state.billingSame = action.payload
        },
        setSelectCustOpen: (state, action) => {
            state.selectCustOpen = action.payload
        },
        setRecordPaymentOpen: (state, action) => {
            state.recordPaymentOpen = action.payload
        },
        setBillingUpdateToggle: (state,) => {
            state.billingUpdate = !state.billingUpdate
        },
        setBillingUpdate: (state, action) => {
            state.billingUpdate = action.payload
        },
        setShippingUpdateToggle: (state,) => {
            state.shippingUpdate = !state.shippingUpdate
        },
        setShippingUpdate: (state, action) => {
            state.shippingUpdate = action.payload
        },
        setPaymentSuccess: (state, action) => {
            state.paymentSuccess = action.payload
        },
        setCust: (state, action) => {
            state.cust = action.payload
        },
        setCustomerOptionChange: (state, action) => {
            state.customerOption = action.payload
        },
        setCustomerTagList: (state, action) => {
            state.customerTagList = action.payload
        },
        setAdjustPayment: (state, action) => {
            state.adjustPayment = action.payload
        },
        setAdjustAmount: (state, action) => {
            state.adjustAmount = action.payload
        },
        setSelectId: (state, action) => {
            state.selectId = action.payload
        },
        setFullView: (state, action) => {
            state.fullView = action.payload
        },
        setAdjustPaymentInvoice: (state, action) => {
            state.adjustPaymentInvoice = action.payload
        },
        setExtraInvoice: (state, action) => {
            state.extraInvoice = action.payload
        },
        setCreditNote: (state, action) => {
            state.creditNote = action.payload
        },
        setTagApiCall: (state, action) => {
            state.tagApiCall = action.payload
        },
        setCreditPolicyOption: (state, action) => {
            state.creditPolicyOption = action.payload
        },
        setCreditPolicyTagList: (state, action) => {
            state.creditPolicyTagList = action.payload
        },
        setAddQtyOption: (state, action) => {
            state.addQtyOption = action.payload
        },
        setAddQtyTagList: (state, action) => {
            state.addQtyTagList = action.payload
        },
        setFrontField: (state, action) => {
            state.frontField = action.payload
        },
        setInvoiceTagList: (state, action) => {
            state.invoiceTagList = action.payload;
        },
        setInvoiceSortByList: (state, action) => {
            state.invoiceSortByList = action.payload;
        },
        setInvoiceGroupByList: (state, action) => {
            state.invoiceGroupByList = action.payload;
        },
        setInvoiceFilterList: (state, action) => {
            state.invoiceFilterList = action.payload
        },
        setInvoiceChangeOption: (state, action) => {
            state.invoiceOption = action.payload;
        },
        setInvoiceTotal: (state, action) => {
            state.invoiceTotal = action.payload
        },
        setInvoiceDefaultField: (state, action) => {
            state.invoiceDefaultField = action.payload
        },
        setInvoiceDetailField: (state, action) => {
            state.invoiceDetailField = action.payload
        },
        setInvoiceFilterResult: (state, action) => {
            state.invoiceFilterResult = action.payload
        },
        setInvoiceFrontField: (state, action) => {
            state.invoiceFrontField = action.payload
        },
        setInvoiceGroupByToggle: (state) => {
            state.invoiceGroupBy = true;
        },
        invoiceGroupToggle: (state) => {
            state.invoiceGroupBy = false;
            state.invoiceGroupByOption = "";
            state.invoiceGroupByOptionValue = "";
            state.invoicePage = 1;
        },
        invoiceHandleView: (state, action) => {
            state.invoiceView = action.payload;
        },
        invoiceHandleGroupOption: (state, action) => {
            state.invoiceGroupByOption = action.payload;
        },
        invoiceHandleGroupOptionValue: (state, action) => {
            state.invoiceGroupByOptionValue = action.payload;
        },
        setInvoicePage: (state, action) => {
            state.invoicePage = action.payload
        },
        setInvoiceSize: (state, action) => {
            state.invoiceSize = action.payload
        },
        setInvoiceData: (state, action) => {
            state.invoiceData = action.payload
        },
        setInvoiceLoading: (state, action) => {
            state.invoiceLoading = action.payload
        },
        setInvoiceGroupByData: (state, action) => {
            state.invoiceGroupByData = action.payload
        },
        setInvoiceGroupByExpand: (state, action) => {
            state.invoiceGroupByExpand = action.payload
        },
        setInvoiceSortBy: (state, action) => {
            state.invoiceSortBy = action.payload;
        },
        setCreditPolicySortByList: (state, action) => {
            state.creditPolicySortByList = action.payload;
        },
        setCreditPolicyGroupByList: (state, action) => {
            state.creditPolicyGroupByList = action.payload;
        },
        setCreditPolicyFilterList: (state, action) => {
            state.creditPolicyFilterList = action.payload
        },
        setCreditPolicyChangeOption: (state, action) => {
            state.creditPolicyOption = action.payload;
        },
        setCreditPolicyTotal: (state, action) => {
            state.creditPolicyTotal = action.payload
        },
        setCreditPolicyDefaultField: (state, action) => {
            state.creditPolicyDefaultField = action.payload
        },
        setCreditPolicyDetailField: (state, action) => {
            state.creditPolicyDetailField = action.payload
        },
        setCreditPolicyFilterResult: (state, action) => {
            state.creditPolicyFilterResult = action.payload
        },
        setCreditPolicyFrontField: (state, action) => {
            state.creditPolicyFrontField = action.payload
        },
        setCreditPolicyGroupByToggle: (state) => {
            state.creditPolicyGroupBy = true;
        },
        creditPolicyGroupToggle: (state) => {
            state.creditPolicyGroupBy = false;
            state.creditPolicyGroupByOption = "";
            state.creditPolicyGroupByOptionValue = "";
            state.creditPolicyPage = 1;
        },
        creditPolicyHandleView: (state, action) => {
            state.creditPolicyView = action.payload;
        },
        creditPolicyHandleGroupOption: (state, action) => {
            state.creditPolicyGroupByOption = action.payload;
        },
        creditPolicyHandleGroupOptionValue: (state, action) => {
            state.creditPolicyGroupByOptionValue = action.payload;
        },
        setCreditPolicyPage: (state, action) => {
            state.creditPolicyPage = action.payload
        },
        setCreditPolicySize: (state, action) => {
            state.creditPolicySize = action.payload
        },
        setCreditPolicyData: (state, action) => {
            state.creditPolicyData = action.payload
        },
        setCreditPolicyGroupByData: (state, action) => {
            state.creditPolicyGroupByData = action.payload
        },
        setCreditPolicyGroupByExpand: (state, action) => {
            state.creditPolicyGroupByExpand = action.payload
        },
        setCreditPolicySortBy: (state, action) => {
            state.creditPolicySortBy = action.payload;
        },
        setCreditNoteSortByList: (state, action) => {
            state.creditNoteSortByList = action.payload;
        },
        setCreditNoteGroupByList: (state, action) => {
            state.creditNoteGroupByList = action.payload;
        },
        setCreditNoteFilterList: (state, action) => {
            state.creditNoteFilterList = action.payload
        },
        setCreditNoteChangeOption: (state, action) => {
            state.creditNoteOption = action.payload;
        },
        setCreditNoteTagList: (state, action) => {
            state.creditNoteTagList = action.payload
        },
        setCreditNoteTotal: (state, action) => {
            state.creditNoteTotal = action.payload
        },
        setCreditNoteDefaultField: (state, action) => {
            state.creditNoteDefaultField = action.payload
        },
        setCreditNoteDetailField: (state, action) => {
            state.creditNoteDetailField = action.payload
        },
        setCreditNoteFilterResult: (state, action) => {
            state.creditNoteFilterResult = action.payload
        },
        setCreditNoteFrontField: (state, action) => {
            state.creditNoteFrontField = action.payload
        },
        setCreditNoteGroupByToggle: (state) => {
            state.creditNoteGroupBy = true;
        },
        creditNoteGroupToggle: (state) => {
            state.creditNoteGroupBy = false;
            state.creditNoteGroupByOption = "";
            state.creditNoteGroupByOptionValue = "";
            state.creditNotePage = 1;
        },
        creditNoteHandleView: (state, action) => {
            state.creditNoteView = action.payload;
        },
        creditNoteHandleGroupOption: (state, action) => {
            state.creditNoteGroupByOption = action.payload;
        },
        creditNoteHandleGroupOptionValue: (state, action) => {
            state.creditNoteGroupByOptionValue = action.payload;
        },
        setCreditNotePage: (state, action) => {
            state.creditNotePage = action.payload
        },
        setCreditNoteSize: (state, action) => {
            state.creditNoteSize = action.payload
        },
        setCreditNoteData: (state, action) => {
            state.creditNoteData = action.payload
        },
        setCreditNoteGroupByData: (state, action) => {
            state.creditNoteGroupByData = action.payload
        },
        setCreditNoteGroupByExpand: (state, action) => {
            state.creditNoteGroupByExpand = action.payload
        },
        setCreditNoteSortBy: (state, action) => {
            state.creditNoteSortBy = action.payload;
        },
    }
});

export default invoiceSlice;
export const invoiceActions = invoiceSlice.actions;