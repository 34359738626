import { Skeleton } from "primereact/skeleton";

const DocSection = () => {
    return (
        <div className="w-full bg-white shadow-[0px_4px_4px_rgba(24,39,75,0.08),0px_2px_4px_rgba(24,39,75,0.12)] rounded-[10px] h-full">
            <div className="h-[55px] flex items-center justify-between px-[27px] gap-[9px] border-b border-[rgba(147,147,147,0.29)] w-full">
                <Skeleton height="21px" className="!max-w-[207px] !rounded-full"></Skeleton>
            </div>

            <div className="px-[40px] py-[29px] w-full">
                <div className="flex flex-col gap-[15px] w-full border-b border-[rgba(147,147,147,0.15)] pb-[20px]">
                    <Skeleton height="15px" className="!max-w-[120px] !rounded-full"></Skeleton>

                    <div className="grid grid-cols-3 gap-x-[10px] gap-y-[15px]">
                        <div className="flex flex-col gap-[4px]">
                            <Skeleton height="12px" className="!max-w-[120px] !rounded-full"></Skeleton>
                            <Skeleton height="12px" className="!max-w-[189px] !rounded-full"></Skeleton>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                            <Skeleton height="12px" className="!max-w-[120px] !rounded-full"></Skeleton>
                            <Skeleton height="12px" className="!max-w-[189px] !rounded-full"></Skeleton>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                            <Skeleton height="12px" className="!max-w-[120px] !rounded-full"></Skeleton>
                            <Skeleton height="12px" className="!max-w-[189px] !rounded-full"></Skeleton>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-[15px] w-full pt-[20px]">
                    <Skeleton height="15px" className="!max-w-[120px] !rounded-full"></Skeleton>

                    <div className="grid grid-cols-3 gap-x-[10px] gap-y-[15px]">
                        <div className="flex flex-col gap-[4px]">
                            <Skeleton height="12px" className="!max-w-[120px] !rounded-full"></Skeleton>
                            <Skeleton height="12px" className="!max-w-[189px] !rounded-full"></Skeleton>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                            <Skeleton height="12px" className="!max-w-[120px] !rounded-full"></Skeleton>
                            <Skeleton height="12px" className="!max-w-[189px] !rounded-full"></Skeleton>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                            <Skeleton height="12px" className="!max-w-[120px] !rounded-full"></Skeleton>
                            <Skeleton height="12px" className="!max-w-[189px] !rounded-full"></Skeleton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocSection;