import useAxios from "../hooks/use-axios";
import * as yup from "yup";

export const tabApi = async (url: any): Promise<any> => {
    const res: any = await useAxios.get(url);

    if (res && res?.status === 200) {
        let tabArr: any[] = [];
        for (let i = 0; i < res?.data?.tags?.length; i++) {
            const el = res?.data?.tags[i];

            if (!el?.hide_from_frontend) {
                tabArr.push(el)
            }

        }
        tabArr = tabArr.sort((a, b) => parseFloat(a?.order) - parseFloat(b?.order));
        let arr: any[] = [];
        let fieldTypeObj: any = {};

        for (let i = 0; i < res?.data?.filters?.length; i++) {
            const el = res?.data?.filters[i];

            let optionObj: any[] = [];
            if (el?.choices) {
                for (let j = 0; j < el?.choices.length; j++) {
                    const option = el?.choices[j];

                    let obj = {
                        id: j + 1,
                        name: option?.text,
                        value: option?.value,
                        flag: false
                    }
                    optionObj.push(obj);
                }
            }

            if (el?.type === "date") {
                fieldTypeObj[el?.filter_name] = el?.type
            } else {
                fieldTypeObj[el?.field_name] = el?.type
            }

            let obj = {
                id: i + 1,
                name: el?.filter_name,
                text: el?.name,
                type: el?.type,
                option: el?.choices ? optionObj : [],
                field_name: !!el?.field_name ? el?.field_name : []
            }

            arr.push(obj)
        }

        return {
            tabArr,
            sortBy: res?.data?.sort_by,
            groupBby: res?.data?.group_by,
            filterArr: arr,
            status: res?.status,
            fieldType: fieldTypeObj
        }
    }
}

export const listApi = async (url: any, option: any, sortBy: any, page: any, size: any, filterResult: any, module: any, groupByOptionValue: any, childPage: any, childSize: any, groupByExpand: any, viewAll: any): Promise<any> => {
    let baseUrl: any;
    if (viewAll) {
        if (!!filterResult) {
            if (!!option) {
                baseUrl = `${url}/?tag__name__iexact=${option}&group_by_field=${groupByOptionValue}&sort_by=${sortBy}&page=${page}&size=${size}&child_page=${childPage}&child_size=${childSize}&group_by_value=${groupByExpand}&${filterResult}`
            } else {
                baseUrl = `${url}/?group_by_field=${groupByOptionValue}&sort_by=${sortBy}&page=${page}&size=${size}&child_page=${childPage}&child_size=${childSize}&group_by_value=${groupByExpand}&${filterResult}`
            }
        } else {
            if (!!option) {
                baseUrl = `${url}/?tag__name__iexact=${option}&group_by_field=${groupByOptionValue}&sort_by=${sortBy}&page=${page}&size=${size}&child_page=${childPage}&child_size=${childSize}&group_by_value=${groupByExpand}`
            } else {
                baseUrl = `${url}/?group_by_field=${groupByOptionValue}&sort_by=${sortBy}&page=${page}&size=${size}&child_page=${childPage}&child_size=${childSize}&group_by_value=${groupByExpand}`
            }
        }
    } else {
        if (!!filterResult) {
            baseUrl = `${url}/?tag__name__iexact=${option}&sort_by=${sortBy}&page=${page}&size=${size}&${filterResult}`
        } else {
            baseUrl = `${url}/?tag__name__iexact=${option}&sort_by=${sortBy}&page=${page}&size=${size}`
        }
    }

    const res: any = await useAxios.get(baseUrl);

    if (res && res?.status === 200) {
        let frontFieldList: any = res?.data?.default_fields?.display_name;
        let defaultFields: any[] = [];
        let customFieldArr: any[] = [];
        if (res?.data?.custom_fields?.length > 0) {
            for (let j = 0; j < res?.data?.custom_fields?.length; j++) {
                const c = res?.data?.custom_fields[j];
                customFieldArr.push(c?.slug);
                frontFieldList[c.slug] = c?.name
            }
        }

        let finalArr: any[] = [res?.data?.default_fields?.column_show, res?.data?.default_fields?.field_visibility, customFieldArr];
        let detailFieldArr: any[] = [...new Set([].concat(...finalArr)) as any]
        if (JSON.parse(localStorage.getItem(module) as any)) {
            defaultFields = JSON.parse(localStorage.getItem(module) as any)
        }
        defaultFields?.length === 0 && localStorage.setItem(module, JSON.stringify(res?.data?.default_fields?.column_show))

        return {
            data: viewAll ? res?.data?.data[0]?.data : res?.data?.data?.results,
            count: viewAll ? res?.data?.data[0]?.count : res?.data?.data?.count,
            defaultFields: defaultFields?.length === 0 ? res?.data?.default_fields?.column_show : defaultFields,
            detailFieldArr: detailFieldArr,
            frontFieldList: frontFieldList,
            status: res?.status
        }
    }

}

export const groupByApi = async (url: any, option: any, sortBy: any, page: any, size: any, filterResult: any, groupByOptionValue: any, module: any): Promise<any> => {
    let baseUrl: any;
    if (!!filterResult) {
        if (!!option) {
            baseUrl = `${url}/?tag__name__iexact=${option}&group_by_field=${groupByOptionValue}&sort_by=${sortBy}&page=${page}&size=${size}&${filterResult}`
        } else {
            baseUrl = `${url}/?group_by_field=${groupByOptionValue}&sort_by=${sortBy}&page=${page}&size=${size}&${filterResult}`
        }
    } else {
        if (!!option) {
            baseUrl = `${url}/?tag__name__iexact=${option}&group_by_field=${groupByOptionValue}&sort_by=${sortBy}&page=${page}&size=${size}`
        } else {
            baseUrl = `${url}/?group_by_field=${groupByOptionValue}&sort_by=${sortBy}&page=${page}&size=${size}`
        }
    }
    const res: any = await useAxios.get(baseUrl);

    if (res && res?.status === 200) {
        let frontFieldList: any = res?.data?.default_fields?.display_name;
        let defaultFields: any[] = [];
        let customFieldArr: any[] = [];
        if (res?.data?.custom_fields?.length > 0) {
            for (let j = 0; j < res?.data?.custom_fields?.length; j++) {
                const c = res?.data?.custom_fields[j];
                customFieldArr.push(c?.slug);
                frontFieldList[c.slug] = c?.name
            }
        }

        let finalArr: any[] = [res?.data?.default_fields?.column_show, res?.data?.default_fields?.field_visibility, customFieldArr];
        let detailFieldArr: any[] = [...new Set([].concat(...finalArr)) as any]
        if (JSON.parse(localStorage.getItem(module) as any)) {
            defaultFields = JSON.parse(localStorage.getItem(module) as any)
        }
        defaultFields?.length === 0 && localStorage.setItem(module, JSON.stringify(res?.data?.default_fields?.column_show))

        return {
            data: res?.data?.data,
            count: res?.data?.count,
            defaultFields: defaultFields?.length === 0 ? res?.data?.default_fields?.column_show : defaultFields,
            detailFieldArr: detailFieldArr,
            frontFieldList: frontFieldList,
            status: res?.status
        }
    }
}

export const dmsListApi = async (url: any, option: any, sortBy: any, page: any, size: any, filterResult: any, module: any, id: any): Promise<any> => {
    let baseUrl: any;
    if (!!filterResult) {
        if (!!id) {
            baseUrl = `${url}/?state__iexact=${option}&folder_location=${id}&sort_by=${sortBy}&page=${page}&size=${size}&${filterResult}`
        } else {
            baseUrl = `${url}/?state__iexact=${option}&sort_by=${sortBy}&page=${page}&size=${size}&${filterResult}`
        }
    } else {
        if (!!id) {
            baseUrl = `${url}/?state__iexact=${option}&folder_location=${id}&sort_by=${sortBy}&page=${page}&size=${size}`
        } else {
            baseUrl = `${url}/?state__iexact=${option}&sort_by=${sortBy}&page=${page}&size=${size}`
        }
    }

    const res: any = await useAxios.get(baseUrl);

    if (res && res?.status === 200) {
        let frontFieldList: any = res?.data?.default_fields?.display_name;
        let defaultFields: any[] = [];
        let customFieldArr: any[] = [];
        if (res?.data?.custom_fields?.length > 0) {
            for (let j = 0; j < res?.data?.custom_fields?.length; j++) {
                const c = res?.data?.custom_fields[j];
                customFieldArr.push(c?.slug);
                frontFieldList[c.slug] = c?.name
            }
        }

        let finalArr: any[] = [res?.data?.default_fields?.column_show, res?.data?.default_fields?.field_visibility, customFieldArr];
        let detailFieldArr: any[] = [...new Set([].concat(...finalArr)) as any]
        if (JSON.parse(localStorage.getItem(module) as any)) {
            defaultFields = JSON.parse(localStorage.getItem(module) as any)
        }
        defaultFields?.length === 0 && localStorage.setItem(module, JSON.stringify(res?.data?.default_fields?.column_show))

        return {
            data: res?.data?.data?.results,
            count: res?.data?.data?.count,
            defaultFields: defaultFields?.length === 0 ? res?.data?.default_fields?.column_show : defaultFields,
            detailFieldArr: detailFieldArr,
            frontFieldList: frontFieldList,
            status: res?.status
        }
    }

}

export const getCustomFields = async (modal: any) => {
    const res: any = await useAxios.get(`/utility/sections-wise-fields/?model=${modal}`);

    if (res && res.status === 200) {
        let arr: any[] = res?.data?.sections;
        let arr1: any[] = [];
        let obj1: any = {};
        if (res?.data?.defaults?.length > 0) {
            obj1.id = res?.data?.sections?.length + 1
            obj1.name = "Default"
            obj1.fields = res?.data?.defaults

            arr.push(obj1)
        }

        for (let i = 0; i < arr?.length; i++) {
            const el = arr[i];
            el.flag = false;
            arr1.push(el)
        }

        let obj: any = {};
        let validationObj: any = {};
        for (let j = 0; j < arr1?.length; j++) {
            const e = arr1[j];
            for (let k = 0; k < e?.fields?.length; k++) {
                const f = e?.fields[k];
                obj[f?.slug] = "";
                validationObj[f?.slug] = (f?.datatype === "int" || f?.datatype === "float" ? yup.number() : f?.datatype === "date" ? yup.date() : f?.datatype === "enum" ? yup.object() : yup.string())
            }
        }
        const schema = yup.object().shape(validationObj);

        return {
            data: arr1,
            customField: arr1?.length > 0 ? true : false,
            inputObj: obj,
            inputSchema: schema
        }
    }
}

export const getSampleDetail = async (id: any) => {
    const res: any = await useAxios.get(`/samples/api/sample-details/?sample_id=${id}`);

    if (res && res.status === 200) {
        return {
            data: res?.data[0],
            componentList: res?.data?.[0]?.component_permission_list?.length > 0 ? res?.data?.[0]?.component_permission_list?.map((item: any) => (item?.components?.[0])) : [],
            status: res?.status
        }
    }
}

export const createFile = async (data: any) => {
    let formdata = new FormData();
    formdata.append("name", data?.name);
    formdata.append("meta_description", "");
    formdata.append("display_name", data?.display_name);
    formdata.append("state", "PRIVATE");
    formdata.append("copy_parent_permission", 1 as any);
    formdata.append("parent", null as any);
    formdata.append("folder_location", data?.folder_location);
    formdata.append("tags", JSON.stringify([]));
    formdata.append("storage_path", data?.storage_path);
    formdata.append("docs_type", "Pdf");

    const response: any = await useAxios.post('/vista-dms/create-file-folder/', formdata, { headers: { "Content-Type": "multipart/form-data" } });

    return response;
}