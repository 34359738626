import { Approve, DataDotsIcon, NameOrgIcon, Reject, SortArrow, SortDownArrow, SortUpArrow } from "../../../svg/AllSvg";
import React, { useEffect, useState } from "react";
import SkeletonGridView from "../../../../skeleton/components/invoice/creditPolicy/GridView";
import { useSelector } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { randomColor } from "../../../../shared/utils/randomColor";
import Moment from 'moment';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import useAxios from "../../../../shared/hooks/use-axios";
import { success } from "../../../../shared/hooks/use-toastify";
import { invoiceActions } from "../../../../shared/store/invoice-Slice";
import { useDispatch } from "react-redux";
import { Paginator } from "primereact/paginator";
import { tableActions } from "../../../../shared/store/table-Slice";

const GridView: React.FC<any> = ({ type }: any) => {
    let userDetail: any = JSON.parse(localStorage.getItem('userdetail') as any);
    const frontField: any = useSelector((state: any) => state.table.frontField);
    const data: any[] = useSelector((state: any) => state.table.data);
    const loading: boolean = useSelector((state: any) => state.table.loading);
    const option: any = useSelector((state: any) => state.table.option);
    const sortBy: string = useSelector((state: any) => state.table.sortBy);
    const sortByList: any[] = useSelector((state: any) => state.table.sortByList);
    const defaultField: any = useSelector((state: any) => state.table.defaultField);
    const total: number = useSelector((state: any) => state.table.total);
    const size: number = useSelector((state: any) => state.table.size);
    const childTotal: number = useSelector((state: any) => state.table.childTotal);
    const childSize: number = useSelector((state: any) => state.table.childSize);
    const childFirst: number = useSelector((state: any) => state.table.childFirst);
    const first: number = useSelector((state: any) => state.table.first);
    const [allCheckBox, setAllCheckBox] = useState<boolean>(false)
    const [list, setList] = useState<any[]>([]);
    const [check, setCheck] = useState<boolean>(false);
    const [oneCheck, setOneCheck] = useState<string>("");
    const dispatch = useDispatch();

    const handleChange = (e: any) => {
        let itemName = e.target.name;
        let checked = e.target.checked;

        let arr: any[] = [];
        if (itemName === "checkAll") {
            setCheck(checked);
            arr = list.map((item: any) => ({ ...item, flag: checked }));
            setList(arr);
        } else {
            arr = list.map((item: any) =>
                item.id === itemName ? { ...item, flag: checked } : item
            );
            let check = arr.every((item: any) => item.flag);
            setCheck(check);
            setList(arr);
        }
    };

   const getSortIcon = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                return <SortDownArrow />
            } else if (desc === sortBy) {
                return <SortUpArrow />
            } else {
                return <SortArrow />
            }
        }
    }

    const handleSort = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                dispatch(tableActions.setSortBy(desc))
            } else {
                dispatch(tableActions.setSortBy(asc))
            }
        }
    }

    const onPageChange = (event: any) => {
        if (type === "group") {
            dispatch(tableActions.setChildPage(event?.page + 1));
            dispatch(tableActions.setChildFirst(event.first));
            dispatch(tableActions.setChildSize(event.rows))
        } else {
            dispatch(tableActions.setPage(event?.page + 1));
            dispatch(tableActions.setFirst(event.first));
            dispatch(tableActions.setSize(event.rows))
        }
    };

    const getHeader = () => {
        let arr: any = [];
        defaultField.forEach((el: any) => {
            for (const key in frontField) {
                if (key === el) {
                    arr.push(<th key={key} className="px-[8px] 2xl:px-[16px] h-full cursor-pointer" onClick={() => handleSort(key, `-${key}`)}>
                        <div className='flex items-center justify-start w-full gap-[8px]'>
                            <p className='text-lGray font-normal text-sm !text-left'>{frontField[key]}</p>
                            <div className="w-[13px] h-[13px]">
                                {getSortIcon(key, `-${key}`)}
                            </div>
                        </div>
                    </th>);
                }
            }
        });

        return arr;
    };

    useEffect(() => {
        setList(data?.length > 0 ? data?.map((item: any) => ({
            ...item,
            flag: false
        })) : [])
    }, [data])

    const handleSubmit = async (item: any, status: any) => {
        try {
            let payload = {
                "credit_policy": item?.id,
                "approved": status?.toString()
            }

            const res: any = await useAxios.post(`/workflow/credit-policy-approval-api/`, payload);

            if (res && res.status === 200) {
                success(`Credit Policy ${status ? "approved" : "disapproved"} successfully.`);
                dispatch(invoiceActions.setTagApiCall(true))
                dispatch(invoiceActions.setApiCall("credit-policy"))
            }

        } catch (error) {
            console.error("error while approval credit note", error);
        }
    }

    return (
        <>
            {(loading || data === null) ? <SkeletonGridView /> :
                <>
                    {(!loading && data && data?.length > 0) &&
                        <div className="flex flex-col justify-between min-h-[600px]">
                            <table className="table-auto overflow-x-scroll w-full skeleton-table">
                                <thead>
                                    <tr className="h-[50px]">
                                        <th className="px-[8px] 2xl:px-[16px] h-full">
                                            <div className='flex items-center justify-center' onMouseLeave={() => setAllCheckBox(false)} onMouseEnter={() => setAllCheckBox(true)}>
                                                <Checkbox className="tests table-check" name="checkAll" checked={check} onChange={handleChange}></Checkbox>
                                            </div>
                                        </th>
                                        {getHeader()}
                                        <th className="px-[8px] 2xl:px-[16px] h-full"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(list) && list.map((item: any, index: number) => (
                                        <tr className={`!rounded-[10px] shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] h-[55px] ${(((index + 1) % 2) === 0) ? "bg-[#F9F9F9]" : "bg-white"}`} key={index}>
                                            <td onMouseEnter={() => setOneCheck(item?.id)} onMouseLeave={() => setOneCheck("")} className="px-[8px] 2xl:px-[16px] h-full relative">
                                                <div className="flex items-center justify-center">
                                                    {(allCheckBox || check || oneCheck === item?.id || item?.flag) ?
                                                        <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                        :
                                                        <>
                                                            {!!item?.customer_details?.address?.org_name ?
                                                                <div style={{ backgroundColor: `${randomColor[item?.customer_details?.address?.org_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.customer_details?.address?.org_name?.charAt(0)}</div>
                                                                : <div style={{ backgroundColor: `${randomColor[item?.customer_details?.first_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.customer_details?.first_name?.charAt(0)}{item?.customer_details?.last_name?.charAt(0)}</div>}
                                                        </>
                                                    }
                                                </div>
                                            </td>

                                            {Array.isArray(defaultField) && defaultField.map((i: any, idx: number) => (
                                                <React.Fragment key={idx}>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full relative">
                                                        {i === "first_name" ?
                                                            <div className="flex flex-col">
                                                                <h3 className="text-dGray font-normal !text-sm text-left">{item?.customer_details?.first_name} {" "} {item?.customer_details?.last_name}</h3>
                                                                {!!item?.customer_details?.address?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#6B7280" /> <span className="text-dGray font-normal !text-xs text-left">{item?.customer_details?.address?.org_name}</span></p>}
                                                            </div>
                                                            : (i === "expired_at" || i === "created_at") ?
                                                                <h3 className="text-mGray font-normal !text-sm text-left">{!!item[i] ? Moment(item[i]).format('DD MMM YYYY') : "-"}</h3>
                                                                :
                                                                i === "email" ?
                                                                    <h3 className="text-mGray font-normal !text-sm text-left">{item?.customer_details?.first_name}</h3>
                                                                    :
                                                                    i === "credit_group" ?
                                                                        <>
                                                                            {item?.group_details?.length !== 0 && <button className={`bg-[#D7E8FF] text-primaryLight flex items-center justify-center text-sm font-normal rounded-[5px] py-[4px] px-[20px] whitespace-nowrap`}>
                                                                                {item?.group_details?.value}{item?.group_details?.amount_type === "Percentage" ? "%" : ""} &nbsp;&nbsp;{item?.group_details?.type}
                                                                            </button>}
                                                                        </> :
                                                                        <div>
                                                                            {!!item[i] ?
                                                                                <h3 className="text-mGray font-normal !text-sm text-left truncate">{item[i]}</h3>
                                                                                :
                                                                                <>
                                                                                    {item?.custom_fields_values?.length > 0 ? <>
                                                                                        {Array.isArray(item?.custom_fields_values) && item?.custom_fields_values.map((c: any, cidx: number) => (
                                                                                            <React.Fragment key={cidx}>
                                                                                                {c?.slug === i && <h3 key={cidx} className="text-mGray font-normal !text-sm text-left truncate">{!!c?.value ? c?.value : "-"}</h3>}
                                                                                            </React.Fragment>
                                                                                        ))}
                                                                                    </> : <h3 className="text-mGray font-normal !text-sm text-left truncate">-</h3>}
                                                                                </>
                                                                            }
                                                                        </div>
                                                        }
                                                    </td>
                                                </React.Fragment>
                                            ))}
                                            <td className="px-[8px] 2xl:px-[16px] h-full">
                                                <div className="flex items-center gap-[10px]">
                                                    {(option?.name === "Pending" && userDetail?.role === "Lab Head") &&
                                                        <Menu align="end" menuButton={<MenuButton>
                                                            <div className="cursor-pointer flex items-center justify-center pt-[4px]">
                                                                <DataDotsIcon />
                                                            </div>
                                                        </MenuButton>}>
                                                            <MenuItem onClick={() => handleSubmit(item, true)}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[6px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray group-hover:fill-primaryLight fill-mGray'><Approve /> Approve</div></div></MenuItem>
                                                            <MenuItem onClick={() => handleSubmit(item, false)}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[6px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray'><Reject /> Reject</div></div></MenuItem>
                                                        </Menu>
                                                    }

                                                    {/* <div className="cursor-pointer flex items-center justify-center pt-[4px]">
                                                        <DataDotsIcon />
                                                    </div> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {
                                type === "group" ?
                                    <div className="flex items-center justify-end">
                                        <Paginator className="pagination" pageLinkSize={3} first={childFirst} rows={childSize} totalRecords={childTotal} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div> :
                                    <div className="flex items-center justify-end">
                                        <Paginator className="pagination" pageLinkSize={3} first={first} rows={size} totalRecords={total} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div>
                            }
                        </div>
                    }
                </>
            }

            {
                (data?.length === 0 && !loading) && <div className="flex items-center justify-center">
                    <img src={require("../../../../assets/images/no-data-found.jpg")} alt="" className="w-[350px] h-[350px]" />
                </div>
            }
        </>
    );
};

export default GridView;