export const messages = {
    pending_sample_receiving: "Pending Sample Receiving",
    pending_assignment: "Pending Assignment",
    pending_receiving: "Pending Receiving",
    generate_report: "Generate Report",
    result_reviewed: "ResultReviewed",
    result_approved: "ResultApproved",
    pending_result_review: "PendingResultReview",
    sample_tab: {
        pending_review: "PendingReview",
        sample_reviewed: "SampleReviewed",
        shared: "Shared",
        pending_receiving: "PendingReceiving",
        pending_report_generation: "PendingReportGeneration",
        pending_parameter_assignment: "PendingParameterAssignment",
        pending_result_review: "PendingResultReview",
        signed_reports: "SignedReports",
        pending_approval: "PendingApproval",
        pending_assignment: "PendingAssignment",
        parameter_assigned: "ParameterAssigned",
        pending_sample_receiving: "PendingSampleReceiving",
        pending_sign: "PendingSign",
        pending_for_testing: "PendingForTesting"
    },
    components: ["Edit", "SampleReviewed", "Pending Receiving", "Pending Sample Receiving", "Pending Assignment", "ParameterAssigned", "PendingForTesting", "PendingApproval", "PendingReportGeneration", "Generate Report", "PendingSign", "SignedReports", "PendingResultReview"]
}