import { useSelector } from "react-redux";
import { MaterialInwardIcon, MaterialOutwardIcon, RegisterArrow, SampleTestClose, StockReturnIcon, StockTransferIcon } from "../../svg/AllSvg";
import { inventoryActions } from "../../../shared/store/inventory-Slice";
import { useDispatch } from "react-redux";
import PrimaryBtn from "../../form/PrimaryBtn";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateIndent = () => {
    const createIndent: boolean = useSelector((state: any) => state.inventory.createIndent);
    const [select, setSelect] = useState<any>(null)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const list: any[] = [
        { id: 1, name: "Material Outward", icon: MaterialOutwardIcon },
        { id: 2, name: "Material Inward", icon: MaterialInwardIcon },
        { id: 3, name: "Stock Return", icon: StockReturnIcon },
        { id: 4, name: "Stock Transfer", icon: StockTransferIcon },
        // { id: 5, name: "Bill of Material", icon: BillMaterialIcon },
    ];

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(inventoryActions.setCreateIndent(false))
        }
    };

    const handleNavigate = () => {
        if (select === 1 || select === 2) {
            navigate("/inventory/indent-request/create")
        }
        if (select === 3) {
            navigate("/inventory/indent-request/stock-return/create")
        }
        if (select === 4) {
            navigate("/inventory/indent-request/stock-transfer/create")
        }
        dispatch(inventoryActions.setCreateIndent(false))
    };

    return (
        <>
            {createIndent &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] h-[382px] sm:w-[600px] sm:h-auto md:w-auto overflow-y-scroll p-[25px] md:px-[40px] md:py-[32px]">
                            <h3 className="text-primaryLight font-semibold text-base xl:text-lg">Create Indent Request</h3>
                            <p className="text-mGray text-sm font-normal">Please select one of this action to create a indent request</p>


                            <div className="flex items-center justify-center flex-col sm:flex-row pt-[30px] gap-[16px] sm:gap-[12px]">
                                {Array.isArray(list) && list.map((item: any, index: number) => (
                                    <div key={index} className={`${item?.id === select ? "border border-primary shadow-[0px_18px_88px_-4px_rgba(24,39,75,0.14),0px_8px_28px_-6px_rgba(24,39,75,0.12)]" : "border border-[#EFEFEF] shadow-[0px_4px_4px_-2px_rgba(24,39,75,0.08),0px_2px_4px_-2px_rgba(24,39,75,0.12)]"} w-[180px] h-[160px] bg-white rounded-[10px] flex flex-col items-center justify-center gap-[4px] hover:bg-[#edfaff] cursor-pointer`} onClick={() => setSelect(item?.id)}>
                                        {<item.icon className={`${select === item?.id ? "fill-primaryLight" : "fill-[#CBD5E1]"}`} />}
                                        <h3 className={`${item?.id === select ? "text-primaryLight" : "text-lGray"} font-normal text-sm`}>{item?.name}</h3>
                                    </div>
                                ))}
                            </div>

                            <div className="w-full pt-[40px] flex items-end justify-end">
                                <PrimaryBtn title="Next" rightIcon={<RegisterArrow className="fill-white" />} disabled={!!select ? false : true} onClick={handleNavigate} />
                            </div>

                            <div className="absolute top-[15px] right-[15px] cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full" onClick={() => dispatch(inventoryActions.setCreateIndent(false))}>
                                <SampleTestClose />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default CreateIndent;