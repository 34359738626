import { useNavigate } from "react-router-dom";
import { BackArr, CompletionDate, CreatedDateCal, CustNameIcon, LabIcon, Minimize, TestingTypeIcon } from "../../svg/AllSvg";
import { useSelector } from "react-redux";
import { Skeleton } from "primereact/skeleton";
import { useTranslation } from "react-i18next";
import Moment from 'moment';
import { messages } from "../../../shared/utils/message";

const Header = ({ setLoader, loader, role, trDetails, trLoading }: any) => {
    const scrollY: boolean = useSelector((state: any) => state.ui.scrollY);
    const sampleDetail = useSelector((state: any) => state.labSample.sampleDetail);
    const component: string = useSelector((state: any) => state.labSample.component);
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    return (
        <>
            <div className={`flex flex-col gap-[20px] w-full z-[3] ${scrollY ? "top-0 fixed left-0 right-0" : "pt-[24px]"} bg-[#F8FAFB] transition-all`}>
                {/* back and btn */}
                {!scrollY && <div className="flex items-center justify-between px-[16px]">
                    <button className="flex items-center gap-[10px]" onClick={() => navigate(-1)}>
                        <div className="w-[13px] h-[13px]">
                            <BackArr className="fill-mGray" />
                        </div>
                        <h4 className="text-dGray text-base font-semibold leading-[20px]">{t("common_btn.back")}</h4>
                    </button>
                    {role === "lab" && <div className="flex items-center gap-[8px]">
                        {
                            !loader ?
                                <button className="min-w-[25px] min-h-[25px] rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] flex items-center justify-center cursor-pointer" onClick={() => setLoader(true)}>
                                    <Minimize className="stroke-mGray" />
                                </button> :
                                <Skeleton className="!w-[25px] !h-[25px]"></Skeleton>
                        }
                    </div>}
                </div>}

                {/* header tr info and actions */}
                <div className={`bg-white ${scrollY ? "h-[75px]" : "rounded-[10px] h-[115px]"} z-[1] transition-all`}>
                    <div style={{ background: `${role === "customer" ? "linear-gradient(92deg, #317998 0%, #1B6889 95.11%)" : "linear-gradient(92deg, #3B3E9C 0%, #5252A1 95.11%)"}` }} className={`w-full shadow-[0px_6px_8px_-6px_rgba(24,39,75,0.12),0px_8px_16px_-6px_rgba(24,39,75,0.08)] relative flex items-center justify-between flex-col h-full ${scrollY ? "" : "rounded-[10px]"}`}>
                        {!scrollY && <img src={require("../../../assets/images/Mask group1.png")} alt="pattern" className="absolute left-0 right-0 bottom-0 rounded-[10px]" />}
                        <div className={`${scrollY ? "min-h-[30px] pl-[32px] pr-[150px]" : "rounded-t-[10px] min-h-[45px] px-[30px]"} w-full bg-[rgba(255,255,255,0.15)] backdrop-blur-[5px] flex items-center justify-between z-[1]`}>
                            <div className="flex items-center gap-[60px]">
                                {scrollY && <button className="flex items-center gap-[10px]" onClick={() => navigate(-1)}>
                                    <div className="w-[13px] h-[13px]">
                                        <BackArr className="fill-white" />
                                    </div>
                                    <h4 className="text-white text-base font-semibold leading-[20px]">{t("common_btn.back")}</h4>
                                </button>}
                                <div className="flex items-center gap-[6px] whitespace-nowrap">
                                    <label htmlFor="Test Report No." className={`text-white ${scrollY ? "text-base" : "text-lg"} font-semibold`}>Sample Code:</label>
                                    <h3 className={`text-white ${scrollY ? "text-base" : "text-lg"} font-semibold`}>#{sampleDetail?.sample_code}</h3>
                                </div>
                            </div>

                            {(scrollY && role === "lab") && <>
                                {
                                    !loader ?
                                        <button className="min-w-[23px] min-h-[23px] rounded-[6px] bg-[#ffffff33] flex items-center justify-center cursor-pointer" onClick={() => setLoader(true)}>
                                            <Minimize className="stroke-white" />
                                        </button> :
                                        <Skeleton className="!w-[25px] !h-[25px] !bg-[rgba(255,255,255,0.20)]"></Skeleton>
                                }
                            </>}
                        </div>

                        <div className={`w-full ${scrollY ? "px-[150px]" : "px-[30px]"} flex items-center justify-between h-full z-[1]`}>
                            <div className="flex items-center h-full">

                                {role === "lab" && <div className="flex flex-col gap-[2px] border-r border-[rgba(255,255,255,0.29)] pr-[15px]">
                                    {!scrollY && <label htmlFor="Customer Name" className="text-white text-xs font-normal">Customer Name</label>}
                                    <div className="flex items-center gap-[8px]">
                                        {scrollY && <CustNameIcon />}
                                        <h3 className="text-white text-sm font-medium">{sampleDetail?.user_detail?.first_name + " " + sampleDetail?.user_detail?.last_name}</h3>
                                    </div>
                                </div>}

                                {role === "customer" && <>
                                    <div className="flex flex-col gap-[2px] border-r border-[rgba(255,255,255,0.29)] pr-[15px]">
                                        {trLoading ?
                                            <div className="flex flex-col gap-[5px]">
                                                {!scrollY && <Skeleton height="12px" className="!w-[60px] !rounded-full !bg-[rgba(255,255,255,0.20)]"></Skeleton>}
                                                <Skeleton height="12px" className="!w-[81px] !rounded-full !bg-[rgba(255,255,255,0.20)]"></Skeleton>
                                            </div>
                                            :
                                            <>
                                                {!scrollY && <label htmlFor="Lab Name" className="text-white text-xs font-normal">{t("customer.billing_info.test_req_detail.lab_name")}</label>}
                                                <div className="flex items-center gap-[8px]">
                                                    {scrollY && <LabIcon />}
                                                    <h3 className="text-white text-sm font-medium">{trDetails?.lab_name}</h3>
                                                </div>
                                            </>}
                                    </div>

                                    <div className="flex flex-col gap-[2px] border-r border-[rgba(255,255,255,0.29)] px-[15px]">
                                        {trLoading ?
                                            <div className="flex flex-col gap-[5px]">
                                                {!scrollY && <Skeleton height="12px" className="!w-[60px] !rounded-full !bg-[rgba(255,255,255,0.20)]"></Skeleton>}
                                                <Skeleton height="12px" className="!w-[72px] !rounded-full !bg-[rgba(255,255,255,0.20)]"></Skeleton>
                                            </div>
                                            :
                                            <>
                                                {!scrollY && <label htmlFor="Testing Type" className="text-white text-xs font-normal">{t("lab_sample.review_sample.testing_type")}</label>}
                                                <div className="flex items-center gap-[8px]">
                                                    {scrollY && <TestingTypeIcon />}
                                                    <h3 className="text-white text-sm font-medium">{trDetails?.service_category_name}</h3>
                                                </div>
                                            </>}
                                    </div>
                                </>}

                                <div className={`flex flex-col gap-[2px] px-[15px] ${role === "lab" && "border-r border-[rgba(255,255,255,0.29)]"}`}>
                                    {!scrollY && <label htmlFor="Received Date" className="text-white text-xs font-normal">Created Date</label>}
                                    <div className="flex items-center gap-[8px]">
                                        {scrollY && <CreatedDateCal className="fill-white" />}
                                        <h3 className="text-white text-sm font-medium">{Moment(sampleDetail?.created_at).format('DD MMM YYYY')}</h3>
                                    </div>
                                </div>
                                {role === "lab" &&
                                    <>
                                        <div className={`flex flex-col gap-[2px] ${component === messages?.sample_tab?.pending_sign && "border-r border-[rgba(255,255,255,0.29)]"} px-[15px]`}>
                                            {!scrollY && <label htmlFor="Received Date" className="text-white text-xs font-normal">Completion Date</label>}
                                            <div className="flex items-center gap-[8px]">
                                                {scrollY && <CompletionDate />}
                                                <h3 className="text-white text-sm font-medium">{!!sampleDetail?.completion_date ? Moment(sampleDetail?.completion_date).format('DD MMM YYYY') : t("lab_sample.sample_detail.no_date")}</h3>
                                            </div>
                                        </div>

                                        {component === messages?.sample_tab?.pending_sign && <h2 className={`text-white font-extrabold text-xl leading-[20px] pl-[13px] relative uppercase ml-[15px] ${scrollY ? "" : "max-w-[150px]"}`}>Pending For Sign
                                            <div className="min-w-[5px] bg-[#F33] h-full rounded-lg absolute top-0 left-0 bottom-0"></div>
                                        </h2>}
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;