import { useSelector } from 'react-redux';
import { LabelLine } from '../../svg/AllSvg'
import { useTranslation } from 'react-i18next';
import CustomFieldInfo from '../../form/CustomFieldInfo';
import Moment from 'moment';

const SampleInfo = () => {
    const customField = useSelector((state: any) => state.setting.customField);
    const sampleDetail = useSelector((state: any) => state.labSample.sampleDetail);
    const { t } = useTranslation('common');

    return (
        <div className="bg-white shadow-[0px_4px_4px_rgba(24,39,75,0.08),0px_2px_4px_rgba(24,39,75,0.12)] rounded-[10px] w-full" id="sample_summary">
            <div className="px-[27px] py-[9.5px] min-h-[55px] flex items-center border-b border-[rgba(147,147,147,0.15)]">
                <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-[9px]">
                        <div className="min-w-[28px] min-h-[28px] rounded-full flex items-center justify-center text-lBlue text-base font-semibold bg-[#EEFBFF]">
                            01
                        </div>
                        <h4 className="text-lBlue text-base font-semibold">{t("lab_sample.sample_detail.sample_information")}</h4>
                    </div>
                </div>
            </div>

            <div className="px-[40px] py-[29px] w-full">
                {/* sample details */}
                <div className="flex flex-col gap-[15px] w-full">
                    <div className="flex items-center gap-[10px]">
                        <div className="w-[4px] h-[16px]">
                            <LabelLine className="fill-dBlue" />
                        </div>
                        <h2 className="h-full text-dBlue text-base font-normal leading-[20px]">
                            {t("customer.billing_info.test_req_detail.sample.sample_detail")}
                        </h2>
                    </div>
                    <div className="grid grid-cols-5 gap-x-[10px] gap-y-[15px] border-b border-[rgba(147,147,147,0.15)] pb-[20px]">
                        <div className="flex flex-col gap-[4px]">
                            <label htmlFor="Sample Code" className="text-mGray text-xs font-normal">{`${t("customer.add_sample.sample.form.field.sample_code")}*`}</label>
                            <p className="text-[#3F4650] text-sm font-medium">{sampleDetail?.sample_code}</p>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                            <label htmlFor="Sample Name" className="text-mGray text-xs font-normal">{t("customer.add_sample.sample.form.field.name")}</label>
                            <p className="text-[#3F4650] text-sm font-normal">{sampleDetail?.test_name}</p>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                            <label htmlFor="Sample Type" className="text-mGray text-xs font-normal">{t("customer.add_sample.sample.form.field.type")}</label>
                            <p className="text-danger text-sm font-normal">{sampleDetail?.sample_type_name}</p>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                            <label htmlFor="Sample Quantity" className="text-mGray text-xs font-normal">{t("customer.add_sample.sample.form.field.qty")}</label>
                            <p className="text-[#3F4650] text-sm font-normal">{sampleDetail?.quantity}</p>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                            <label htmlFor="Sample Unit" className="text-mGray text-xs font-normal">{t("customer.add_sample.sample.form.field.unit")}</label>
                            <p className="text-[#3F4650] text-sm font-normal">{sampleDetail?.sample_unit_name}</p>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                            <label htmlFor="Sealed Status" className="text-mGray text-xs font-normal">{t("customer.add_sample.sample.form.field.status")}</label>
                            <p className="text-[#3F4650] text-sm font-normal">{sampleDetail?.sealed_status_name}</p>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                            <label htmlFor="Sealed Status" className="text-mGray text-xs font-normal">{t("customer.billing_info.test_req_detail.sample.field.expire_date")}</label>
                            <p className="text-[#3F4650] text-sm font-normal">{!!sampleDetail?.expiry_date ? Moment(sampleDetail?.expiry_date).format('DD MMM YYYY') : "-"}</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-[15px] w-full pt-[20px]">
                    <div className="flex items-center gap-[10px]">
                        <div className="w-[4px] h-[16px]">
                            <LabelLine className="fill-dBlue" />
                        </div>
                        <h2 className="h-full text-dBlue text-base font-normal leading-[20px]">
                            {t("customer.billing_info.test_req_detail.sample.sample_desc")}
                        </h2>
                    </div>

                    <div className="grid grid-cols-3 gap-x-[10px] gap-y-[15px]">
                        <div className="flex flex-col gap-[4px]">
                            <label htmlFor="Sample Description" className="text-mGray text-xs font-normal">{t("customer.add_sample.sample.form.field.desc")}</label>
                            <p className="text-[#3F4650] text-sm font-normal">{!!sampleDetail?.sample_description ? sampleDetail?.sample_description : "-"}</p>
                        </div>

                    </div>
                </div>

                <div className={`flex flex-col gap-[15px] w-full pt-[20px] border-t border-[rgba(147,147,147,0.15)] mt-[20px] ${!customField && "hidden"}`}>
                    <CustomFieldInfo grid={3} modal="samplepart" fillModal="Sample" id={sampleDetail?.id} page="lims" />
                </div>
            </div>
        </div>
    )
}

export default SampleInfo