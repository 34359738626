/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { CardTable, DangerDelete, ListMenu, NormalTable, OptionDot } from "../svg/AllSvg";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { tableActions } from "../../shared/store/table-Slice";
import FilterView from "./FilterView";
// import Papa from 'papaparse';
import Select from "react-select";
import { useSelect } from "../../shared/hooks/use-select";
import useAxios from "../../shared/hooks/use-axios";

const FilterOption: React.FC<any> = ({ module, type, exportOption, csvUrl, jsonUrl, fileName }: any) => {
    const option: any = useSelector((state: any) => state.table.option);
    const sortBy: string = useSelector((state: any) => state.table.sortBy);
    const filterResult: string = useSelector((state: any) => state.table.filterResult);
    const data: any[] = useSelector((state: any) => state.table.data);
    const groupByData: any = useSelector((state: any) => state.table.groupByData);
    const frontField: any = useSelector((state: any) => state.table.frontField);
    const [mainMenuOpen, setMainMenuOpen] = useState<boolean>(false);
    const [filterColumnOpen, setFilterColumnOpen] = useState<boolean>(false);
    const view: string = useSelector((state: any) => state.table.view);
    const groupBy: boolean = useSelector((state: any) => state.table.groupBy);
    const groupByOption: string = useSelector((state: any) => state.table.groupByOption);
    const groupByList: any[] = useSelector((state: any) => state.table.groupByList);
    const defaultField: any[] = useSelector((state: any) => state.table.defaultField);
    const detailField: any[] = useSelector((state: any) => state.table.detailField);
    const viewAll: boolean = useSelector((state: any) => state.table.viewAll);
    const groupByOptionValue: string = useSelector((state: any) => state.table.groupByOptionValue);
    let [exportData, setExportData] = useState<any>(null);
    const ref1: any = useRef();
    const ref2: any = useRef();
    const params = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    let styles = useSelect(false, true, "34px", "pointer", "#6B7280");

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref1.current && !ref1.current.contains(event.target)) {
                setMainMenuOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref1]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref2.current && !ref2.current.contains(event.target)) {
                setFilterColumnOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref2]);

    const handleClick = (el: any) => {
        if (defaultField?.includes(el)) {
            let arr: any[] = defaultField.filter((item: any) => item !== el);
            dispatch(tableActions.setDefaultField(arr))
            localStorage.setItem(module, JSON.stringify(arr))
        } else {
            let arr: any[] = [...defaultField, el];
            dispatch(tableActions.setDefaultField(arr))
            localStorage.setItem(module, JSON.stringify(arr))
        }
    }

    const handleDefaultField = () => {
        let arr: any = [];
        for (const key in frontField) {
            if (detailField?.includes(key)) {
                arr.push(<p key={key} className={`${defaultField?.includes(key) ? "text-primaryLight font-medium" : "text-lGray font-normal"} text-sm hover:bg-[#F4F4F4] cursor-pointer py-[8px] px-[16px]`} onClick={() => handleClick(key)}>{frontField[key]}</p>);
            }
        }

        return arr;
    };

    const handleExport = async (e: any) => {
        try {
            if (e?.value === "CSV") {
                const res: any = await useAxios.get(`/${csvUrl}/?tag__name__iexact=${option?.name}&sort_by=${sortBy}&${filterResult}`);

                if (res && res?.status === 200 && res?.data) {
                    exportData = e;
                    setExportData(e)
                    const type: any = 'text/csv;charset=utf-8;'
                    const blob = new Blob([res?.data], { type });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${fileName}.csv`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    exportData = null;
                    setExportData(exportData)
                }
            } else {
                const res: any = await useAxios.get(`/${jsonUrl}/?tag__name__iexact=${option?.name}&sort_by=${sortBy}&${filterResult}`);

                if (res && res?.status === 200 && res?.data) {
                    exportData = e;
                    setExportData(e)
                    const jsonData = JSON.stringify(res?.data);
                    const type: any = 'application/json'
                    const blob = new Blob([jsonData], { type });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${fileName}.json`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    exportData = null;
                    setExportData(exportData)
                }
            }
        } catch (error) {
            console.error("error while exporting data", error);
        }
    }

    return (
        <div className="flex items-center gap-[8px] flex-col sm:flex-row w-full">
            <FilterView />

            {!!!params?.id && <div className="flex items-center gap-[8px] justify-end w-full sm:w-auto">
                {(!viewAll && !!!type) && <>
                    {(groupBy && !!groupByOption) && <div className="rounded-[10px] border border-[#CDCDCD] shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] p-[8px] xl:p-[10px] flex items-center gap-[8px] whitespace-nowrap">
                        <h3 className="text-primaryLight font-medium text-sm capitalize">{t("group_by_menu.group_by")}: <span>{groupByOption}</span></h3>
                        <div className="cursor-pointer" onClick={() => dispatch(tableActions.groupToggle())}>
                            <DangerDelete />
                        </div>
                    </div>}
                </>}
                <div className="relative cursor-pointer" ref={ref1}>
                    <div className="relative rounded-[10px] px-[7px] py-[7px] border border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] flex items-center justify-center cursor-pointer" onClick={() => setMainMenuOpen(!mainMenuOpen)}>
                        <ListMenu className="w-[18px] h-[18px]" />
                    </div>

                    {mainMenuOpen && <div className="bg-white rounded-[10px] w-[180px] shadow-[0px_12px_42px_-4px_rgba(24,39,75,0.12),0px_8px_18px_-6px_rgba(24,39,75,0.12)] py-[8px] absolute top-[54px] z-10 right-0 h-[400px] overflow-y-scroll">
                        <p className="text-xs py-[8px] px-[16px] text-mGray font-normal">{t("fieldMenu.layout")}</p>
                        <div className="flex items-center gap-[8px] py-[8px] px-[16px] hover:bg-[#F4F4F4] cursor-pointer" onClick={() => dispatch(tableActions.handleView("card"))}>
                            <CardTable className={`${view === "card" ? "stroke-primaryLight" : "stroke-lGray"}`} />
                            <p className={`${view === "card" ? "text-primaryLight font-medium" : "text-lGray font-normal"} text-sm`}>{t("fieldMenu.card_layout")}</p>
                        </div>
                        <div className="flex items-center gap-[8px] py-[8px] px-[16px] hover:bg-[#F4F4F4] cursor-pointer" onClick={() => dispatch(tableActions.handleView("table"))}>
                            <NormalTable className={`${view === "table" ? "stroke-primaryLight" : "stroke-lGray"}`} />
                            <p className={`${view === "table" ? "text-primaryLight font-medium" : "text-lGray font-normal"} text-sm cursor-pointer`}>{t("fieldMenu.table_layout")}</p>
                        </div>
                        <div className="w-full border-b border-[#CBD5E1] my-[8px]"></div>
                        <p className="text-xs py-[8px] px-[16px] text-mGray font-normal">{t("fieldMenu.list_item")}</p>
                        {handleDefaultField()}
                    </div>}
                </div>

                {(!viewAll && !!!type) && <div className="relative cursor-pointer" ref={ref2}>
                    <div className="rounded-[10px] px-[7.5px] py-[14px] border border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] flex items-center justify-center cursor-pointer" onClick={() => setFilterColumnOpen(!filterColumnOpen)}>
                        <OptionDot color="#6B7280" />
                    </div>

                    {filterColumnOpen && <div className="bg-white rounded-[10px] w-[180px] shadow-[0px_12px_42px_-4px_rgba(24,39,75,0.12),0px_8px_18px_-6px_rgba(24,39,75,0.12)] py-[8px] absolute top-[54px] right-0 z-10">
                        <p className="text-xs py-[8px] px-[16px] text-mGray font-normal">{t("group_by_menu.group_by")}</p>
                        <p className={`text-sm hover:bg-[#F4F4F4] cursor-pointer py-[8px] px-[16px] ${!groupBy ? "text-primaryLight font-medium" : "text-lGray font-normal"}`} onClick={() => dispatch(tableActions.groupToggle())}>{t("group_by_menu.none")}</p>

                        {Array.isArray(groupByList) && groupByList.map((item: any, index: number) => (
                            <p className={`text-sm hover:bg-[#F4F4F4] cursor-pointer py-[8px] px-[16px] ${groupByOption === item?.name ? "text-primaryLight font-medium" : "text-lGray font-normal"}`} onClick={() => { dispatch(tableActions.groupByToggle()); dispatch(tableActions.handleGroupOption(item?.name)); dispatch(tableActions.handleGroupOptionValue(item?.field_name)); }} key={index}>By {item?.name}</p>
                        ))}
                        {/* <div className="w-full border-b border-[#CBD5E1] my-[8px]"></div>
                        <p className="text-xs py-[8px] px-[16px] text-mGray font-normal">{t("group_by_menu.other_option")}</p>
                        <p className="text-sm hover:bg-[#F4F4F4] cursor-pointer py-[8px] px-[16px] text-lGray font-normal">{t("group_by_menu.export_csv")}</p>
                        <p className="text-sm hover:bg-[#F4F4F4] cursor-pointer py-[8px] px-[16px] text-lGray font-normal">{t("group_by_menu.share_list")}</p> */}
                    </div>}
                </div>}
                
                {(!!exportOption && exportOption) &&
                    <div className="border border-[#D4D4D4] rounded-[10px]">
                        <Select
                            options={[{ label: "CSV", value: "CSV" }, { label: "JSON", value: "JSON" }]}
                            name=""
                            styles={styles}
                            placeholder="Export"
                            className="min-w-[100px]"
                            onChange={(e: any) => handleExport(e)}
                            isDisabled={viewAll ? (data?.length === 0 ? true : false) : !!groupByOptionValue ? (groupByData?.length === 0 ? true : false) : (!viewAll && !!!groupByOptionValue) ? (data?.length === 0 ? true : false) : false}
                            value={exportData}
                            isSearchable={false}
                        />
                    </div>
                }
            </div>}

        </div>
    );
};

export default FilterOption;